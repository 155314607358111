<template>
  <router-link :to="link" custom v-slot="{ navigate }">
    <article class="menu-item" :class="{ 'menu-item-unselected': !active }" @click="navigate">
      <div class="active-menu-item-rectangle" :class="{ 'active': active }"></div>
      <div class="icon-container">
        <img class="icons" :src="getImageUrl(icon)" alt="" />
      </div>
      <div class="menu-item-text valign-text-middle small-text">{{ text }}</div>
    </article>
  </router-link>
</template>

<script>
export default {
  name: 'MenuItem',
  props: {
    text: String,
    active: Boolean,
    icon: String,
    link: String,
  },
  methods: {
    getImageUrl(name) {
      return `/img/${name}`;
    }
  }
}
</script>

<style>
.menu-item {
  align-items: center;
  border-radius: 8px;
  display: flex;
  gap: 16px;
  height: 40px;
  padding: 0px 16px 0px 0px;
  position: relative;
  width: 222px;
  cursor: pointer;
  transition: all 0.3s ease;
  text-decoration: none;
}

.menu-item:hover {
  background-color: rgba(255, 255, 255, 0.1);
}

.menu-item-unselected {
  opacity: 0.6;
}

.active-menu-item-rectangle {
  background-color: transparent;
  border-radius: 0px 5px 5px 0px;
  height: 24px;
  position: relative;
  width: 5px;
  transition: all 0.3s ease;
}

.active-menu-item-rectangle.active {
  background-color: var(--white);
}

.icon-container {
  height: 24px;
  width: 24px;
}

.icons {
  height: 100%;
  width: 100%;
}

.menu-item-text {
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 1;
  color: var(--white);
  display: -webkit-box;
  flex: 1;
  font-weight: 500;
  line-height: 24px;
  transition: all 0.3s ease;
}

.menu-item:hover .menu-item-text {
  transform: translateX(5px);
}
</style>