<template>
  <div class="person-form">
    <transition name="fade-slide" mode="out-in">
      <div v-if="petId && selectedPet" class="client-info" key="pet-info">
        <div class="info-column left-column">
          <div class="info-row">
            <div class="field">
              <h1 class="field-label">{{ messages.firstName }}</h1>
              <text-field
                :value="selectedPet.name"
                readonly
                class="field"
                outlined
                dense
              />
            </div>
            <div class="field">
              <h1 class="field-label">{{ messages.sex }}</h1>
              <text-field
                :value="messages.sexes[selectedPet.sex]"
                readonly
                class="field"
                outlined
                dense
              />
            </div>
          </div>
          <div class="info-row">
            <div class="field">
              <h1 class="field-label">{{ messages.species }}</h1>
              <text-field
                :value="selectedPet.type"
                readonly
                class="field"
                outlined
                dense
              />
            </div>
            <div class="field">
              <h1 class="field-label">{{ messages.breed }}</h1>
              <text-field
                :value="selectedPet.breed"
                readonly
                class="field"
                outlined
                dense
              />
            </div>
          </div>
          <div class="field">
            <h1 class="field-label">{{ messages.chipNumber }}</h1>
            <text-field
              :value="selectedPet.chipNumber"
              readonly
              class="field"
              outlined
              dense
            />
          </div>
        </div>
        <div class="info-column right-column">
          <div class="info-row">
            <div class="field">
              <h1 class="field-label">{{ messages.birthdate }}</h1>
              <text-field
                :value="selectedPet.birthdate"
                readonly
                outlined
                dense
                class="field"
              />
            </div>
            <div class="field">
              <h1 class="field-label">{{ messages.weight }}</h1>
              <text-field
                :value="selectedPet.weight"
                readonly
                class="field"
                outlined
                dense
              />
            </div>
          </div>
          <div class="field">
            <h1 class="field-label">{{ messages.comments }}</h1>
            <text-area
              :value="selectedPet.comment"
              outlined
              readonly
              rows="6"
              class="comment-field"
              auto-grow
            />
          </div>
        </div>
      </div>
      <div v-else-if="loading" class="dzivnieks" key="loading">
        <p class="client-info-will-be-shown-here valign-text-middle small-text">
          {{ messages.loading }}
        </p>
      </div>
      <div v-else class="dzivnieks" key="placeholder">
        <p class="client-info-will-be-shown-here valign-text-middle small-text">
          {{ messages.informationAboutPetWillBeHere }}
        </p>
      </div>
    </transition>
  </div>
</template>

<script>
import TextField from '/src/vue/components/TextField.vue'
import TextArea from '/src/vue/components/TextArea.vue'
import { database } from '/src/vue/ipc.js'
import { messages } from '../../../../locale'

export default {
  name: 'PetInfoDisplay',
  computed: {
    messages() {
      return messages
    },
  },
  components: {
    TextField,
    TextArea,
  },
  data() {
    return {
      selectedPet: null,
      loading: false,
    }
  },
  props: {
    petId: {
      type: [String, Number],
      default: null,
    },
    clientId: {
      type: String,
      default: null,
    },
  },
  watch: {
    petId: {
      handler() {
        this.fetchPetData()
      },
      immediate: true,
    },
  },
  methods: {
    async fetchPetData() {
      if (!this.petId || !this.clientId) {
        this.selectedPet = null
        return
      }

      this.loading = true
      try {
        const pets = await database.getPets(this.clientId)
        this.selectedPet = pets.find((pet) => pet.id === this.petId)
        if (!this.selectedPet) {
          console.error(
            `Pet with id ${this.petId} not found for client ${this.clientId}`,
          )
        }
      } catch (error) {
        console.error('Error fetching pet data:', error)
        this.selectedPet = null
      } finally {
        this.loading = false
      }
    },
  },
}
</script>

<style scoped>
.person-form {
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 0 40px 0 40px;
}

.client-info {
  display: flex;
  width: 100%;
  height: 300px;
  justify-content: space-between;
}

.info-column {
  display: flex;
  flex-direction: column;
  gap: 10px;
  width: 45%;
}

.left-column {
  align-items: flex-start;
}

.right-column {
  align-items: flex-end;
}

.info-row {
  display: flex;
  gap: 20px;
  width: 100%;
}

.dzivnieks {
  position: relative;
  width: 100%;
  height: 300px;
}

.client-info-will-be-shown-here {
  position: absolute;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #d9d9d9;
  font-weight: 500;
  text-align: center;
}

.field {
  width: 100%;
}

.field-label {
  margin-bottom: 5px;
  font-size: 14px;
  color: var(--elephant);
}

/* Animation styles */
.fade-slide-enter-active,
.fade-slide-leave-active {
  transition: all 0.2s ease;
}

.fade-slide-enter-from,
.fade-slide-leave-to {
  opacity: 0;
  transform: translateY(20px);
}

.fade-slide-enter-to,
.fade-slide-leave-from {
  opacity: 1;
  transform: translateY(0);
}

/* Add staggered animation for child elements */
.client-info > * {
  opacity: 0;
  transform: translateY(2px);
  animation: fadeSlideIn 0.2s ease forwards;
}

.client-info > *:nth-child(1) {
  animation-delay: 0.1s;
}
.client-info > *:nth-child(2) {
  animation-delay: 0.1s;
}

@keyframes fadeSlideIn {
  to {
    opacity: 1;
    transform: translateY(0);
  }
}
</style>