<template>
    <h2 class="header-small">{{text}}</h2>
</template>
  
  <script>
  export default {
    name: 'SmallerHeadline',
    props: ['text'],
  }
  </script>

<style>
  .header-small {
    font-family: 'Inter', sans-serif;
    padding: 15px;
    border-bottom: 1px solid var(--bon-jour);
    text-align: center;
    font-weight: 500;
    font-size: 22px;
  }
</style>