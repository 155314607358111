<template>
  <div class="client-info-container">
    <div class="header-group">
      <headline-title
        :text="messages.aboutClient"
        :showCloseIcon="true"
        @close="close"
      />
    </div>
    <div class="scrollable-content">
      <div class="content-wrapper">
        <div class="main-content">
          <div class="profile-section">
            <client-profile
              v-if="localClient"
              :client="localClient"
              :editing-fields="editingFields"
              :is-edited="edited"
              :visits-count="visitsCount"
              :appointments-count="notesCount"
              @edit="enableEditing"
              @save="saveField"
              @input="handleInput"
              @cancel="cancelChanges"
            />
            <v-progress-circular
              v-else
              indeterminate
              color="var(--camelot)"
            ></v-progress-circular>
          </div>

          <div class="pets-section">
            <pets-panel
              :pets="clientPets"
              @show-pet-details="showPetDetails"
              :client-id="clientId"
              @pet-updated="updatePet"
            />
          </div>
        </div>

        <div class="side-panel" style="width: 40%">
          <appointments-panel
            :clientId="clientId"
          />
        </div>
      </div>

    </div>
  </div>
</template>

<script>
import PetsPanel from '../../pages/fulllist/components/expansion-panels/PetsPanel.vue'
import { database } from '../../ipc.js'
import ClientProfile from '../../pages/fulllist/components/ClientProfile.vue'
import HeadlineTitle from '../../components/HeadlineTitle.vue'
import AppointmentsPanel from '../../pages/fulllist/components/expansion-panels/AppointmentsPanel.vue'
import { messages } from '@/vue/locale'
import MorePet from '@/vue/pages/fulllist/components/pop-ups/MorePet.vue'

export default {
  name: 'ClientInfo',
  computed: {
    messages() {
      return messages
    },
  },
  components: {
    MorePet,
    AppointmentsPanel,
    ClientProfile,
    PetsPanel,
    HeadlineTitle,
  },
  props: {
    clientId: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      localClient: null,
      originalClient: {},
      edited: false,
      editingFields: {
        fullName: false,
        phone: false,
        email: false,
        address: false,
        comment: false,
      },
      clientPets: [],
      clientVisits: [],
      clientNotes: [],
      selectedPet: null,
      visitsCount: 0,
      notesCount: 0,
    }
  },
  created() {
    this.fetchClientData()
  },
  methods: {
    close() {
      this.$emit('close')
    },
    updatePet() {
      this.fetchClientData()
    },
    updateVisit() {
      this.fetchClientData()
    },
    async fetchClientData() {
      const clients = await database.getAllClients()
      this.localClient = clients.find((client) => client.id === this.clientId)
      this.originalClient = { ...this.localClient }

      this.clientPets = this.localClient.animals || []
      this.clientVisits = await database.getVisitsForClient(this.clientId)
      this.clientNotes = await database.getNotesForClient(this.clientId)

      this.visitsCount = this.clientVisits.length
      this.notesCount = this.clientNotes.length

      if (this.clientPets.length > 0) {
        this.selectedPet = this.clientPets[0]
      }
    },
    enableEditing(field) {
      this.editingFields[field] = true
    },
    handleInput({ field, value }) {
      this.localClient = {
        ...this.localClient,
        [field]: value,
      }
      this.checkIfEdited()
    },
    checkIfEdited() {
      this.edited =
        JSON.stringify(this.localClient) !== JSON.stringify(this.originalClient)
    },
    cancelChanges() {
      this.localClient = { ...this.originalClient }
      this.edited = false
      this.resetEditingFields()
    },
    resetEditingFields() {
      Object.keys(this.editingFields).forEach((key) => {
        this.editingFields[key] = false
      })
    },
    showPetDetails(pet) {
      this.selectedPet = pet
    },
    enableFieldEditing(field) {
      this.$set(this.editingFields, field, true)
    },
    updateField({ field, value }) {
      this.$set(this.localClient, field, value)
      this.edited = true
    },
    async saveField({ field, value }) {
      // Update local client data
      this.$set(this.localClient, field, value)

      // Update the database
      try {
        const result = await database.updateClient(
          this.clientId,
          this.localClient,
        )
        // Successfully saved
        this.originalClient = { ...this.localClient } // Update originalClient to reflect the changes
        this.edited = false // Reset edited state
        this.editingFields[field] = false // Exit editing mode for this field
      } catch (error) {
        console.error('Error saving client data:', error)
      }
    },
    cancelEditing(field) {
      this.localClient[field] = this.originalClient[field]
      this.editingFields[field] = false
      this.edited = false
    },
  },
}
</script>

<style scoped>
.client-info-container {
  /* width: 100%; */
  max-width: none;
  padding: 0;
}

.scrollable-content {
  max-height: calc(100vh - 220px); /* Maximum height constraint */
  overflow-y: auto; /* Scrollable if content exceeds max height */
  height: auto; /* Automatically adjust height based on content */
  margin-bottom: 40px;
}

.content-wrapper {
  display: flex;
  padding: 0 40px;
  gap: 20px;
  overflow-y: hidden;
  max-height: none;
}

.header-group {
  padding-top: 10px;
}

.main-content {
  flex: 1;
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.profile-section {
  width: 110%;
}

.pets-section {
  width: 140%;
}

.side-panel {
  width: 40%;
  flex-shrink: 0;
  position: relative;
  align-self: flex-start;
}

.vaccination-wrapper {
  padding: 0 40px;
  margin-top: 20px;
}
</style>