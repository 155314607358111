import Vue from 'vue'
import App from './App.vue'
import Router from 'vue-router'
import '/styleguide.css'
import '/globals.css'
import Vuetify from 'vuetify'
import 'vuetify/dist/vuetify.min.css'
import '@mdi/font/css/materialdesignicons.css'

import Calendar from './vue/pages/calendar/Calendar.vue'
import Statistics from './vue/pages/statistics/Statistics.vue'
import FullList from './vue/pages/fulllist/FullList.vue'
import RegisterEmpty from './vue/pages/register/Register.vue'
import { messages as locale } from '@/vue/locale'

Vue.use(Router)
Vue.use(Vuetify)

Vue.config.productionTip = false

const router = new Router({
  mode: 'history',
  routes: [
    {
      path: '/',
      redirect: '/statistics'
    },
    {
      path: '/statistics',
      name: locale.pageStatisticsTitle,
      component: Statistics,
    },
    {
      path: '/full-list',
      name: locale.pageFullListTitle,
      component: FullList,
    },
    {
      path: '/register',
      name: locale.pageRegisterTitle,
      component: RegisterEmpty,
    },
    {
      path: '/calendar',
      name: locale.pageCalendarTitle,
      component: Calendar,
    },
  ],
})

const vuetify = new Vuetify({
  icons: {
    iconfont: 'mdi',
  },
})

new Vue({
  vuetify,
  router,
  render: (h) => h(App),
}).$mount('#app')
