<template>
  <modal-template @close="handleClose">
    <smaller-headline :text="messages.informationAboutAppointment" />
    <form @submit.prevent="handleSubmit" class="edit-calendar-item-form">
      <div>
        <h1 class="field-label-name">{{ messages.time }}</h1>
        <div class="date-time-row">
          <div class="time-fields">
            <DatePicker
              :date="editedNote.date"
              v-model="editedNote.date"
              :available-dates="workingDates"
              @input="onDateTimeChange"
              outlined
            />
            <TimePicker
              v-if="isDataReady"
              :selected-time="getStartTime()"
              :interval="parameters.calendarGridInterval"
              v-model="editedNote.startTime"
              :label="messages.from"
              :available-times="availableStartTimes"
              @input="onDateTimeChange"
              outlined
            />
            <TimePicker
              v-if="isDataReady"
              :selected-time="getEndTime()"
              :interval="parameters.calendarGridInterval"
              v-model="editedNote.endTime"
              :label="messages.till"
              :available-times="availableEndTimes"
              @input="onDateTimeChange"
              outlined
            />
          </div>
        </div>
      </div>

      <div v-if="editedNote.type === 'visit'" class="form-field">
        <div v-if="isCalendar">
          <h1 class="field-label-name">{{ messages.clientName }}</h1>
          <client-search-dropdown
            v-model="selectedClientId"
            prepend-inner-icon="mdi-magnify"
            class="main-search-dropdown"
            type="default"
            outlined
            @client-selected="onClientSelected"
          />          
        </div>
        <div>
          <h1 class="field-label-name">{{ messages.petName }}</h1>
          <pet-search-dropdown
            v-model="selectedPetId"
            :clientId="selectedClientId"
            prepend-inner-icon="mdi-magnify"
            class="main-search-dropdown"
            type="default"
            outlined
            :disabled="selectedClientId === null"
            @pet-selected="onPetSelected"
          />          
        </div>

      </div>

      <div>
        <h1 class="field-label-name">{{ messages.topic }}</h1>
        <TextField
          v-model="editedNote.title"
          dense
          outlined
        />
      </div>

      <div>
        <h1 class="field-label-name">{{ messages.description }}</h1>
        <TextArea
          v-model="editedNote.description"
          maxRows="10"
          outlined
        />
      </div>

      <div class="button-section">
        <div class="left-aligned">
          <PopUpActionButton
            :icon="'mdi-delete'"
            buttonType="outlined"
            iconColor="var(--camelot)"
            @click.prevent="deleteNote"
          />
        </div>
        <div class="right-aligned">
          <PopUpActionButton
            :text="messages.cancel"
            textColor="var(--camelot)"
            buttonType="outlined"
            @click.prevent="handleClose"
          />
          <PopUpActionButton
            :text="messages.save"
            backgroundColor="var(--camelot)"
            textColor="white"
            @click.prevent="handleSubmit"
            :disabled="!isFormValid"
          />
        </div>
      </div>
    </form>
  </modal-template>
</template>

<script>
import TextArea from '/src/vue/components/TextArea.vue'
import DatePicker from '/src/vue/components/DatePicker.vue'
import PopUpActionButton from '/src/vue/components/PopUpActionButton.vue'
import TimePicker from '/src/vue/components/TimePicker.vue'
import TextField from '/src/vue/components/TextField.vue'
import SmallerHeadline from '/src/vue/components/SmallerHeadline.vue'
import { database } from '/src/vue/ipc'
import utils from '/src/vue/utils'
import ClientSearchDropdown from '@/vue/components/ClientSearchDropdown.vue'
import PetSearchDropdown from '@/vue/components/PetSearchDropdown.vue'
import { messages } from '../../../locale'
import { parameters } from '@/vue/settings'
import ModalTemplate from '@/vue/components/ModalTemplate.vue'

export default {
  name: 'EditCalendarItem',
  components: {
    ModalTemplate,
    TextField,
    PopUpActionButton,
    DatePicker,
    TextArea,
    TimePicker,
    ClientSearchDropdown,
    PetSearchDropdown,
    SmallerHeadline
  },
  props: {
    note: {
      type: Object,
      required: true
    },
    isCalendar: {
      type: Boolean,
      default: true,
    }
  },
  data() {
    return {
      localClient: null,
      editedNote: {
        ...this.note,
        title: this.note.title || '',
        description: this.note.description || '',
        date: this.note.date || '',
        startTime: this.note.startTime || '',
        endTime: this.note.endTime || '',
        type: this.note.type || 'task',
      },
      selectedClientId: null,
      selectedPetId: null,
      workingHours: {},
      existingNotes: [],
      dataLoaded: false
    }
  },
  computed: {
    parameters() {
      return parameters
    },
    settings() {
      return settings
    },
    messages() {
      return messages
    },
    workingDates() {
      const today = new Date();
      const nextMonth = new Date(today.getFullYear(), today.getMonth() + 1, 1);
      const dates = [];

      while (today < nextMonth) {
        const dayName = this.getDayName(today);
        if (this.workingHours[dayName] && this.workingHours[dayName].isOpen) {
          dates.push(today.toISOString().substr(0, 10));
        }
        today.setDate(today.getDate() + 1);
      }

      return dates;
    },
    // availableStartTimes() {
    //   if (!this.editedNote.date) return [];

    //   const dayName = this.getDayName(new Date(this.editedNote.date));
    //   const { start, end } = this.workingHours[dayName] || { start: '09:00', end: '17:00' };

    //   const times = this.generateTimeSlots(start, end);
    //   return this.filterAvailableTimes(times);
    // },
    // availableEndTimes() {
    //   if (!this.editedNote.startTime) return [];


    //   const dayName = this.getDayName(new Date(this.editedNote.date));
    //   const { end } = this.workingHours[dayName] || { end: '17:00' };

    //   // const times = this.generateTimeSlots(this.editedNote.startTime, end);
    //   // return this.filterAvailableTimes(times, this.editedNote.startTime);
    //   const times = this.generateTimeSlots(this.editedNote.startTime, end);
    //   return this.filterAvailableEndTimes(times, this.editedNote.startTime);
    // },
    availableStartTimes() {
      if (!this.editedNote.date) return [];

      const dayName = this.getDayName(new Date(this.editedNote.date));
      const { start, end } = this.workingHours[dayName];

      const times = this.generateTimeSlots(start, end);
      return this.filterAvailableTimes(times);
    },
    availableEndTimes() {
      if (!this.editedNote.startTime) return [];

      const dayName = this.getDayName(new Date(this.editedNote.date));
      const { end } = this.workingHours[dayName];

      const times = this.generateTimeSlots(this.editedNote.startTime, end);
      return this.filterAvailableEndTimes(times, this.editedNote.startTime);
    },
    isDataReady() {
      return this.dataLoaded && Object.keys(this.workingHours).length > 0;
    },
    isFormValid() {
      return this.editedNote.date && this.editedNote.startTime && this.editedNote.endTime &&
        this.editedNote.description.trim() !== '' && this.editedNote.title.trim() !== '';
    }
  },
  methods: {
    async fetchClientData() {
      const clients = await database.getAllClients();
      this.localClient = clients.find(client => client.id === this.clientId);
      this.originalClient = { ...this.localClient };

      this.clientPets = this.localClient.animals || [];
      this.clientVisits = await database.getVisitsForClient(this.clientId);
      this.clientAppointments = await database.getNotesForClient(this.clientId);

      this.visitsCount = this.clientVisits.length;
      this.appointmentsCount = this.clientAppointments.length;

      if (this.clientPets.length > 0) {
        this.selectedPet = this.clientPets[0];
      }
    },
    handleClose() {
      // Emit the close event without causing a reload
      this.$emit('close');
    },
    getStartTime() {
      return this.editedNote.startTime || this.editedNote.timeStart || '';
    },
    getEndTime() {
      return this.editedNote.endTime || this.editedNote.timeEnd || '';
    },
    async handleSubmit() {
      if (!this.isFormValid) return;

      try {
        const updatedNote = {
          ...this.editedNote,
          text: this.editedNote.description,
          startTime: this.editedNote.startTime || this.editedNote.timeStart,
          endTime: this.editedNote.endTime || this.editedNote.timeEnd,
        };
        console.log("WHATS IN THIS SHIT??", this.editedNote)

        if (this.editedNote.type === 'visit') {
          const clients = await database.getAllClients();
          const client = clients.find(client => client.id === this.selectedClientId);
          if (!client) {
            throw new Error('Selected client not found');
          }

          const pets = await database.getPets(this.selectedClientId);
          const pet = pets.find(pet => pet.id === this.selectedPetId);
          if (!pet) {
            throw new Error('Selected pet not found');
          }
        }

        console.log('Attempting to update note:', updatedNote);

        await database.updateNote(this.note.id, updatedNote);

        // Emit the updated note regardless of the database operation result
        this.$emit('noteUpdated', { ...updatedNote, id: this.note.id });
        this.handleClose();
      } catch (error) {
        console.error('Error updating note:', error);
        // You might want to show an error message to the user here
      }
    },
    onDateTimeChange() {
      // This method can be used if you need to perform any actions when date/time changes
    },
    onClientSelected(clientId) {
      this.selectedClientId = clientId;
      this.editedNote.ownerId = clientId;
      this.selectedPetId = null;
    },
    onPetSelected(id) {
      this.selectedPetId = id;
      this.editedNote.petId = id;
    },
    getDayName(date) {
      const days = Object.keys(messages.weekDays.full)
      const dayIndex = date.getDay()
      const adjustedIndex = (dayIndex + 6) % 7
      return days[adjustedIndex];
    },
    generateTimeSlots(start, end) {
      const times = [];
      let current = start;

      while (current <= end) {
        times.push(current);
        current = utils.addMinutesToStringDate(current, this.parameters.calendarGridInterval);
      }

      return times;
    },
    filterAvailableTimes(times, startFrom = '00:00') {
      return times.filter(time => {
        if (time < startFrom) return false;

        const conflictingNote = this.existingNotes.find(note => {
          return note.date === this.editedNote.date &&
            note.id !== this.editedNote.id &&
            time >= note.startTime &&
            time < note.endTime;
        });

        return !conflictingNote;
      });
    },
    filterAvailableEndTimes(times, startTime) {
      // Find the next conflicting note's start time
      const nextConflictingNote = this.existingNotes
        .filter(note => note.date === this.editedNote.date && note.startTime > startTime)
        .sort((a, b) => a.startTime.localeCompare(b.startTime))[0];

      // Check if there is a next conflicting note
      const nextConflictingTime = nextConflictingNote ? nextConflictingNote.startTime : null;

      return times.filter(time => {
        // Ensure the end time is after the start time
        if (time <= startTime) return false;

        // If there is a next conflicting note, ensure the end time is before its start time or equal to it
        if (nextConflictingTime && time > nextConflictingTime) return false;

        // Check for conflicts with existing notes
        const conflictingNote = this.existingNotes.find(note => {
          return note.date === this.editedNote.date &&
            note.id !== this.editedNote.id &&
            time > note.startTime &&
            time <= note.endTime;
        });

        return !conflictingNote;
      });
    },


    async deleteNote() {
      try {
        await database.removeNote(this.note.id);
        this.$emit('noteDeleted', this.note.id);
        this.handleClose();
      } catch (error) {
        console.error('Failed to delete note:', error);
        // You might want to show an error message to the user here
      }
    },
    async loadData() {
      this.workingHours = await database.getWorkingHours();
      this.existingNotes = await database.getNotes();
      this.dataLoaded = true;
      if (this.note.type === 'visit') {
        this.selectedClientId = this.note.ownerId;
        this.selectedPetId = this.note.petId;
      }
    }
  },
  async created() {
    await this.loadData();
  }
}
</script>

<style scoped>
.edit-calendar-item-form {
  padding: 20px;
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: 20px;
}

.date-time-row {
  display: flex;
}

.time-fields {
  display: flex;
  gap: 20px;
  flex: 2;
}

.button-section {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.left-aligned {
  align-self: flex-start;
}

.right-aligned {
  gap: 10px;
  display: flex;

}

.form-field {
  gap: 75px;
  margin-bottom: -20px;
  display: flex;
  flex-direction: column;
}
</style>