<template>
  <div class="visit-info-display">
    <div class="visit-content">
      <div class="column text-column">
        <div class="info-section">
          <div>
            <label class="input-label">{{ messages.topic }}</label>
            <text-field
              class="topic-field"
              v-model="topic"
              @input="updateVisitData"
              outlined
              dense
            />
          </div>
          <div>
            <label class="input-label">{{ messages.description }}</label>
            <text-area
              v-model="description"
              class="description-field"
              auto-grow
              rows="8"
              @input="updateVisitData"
              outlined
            />
          </div>
        </div>
      </div>

      <!-- Expansion Panels for Medicine and Vaccine Sections -->
      <div class="column tables-column">
        <v-expansion-panels v-model="activePanel" accordion flat>
          <!-- Medicine Section -->
          <v-expansion-panel>
            <v-expansion-panel-header>
              {{ messages.medicines }}
            </v-expansion-panel-header>
            <v-expansion-panel-content>
              <medicine-section
                :medicines="medicines"
                @add-medicine="addNewMedicine"
                @remove-medicine="removeMedicine"
                @update-medicine="updateMedicine"
              />
            </v-expansion-panel-content>
          </v-expansion-panel>

          <!-- Vaccine Section -->
          <v-expansion-panel>
            <v-expansion-panel-header> {{ messages.vaccines }} </v-expansion-panel-header>
            <v-expansion-panel-content>
              <vaccine-section
                :vaccines="currentVisitVaccines"
                @add-vaccine="openAddVaccinePopup"
                @edit-vaccine="editVaccine"
                @remove-vaccine="removeVaccine"
              />
            </v-expansion-panel-content>
          </v-expansion-panel>
        </v-expansion-panels>
      </div>
    </div>

    <!-- Add/Edit Vaccine Popup -->
    <add-edit-vaccine-popup
      v-if="showAddVaccinePopup"
      :edit-mode="editMode"
      :vaccine="editingVaccine"
      :client-id="clientId"
      :pet-id="petId"
      @close="closeVaccinePopup"
      @add-update-vaccine="addOrUpdateVaccine"
    />
  </div>
</template>

<script>
import TextField from '/src/vue/components/TextField.vue'
import TextArea from '/src/vue/components/TextArea.vue'
import MedicineSection from './MedicineSection.vue'
import VaccineSection from './VaccineSection.vue'
import AddEditVaccinePopup from './AddEditVaccinePopup.vue'
import { database } from '/src/vue/ipc.js'
import { messages } from '../../../../locale'

export default {
  name: 'VisitInfoDisplay',
  computed: {
    messages() {
      return messages
    },
  },
  components: {
    TextField,
    TextArea,
    MedicineSection,
    VaccineSection,
    AddEditVaccinePopup,
  },
  props: {
    clientId: {
      type: String,
      required: true,
    },
    petId: {
      type: String,
      required: false,
      default: null,
    },
    visitId: {
      type: String,
      default: null,
    },
  },
  data() {
    return {
      topic: '',
      description: '',
      medicines: [],
      currentVisitVaccines: [],
      showAddVaccinePopup: false,
      editMode: false,
      editingVaccine: {},
      existingVaccineCycles: [],
      nextMedicineId: 1,
    }
  },
  watch: {
    petId: {
      immediate: true,
      handler(newPetId) {
        if (newPetId) {
          this.loadExistingVaccineCycles()
          this.loadVisitData()
        }
      },
    },
    visitId: {
      immediate: true,
      handler() {
        this.loadVisitData()
      },
    },
  },
  methods: {
    updateVisitData() {
      this.$emit('visit-data-updated', {
        topic: this.topic,
        description: this.description,
        medicines: this.medicines,
        vaccines: this.currentVisitVaccines,
      })
    },
    addNewMedicine() {
      this.medicines.push({
        id: this.nextMedicineId++,
        name: '',
        amount: 0,
      })
      this.updateVisitData()
    },
    removeMedicine(id) {
      this.medicines = this.medicines.filter((medicine) => medicine.id !== id)
      this.updateVisitData()
    },
    updateMedicine(updatedMedicine) {
      const index = this.medicines.findIndex((m) => m.id === updatedMedicine.id)
      if (index !== -1) {
        this.medicines[index] = updatedMedicine
      }
      this.updateVisitData()
    },
    openAddVaccinePopup() {
      this.showAddVaccinePopup = true
      this.editMode = false
      this.editingVaccine = {}
    },
    editVaccine(vaccine) {
      this.editMode = true
      this.editingVaccine = { ...vaccine }
      this.showAddVaccinePopup = true
    },
    removeVaccine(id) {
      this.currentVisitVaccines = this.currentVisitVaccines.filter(
        (vaccine) => vaccine.id !== id,
      )
      this.updateVisitData()
    },
    closeVaccinePopup() {
      this.showAddVaccinePopup = false
      this.editMode = false
      this.editingVaccine = {}
    },
    addOrUpdateVaccine(vaccine) {
      console.log('Received vaccine:', vaccine);

      const existingIndex = this.currentVisitVaccines.findIndex(
        (v) => v.id === vaccine.id,
      );

      if (existingIndex !== -1) {
        // Update existing vaccine using Vue.set to maintain reactivity
        this.$set(this.currentVisitVaccines, existingIndex, { ...vaccine });
      } else {
        // Add new vaccine
        this.currentVisitVaccines.push({ ...vaccine });
      }

      this.closeVaccinePopup();
      this.updateVisitData();
      console.log('Updated currentVisitVaccines:', this.currentVisitVaccines);
    },
    async loadExistingVaccineCycles() {
      if (this.clientId && this.petId) {
        try {
          this.existingVaccineCycles = await database.getVaccineCyclesForPet(
            this.clientId,
            this.petId,
          )
        } catch (error) {
          console.error('Error loading existing vaccine cycles:', error)
          // TODO: Add user-facing error notification
        }
      }
    },
    async loadVisitData() {
      if (this.visitId) {
        try {
          const allVisits = await database.getVisits()
          const visit = allVisits.find((v) => v.id === this.visitId)
          if (visit) {
            this.topic = visit.topic || ''
            this.description = visit.description || ''
            this.medicines = visit.medicines || []
            this.currentVisitVaccines = visit.vaccines || []
            this.nextMedicineId =
              Math.max(...this.medicines.map((m) => m.id || 0), 0) + 1
            this.updateVisitData()
          } else {
            console.error('Visit not found')
            // TODO: Add user-facing error notification
          }
        } catch (error) {
          console.error('Error loading visit data:', error)
          // TODO: Add user-facing error notification
        }
      }
    },
  },
  created() {
    if (this.clientId && this.petId) {
      this.loadExistingVaccineCycles()
    }
    if (this.visitId) {
      this.loadVisitData()
    }
  },
}
</script>

<style scoped>
.visit-info-display {
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 0 40px;
}

.visit-content {
  display: flex;
  gap: 40px;
}

.column {
  flex: 1;
  display: flex;
  flex-direction: column;
}

.tables-column {
  margin-top: 30px;
  flex: 2;
  display: flex;
  flex-direction: column;
}

.info-section {
  display: flex;
  flex-direction: column;
  border-radius: 8px;
  background: #fff;
  gap: 20px;
}

.input-label {
  display: block;
  margin-bottom: 8px;
  color: #08616d;
  font-family: Poppins, sans-serif;
  font-size: 14px;
  font-weight: 500;
}

.topic-field,
.description-field {
  width: 100%;
  font-family: Poppins, sans-serif;
  font-size: 14px;
}

.description-field {
  min-height: 100px;
  resize: vertical;
}

/* Expansion Panel Styles */
.v-expansion-panel-header {
  font-family: Poppins, sans-serif;
  font-size: 16px;
  font-weight: 500;
  color: #08616d;
}

.v-expansion-panel {
  border: 1px solid #ccc;
  border-radius: 5px;
}
.v-expansion-panel:not(:last-child) {
  margin-bottom: 20px;
}
</style>
