<template>
  <div>
    <v-text-field
      v-model="displayValue"
      :label="label"
      dense
      outlined
      :color="color"
      @input="handleInput"
    />
  </div>
</template>

<script>
export default {
  name: 'ChipNumberInput',
  props: {
    value: {
      type: String,
      default: '',
    },
    label: {
      type: String,
      default: '',
    },
    color: {
      type: String,
      default: 'primary',
    },
  },
  data() {
    return {
      localValue: this.value,
    };
  },
  computed: {
    displayValue: {
      get() {
        return this.formatChipNumber(this.localValue);
      },
      set(value) {
        this.localValue = value.replace(/\s/g, '');
      },
    },
  },
  methods: {
    handleInput() {
      this.$emit('input', this.localValue);
    },
    formatChipNumber(value) {
      const cleaned = value.replace(/\s/g, '');
      const groups = cleaned.match(/.{1,3}/g) || [];
      return groups.join(' ');
    },
  },
};
</script>