<template>
  <div class="client-search-dropdown">
    <v-autocomplete
      v-model="selectedClientId"
      :items="clientItems"
      :label="label"
      :prepend-inner-icon="prependInnerIcon"
      item-text="display"
      item-value="id"
      @change="emitselectedClientId"
      color="var(--mosque)"
      :filter="customFilter"
      :menu-props="{ maxHeight: '280px' }"
      v-bind="$attrs"
      dense
      :no-data-text="noDataText"
    >
      <template v-slot:item="{ item }">
        <v-list-item-content>
          <v-list-item-title v-text="item.display"></v-list-item-title>
          <v-list-item-subtitle class="d-flex justify-space-between align-center mt-1">
            <div class="email-section">
              <v-icon small class="mr-1">mdi-email</v-icon>
              <span>{{ item.email }}</span>
            </div>
            <div class="phone-section">
              <v-icon small class="mr-1">mdi-phone</v-icon>
              <span>{{ item.phone }}</span>
            </div>
          </v-list-item-subtitle>
        </v-list-item-content>
      </template>
    </v-autocomplete>
  </div>
</template>

<script>
import { database } from '../ipc'
import { messages as locale } from '../locale'

export default {
  name: 'ClientSearchDropdown',
  inheritAttrs: false,
  props: {
    value: {
      type: [Number, String],
      default: null
    },
    label: String,
    prependInnerIcon: String,
  },
  data() {
    return {
      selectedClientId: this.value,
      clients: [],
      noDataText: locale.dropdownEmptyClientList,
    };
  },
  computed: {
    clientItems() {
      return this.clients.map(client => ({
        id: client.id,
        display: `${client.fullName}`,
        email: client.email,
        phone: client.phone
      }));
    }
  },
  methods: {
    emitselectedClientId() {
      console.log(this.selectedClientId)
      this.$emit('input', this.selectedClientId);
      this.$emit('client-selected', this.selectedClientId);
    },
    customFilter(item, queryText, itemText) {
      const searchText = queryText.toLowerCase();
      return item.display.toLowerCase().includes(searchText) ||
        item.email.toLowerCase().includes(searchText) ||
        item.phone.includes(searchText);
    },
    async updateClientList() {
      this.clients = Object.entries(await database.getAllClients()).map(([id, client]) => ({
        id: Number(id),
        ...client
      }));
    }
  },
  created() {
    this.updateClientList();
  },
  watch: {
    value(newValue) {
      this.selectedClientId = newValue;
    }
  }
}
</script>

<style scoped>
.client-search-dropdown {
  height: 0;
}

.v-list-item__subtitle {
  display: flex !important;
  justify-content: space-between !important;
  align-items: center !important;
}

.email-section, .phone-section {
  display: flex;
  align-items: center;
}

.email-section {
  margin-right: auto;
}

.phone-section {
  margin-left: auto;
}

::v-deep .v-text-field.v-text-field--outlined fieldset {
  opacity: 0.5;
}
</style>