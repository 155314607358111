<template>
  <div class="sorting">
    <div class="sorting-1" @click="togglePopup">
      <img v-if="!type" class="frame-15" src="../../../../public/img/frame-18.svg" alt="Frame" />
      <i v-if="type === 'name' && order === 'asc'" class="icon mdi mdi-sort-alphabetical-ascending"></i>
      <i v-if="type === 'name' && order === 'desc'" class="icon mdi mdi-sort-alphabetical-descending"></i>
      <i v-if="type === 'date' && order === 'asc'" class="icon mdi mdi-sort-calendar-ascending"></i>
      <i v-if="type === 'date' && order === 'desc'" class="icon mdi mdi-sort-calendar-descending"></i>
      <div class="label-1 inter-semi-bold-elephant-16px">{{ label }}</div>
    </div>
    <transition name="fade">
      <div v-if="showPopup" class="sorting-popup" @mouseleave.self="closePopup">
        <div @click="sort('name', 'asc')">
          <i class="mdi mdi-sort-alphabetical-ascending"></i>
          {{ locale.sortAlphabeticalAscending }}
        </div>
        <div @click="sort('name', 'desc')">
          <i class="mdi mdi-sort-alphabetical-descending"></i>
          {{ locale.sortAlphabeticalDescending }}
        </div>
        <div @click="sort('date', 'asc')">
          <i class="mdi mdi-sort-calendar-ascending"></i>
          {{ locale.sortTimedAscending }}
        </div>
        <div @click="sort('date', 'desc')">
          <i class="mdi mdi-sort-calendar-descending"></i>
          {{ locale.sortTimedDescending }}
        </div>
      </div>
    </transition>
  </div>
</template>

<script>
import { messages as locale } from '../../locale'

export default {
  name: 'Sorting',
  computed: {
    locale() {
      return locale
    }
  },
  props: ['label'],
  emits: ['sort'],
  data() {
    return {
      showPopup: false,
      type: null,
      order: null,
    }
  },
  methods: {
    togglePopup() {
      this.showPopup = !this.showPopup
    },
    closePopup() {
      this.showPopup = false
    },
    sort(type, order) {
      this.type = type
      this.order = order
      this.$emit('sort', { type, order })
      this.showPopup = false
    }
  }
}
</script>

<style scoped>
.sorting {
  position: relative;
  display: inline-block;
}

.sorting-1 {
  align-items: center;
  background-color: var(--white);
  border: 1px solid;
  border-color: var(--bon-jour);
  border-radius: 8px;
  display: flex;
  gap: 12px;
  height: 40px;
  padding: 8px 16px 8px 12px;
  position: relative;
  width: 114px;
  cursor: pointer;
}

.frame-15 {
  height: 24px;
  position: relative;
  width: 24px;
}

.icon {
  font-size: 22px;
  margin-bottom: 2px
}

.label-1 {
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 1;
  display: -webkit-box;
  letter-spacing: 0;
  line-height: 24px;
  overflow: hidden;
  position: relative;
  text-overflow: ellipsis;
  white-space: nowrap;
  width: fit-content;
}

.sorting-popup {
  position: absolute;
  top: 100%;
  right: 0;
  margin-top: 10px;
  background-color: white;
  border: 1px solid var(--bon-jour);
  border-radius: 8px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
  z-index: 10;
  min-width: 250px;
}

.sorting-popup div {
  padding: 8px 12px;
  cursor: pointer;
  display: flex;
  align-items: center;
  transition: background-color 0.3s ease;
}

.sorting-popup div:hover {
  background-color: #f0f0f0;
}

.sorting-popup i {
  margin-right: 8px;
  font-size: 20px;
}

.fade-enter-active, .fade-leave-active {
  transition: opacity 0.3s ease;
}

.fade-enter, .fade-leave-to {
  opacity: 0;
}
</style>