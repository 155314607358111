<template>
  <div class="calendar">
    <WorkTimeOverlay
      v-if="showWorkTimePopUp"
      @close="closeWorkTimePopUp"
      @workingHoursUpdated="updateGrid"
    />
    <AddCalendarItem
      :selectedSlot="selectedSlot"
      :selectedDate="currentDate"
      @close="closeAddCalendarItemPopUp"
      @noteAdded="onNoteAdded"
      v-if="showAddCalendarItemPopUp"
    />
    <EditCalendarItem
      :note="selectedNote"
      @close="closeEditCalendarItemPopUp"
      @noteUpdated="onNoteUpdated"
      @noteDeleted="onNoteDeleted"
      v-if="showEditCalendarItemPopUp"
    />
    <div class="calendar__header">
      <div class="calendar__buttons">
        <button class="calendar__button calendar__button--filled" @click="openAddCalendarItemPopUp">
          <img
            class="calendar__button-icon"
            src="../../../../public/img/pencil-white.svg"
            alt="Add visit"
          />
          <span class="calendar__button-text">
            {{ messages.calendarAddVisit }}
          </span>
        </button>
        <button class="calendar__button calendar__button--outline" @click="openWorkTimePopUp">
          <img class="calendar__button-icon" src="../../../../public/img/frame-2.svg" alt="Manage working time" />
          <span class="calendar__button-text">
            {{ messages.calendarManageWorkingTime}}
          </span>
        </button>
      </div>
      <div class="calendar__date-display">
        <div class="calendar__date-navigation">
          <img class="calendar__nav-arrow" src="../../../../public/img/frame.svg" alt="Previous" @click="changeDate(-1)" />
          <div class="calendar__date-picker">
            <img
              class="calendar__calendar-icon"
              src="../../../../public/img/calendar-purple.svg"
              alt="Calendar"
            />
            <span class="calendar__date">{{ formattedDate }}</span>
          </div>
          <img class="calendar__nav-arrow" src="../../../../public/img/frame-1.svg" alt="Next" @click="changeDate(1)" />
        </div>
        <div class="calendar__date-text">
          {{ formattedDayAndMonth  }}
        </div>
      </div>
    </div>
    <div class="calendar__content">
      <div v-if="workingHoursLoaded" class="calendar__time-section">
        <TimeSection
          :notes="filteredNotes"
          :timeSlots="generateTimeSlots()"
          :key="updateKey"
          @empty-slot-click="openAddCalendarItemPopUp"
          @note-click="openEditCalendarItemPopUp"
        />
      </div>
      <div v-else class="calendar__loading">
        Loading working hours...
      </div>
    </div>
  </div>
</template>

<script>
import WorkTimeOverlay from '../../pages/calendar/work-time-pop-up/WorkTimeOverlay.vue'
import AddCalendarItem from '../../pages/calendar/add-calendar-overlay/AddCalendarItem.vue'
import TimeSection from '../../pages/calendar/TimeSection.vue'
import { database } from '../../ipc.js'
import EditCalendarItem from '../../pages/calendar/add-calendar-overlay/EditCalendarItem.vue'
import { parameters } from '../../settings'
import { messages } from '@/vue/locale'
import DatePicker from '@/vue/components/DatePicker.vue'

export default {
  name: 'Calendar',
  components: {
    DatePicker,
    TimeSection,
    WorkTimeOverlay,
    AddCalendarItem,
    EditCalendarItem,
  },
  data() {
    return {
      notes: [],
      showWorkTimePopUp: false,
      showAddCalendarItemPopUp: false,
      showEditCalendarItemPopUp: false,
      updateKey: 0,
      selectedSlot: null,
      selectedNote: null,
      currentDate: new Date(),
      currentWorkingHours: {},
      workingHoursLoaded: false,
    }
  },
  computed: {
    messages() {
      return messages
    },
    formattedDate() {
      return this.currentDate.toLocaleDateString('en-GB')
    },
    formattedDayAndMonth() {
      return this.currentDate.toLocaleDateString('lv-LV', { weekday: 'long', day: 'numeric', month: 'long' })
    },
    filteredNotes() {
      return this.notes.filter(note => {
        const noteDate = new Date(note.date)
        return noteDate.toDateString() === this.currentDate.toDateString()
      })
    },
  },
  methods: {
    openWorkTimePopUp() {
      this.showWorkTimePopUp = true
    },
    closeWorkTimePopUp() {
      this.showWorkTimePopUp = false
      this.updateGrid()
    },
    openAddCalendarItemPopUp(slot) {
      this.selectedSlot = typeof slot === 'string' ? slot : null;
      this.showAddCalendarItemPopUp = true;
    },
    closeAddCalendarItemPopUp() {
      this.showAddCalendarItemPopUp = false
      this.selectedSlot = null
    },
    async onNoteAdded(newNote) {
      await this.loadNotes()
      this.updateGrid()
      this.closeAddCalendarItemPopUp()
    },
    openEditCalendarItemPopUp(note) {
      this.selectedNote = note;
      this.showEditCalendarItemPopUp = true;
    },
    closeEditCalendarItemPopUp() {
      this.showEditCalendarItemPopUp = false;
      this.selectedNote = null;
    },
    async loadNotes() {
      this.notes = await database.getNotes() || []
      this.updateKey += 1
    },
    async refreshCalendar() {
      await this.loadNotes()
      await this.loadWorkingHours()
      this.updateGrid()
    },

    // async onNoteAdded(newNote) {
    //   await database.addNote(newNote)
    //   await this.refreshCalendar()
    //   this.closeAddCalendarItemPopUp()
    // },
    onNoteDeleted(noteId) {
      this.notes = this.notes.filter(note => note.id !== noteId);
      this.loadNotes();
      this.closeEditCalendarItemPopUp();
    },
    async onNoteUpdated(updatedNote) {
      const index = this.notes.findIndex(note => note.id === updatedNote.id);
      if (index !== -1) {
        this.notes.splice(index, 1, updatedNote);
      }
      await database.updateNote(updatedNote);
      await this.loadNotes();
      this.closeEditCalendarItemPopUp();
    },
    changeDate(days) {
      const newDate = new Date(this.currentDate);
      newDate.setDate(newDate.getDate() + days);
      this.currentDate = newDate;
      this.loadNotes();
      this.updateGrid();
    },
    async updateGrid() {
      await this.loadWorkingHours()
      this.updateKey += 1
    },
    async loadWorkingHours() {
      this.currentWorkingHours = await database.getWorkingHours()
      this.workingHoursLoaded = true
    },
    generateTimeSlots() {
      if (!this.workingHoursLoaded) {
        return []
      }

      const currentDay = this.getCurrentDayName()
      const dayData = this.currentWorkingHours[currentDay]

      if (!dayData || !dayData.isOpen) {
        return undefined
      }

      const { start, end } = dayData
      const timeSlots = [];
      const [startHour, startMinute] = start.split(':').map(Number);
      const [endHour, endMinute] = end.split(':').map(Number);

      let hours = startHour;
      let minutes = startMinute;

      while (hours < endHour || (hours === endHour && minutes <= endMinute)) {
        const formattedTime = `${hours.toString().padStart(2, '0')}:${minutes.toString().padStart(2, '0')}`;
        timeSlots.push(formattedTime);

        minutes += parameters.calendarGridInterval;
        if (minutes >= 60) {
          minutes -= 60;
          hours += 1;
        }
      }
      return timeSlots;
    },
    getCurrentDayName() {
      const days = Object.keys(messages.weekDays.full)
      const dayIndex = this.currentDate.getDay()
      const adjustedIndex = (dayIndex + 6) % 7
      console.log('TODAY', days[adjustedIndex])
      return days[adjustedIndex];
    },
  },
  async created() {
    await this.loadNotes()
    await this.loadWorkingHours()
  },
}
</script>

<style scoped>
.calendar {
  display: flex;
  flex-direction: column;
  gap: 22px;
  position: fixed;
  top: 98px; /* Keep the existing top margin */
  left: 240px; /* Adjust based on your sidebar width */
  right: 20px; /* Add some right margin */
  bottom: 5vh; /* Create a 5% gap at the bottom */
  z-index: 2;
}

.calendar__header {
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.calendar__buttons {
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
}

.calendar__button {
  display: flex;
  align-items: center;
  gap: 6px;
  padding: 12px 15px;
  border-radius: 8px;
  cursor: pointer;
  font-family: var(--font-family-inter), serif;
  font-size: var(--font-size-m);
  font-weight: 600;
  white-space: nowrap;
}

.calendar__button--filled {
  background-color: var(--camelot);
  color: white;
  border: none;
}

.calendar__button--outline {
  background-color: transparent;
  color: var(--camelot);
  border: 2px solid var(--camelot);
}

.calendar__button-icon {
  width: 24px;
  height: 24px;
}

.calendar__date-display {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 5px;
}

.calendar__date-navigation {
  display: flex;
  align-items: center;
  gap: 5px;
}

.calendar__nav-arrow {
  width: 24px;
  height: 24px;
  cursor: pointer;
}

.calendar__date-picker {
  display: flex;
  align-items: center;
  gap: 5px;
}

.calendar__calendar-icon {
  width: 18px;
  height: 18px;
}

.calendar__date {
  color: var(--camelot);
  font-family: var(--font-family-inter), serif;
  font-size: var(--font-size-m);
  font-weight: 500;
}

.calendar__date-text {
  color: var(--elephant-2);
  font-family: var(--font-family-inter), serif;
  font-size: var(--font-size-s);
  font-weight: 400;
}

.calendar__content {
  background-color: var(--white);
  border-radius: 8px;
  box-shadow: 2px 4px 6px rgba(0, 0, 0, 0.15);
  flex: 1;
  display: flex;
  flex-direction: column;
  overflow: hidden;
}

.calendar__time-section {

  flex: 1;
  overflow-y: auto;
  padding: 30px;
  scrollbar-width: thin;
}

.calendar__time-section::-webkit-scrollbar {
  width: 6px;
}

.calendar__time-section::-webkit-scrollbar-track {
  background: transparent;
}

.calendar__time-section::-webkit-scrollbar-thumb {
  border-radius: 3px;
}

.calendar__loading {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  font-family: var(--font-family-inter), serif;
  font-size: var(--font-size-m);
  color: var(--elephant-2);
}

@media (min-width: 768px) {
  .calendar__header {
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
  }

  .calendar__date-display {
    margin-right: 0;
  }
}

@media (max-width: 767px) {
  .calendar {
    padding: 0 10px;
  }

  .calendar__button-text {
    display: none;
  }

  .calendar__button {
    padding: 10px;
  }

  .calendar__date-text {
    font-size: var(--font-size-xs);
  }
}

@media (max-width: 480px) {
  .calendar__buttons {
    justify-content: center;
  }

  .calendar__date-display {
    width: 100%;
  }

  .calendar__date-navigation {
    justify-content: space-between;
    width: 100%;
  }
}
</style>