<template>
  <div class="pet">
    <registration-nav :active-index="0" @change-active="HandleClick"/>
    <headline-title :text="messages.clientAddition" />
    <div class="search-section">
      <client-search-dropdown
        v-model="selectedClientId"
        :label="messages.dropdownClientSearch"
        prepend-inner-icon="mdi-magnify"
        class="search-bar"
        type="default"
        @client-selected="handleClientSelected"
        outlined
        ref="clientSearchDropdown"
      />
      <clicky-button
        :text="messages.addNewClient"
        button-class="primary"
        @click="showAddClientPopup"
        class="add-client-button"
      />
    </div>
    <client-info-display :clientId="selectedClientId" />
    <add-client-pop-up
      v-if="isAddClientPopupVisible"
      @close="hideAddClientPopup"
      @client-added="handleNewClientAdded"
    />
  </div>
</template>

<script>
import ClientInfoDisplay from './ClientInfoDisplay.vue'
import ClickyButton from '/src/vue/components/ClickyButton.vue'
import AddClientPopUp from '/src/vue/pages/register/sections/client/AddClientPopUp.vue'
import RegistrationNav from '/src/vue/pages/register/RegistrationNav.vue'
import HeadlineTitle from '/src/vue/components/HeadlineTitle.vue'
import { database } from '/src/vue/ipc'
import ClientSearchDropdown from '@/vue/components/ClientSearchDropdown.vue'
import { messages } from '@/vue/locale'

export default {
  name: 'ClientRegistration',
  computed: {
    messages() {
      return messages
    }
  },
  components: {
    HeadlineTitle,
    RegistrationNav,
    ClientSearchDropdown,
    ClickyButton,
    ClientInfoDisplay,
    AddClientPopUp,
  },
  data() {
    return {
      isAddClientPopupVisible: false,
      selectedClientId: null,
    }
  },
  methods: {
    HandleClick(index) {
      if (index == 1){
        this.$emit('next')
      }
    },
    showAddClientPopup() {
      this.isAddClientPopupVisible = true
    },
    hideAddClientPopup() {
      this.isAddClientPopupVisible = false
    },
    async handleClientSelected(clientId) {
      console.log('Outside dropdown', clientId)
      this.selectedClientId = clientId
      this.$emit('client-selected', clientId)
    },
    async handleNewClientAdded(newClientId) {
      this.selectedClientId = newClientId
      this.$emit('client-selected', newClientId)
      this.$nextTick(() => {
        this.$refs.clientSearchDropdown.updateClientList()
      })
    },
  },
}
</script>

<style scoped>
@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@400;500&display=swap');

.pet {
  background-color: var(--white);
  border-radius: 8px;
  box-shadow: 2px 4px 6px rgba(0, 0, 0, 0.15);
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  font-family: 'Poppins', sans-serif;
  gap: 15px;
  height: 520px;
  width: 100%;
  padding-bottom: 40px;
}

.search-section {
  margin-top: 15px;
  margin-left: 40px;
  display: flex;
  width: calc(100% - 80px);
  gap: 20px;
}

.search-bar {
  flex: 1;
  min-width: 200px;
  margin: 0;
}

.add-client-button {
  white-space: nowrap;
  min-width: 200px;
  height: 40px;
}
</style>