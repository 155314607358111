<template>
  <v-btn
    :class="['action-button', buttonClass]"
    @click="$emit('click', $event)"
    :style="buttonStyle"
    type="text"
    class="text-none"
  >
    <v-icon v-if="icon" small :style="iconStyle">{{ icon }}</v-icon>
    <div v-if="text" class="action-button-text-style">
      {{ text }}
    </div>
  </v-btn>
</template>

<script>
export default {
  name: 'PopUpActionButton',
  props: {
    text: {
      type: String,
      default: ''
    },
    icon: {
      type: String,
      default: ''
    },
    buttonClass: {
      type: String,
      default: ''
    },
    backgroundColor: {
      type: String,
      default: ''
    },
    textColor: {
      type: String,
      default: ''
    },
    borderColor: {
      type: String,
      default: ''
    },
    iconColor: {
      type: String,
      default: ''
    },
    buttonType: {
      type: String,
      default: ''
    }
  },
  computed: {
    buttonStyle() {
      if (this.buttonType == 'text'){
        return {
          backgroundColor: 'transparent',
          color: this.textColor || 'var(--camelot)',
          boxShadow: 'none',
        }
      } else if (this.buttonType == 'outlined'){
        return {
          backgroundColor: 'transparent',
          color: this.textColor || 'var(--camelot)',
          borderColor: this.borderColor || 'var(--camelot)',
          borderStyle: 'solid',
          borderWidth: '1px',
          boxShadow: 'none',
        }
      } else{
        return {
          backgroundColor: this.backgroundColor || 'transparent',
          color: this.textColor || 'var(--camelot)',
        }        
      }

    },
    iconStyle() {
      return {
        color: this.iconColor || this.textColor || 'var(--camelot)'
      }
    }
  }
}
</script>

<style scoped>
.action-button {
  font-family: 'Inter', sans-serif;
  font-size: 16px;
  font-weight: 600;
  padding: 8px 16px;
  border-radius: 5px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  min-width: 34px;
  height: 40px;
  /* width: 50%; */
  transition: all 0.3s ease;
}

/* .action-button-text-style {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.action-button:hover {
  opacity: 0.8;
} */

.v-icon {
  font-size: 18px;

}
</style>