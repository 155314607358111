<template>
  <div class="pet">
    <registration-nav :active-index="1" @change-active="HandleClick"/>
    <headline-title :text="messages.petAddition" />
    <div class="search-section">
      <pet-search-dropdown
        v-model="selectedPetId"
        :label="messages.dropdownPetSearch"
        prepend-inner-icon="mdi-magnify"
        class="search-bar"
        type="default"
        @pet-selected="handlePetSelected"
        outlined
        :clientId="clientId"
        ref="petSearchDropdown"
      />
      <clicky-button
        :text="messages.addNewPet"
        button-class="primary"
        @click="showPetClientPopup"
        class="add-pet-button"
      />
    </div>
    <pet-info-display :petId="selectedPetId" :clientId="clientId"/>
    <add-pet-pop-up
      v-if="isAddPetPopupVisible"
      :clientId="clientId"
      @close="hideAddPetPopup"
      @pet-added="handleNewPetAdded"
    />
  </div>
</template>

<script>
import ClickyButton from '/src/vue/components/ClickyButton.vue'
import AddClientPopUp from '/src/vue/pages/register/sections/client/AddClientPopUp.vue'
import PetInfoDisplay from '/src/vue/pages/register/sections/pet/PetInfoDisplay.vue'
import RegistrationNav from '/src/vue/pages/register/RegistrationNav.vue'
import AddPetPopUp from '/src/vue/pages/register/sections/pet/AddPetPopUp.vue'
import HeadlineTitle from '/src/vue/components/HeadlineTitle.vue'
import ClientSearchDropdown from '@/vue/components/ClientSearchDropdown.vue'
import PetSearchDropdown from '@/vue/components/PetSearchDropdown.vue'
import { messages } from '@/vue/locale'

export default {
  name: 'PetRegistration',
  computed: {
    messages() {
      return messages
    }
  },
  props: {
    clientId: {
      type: String,
      required: true
    }
  },
  components: {
    HeadlineTitle,
    AddPetPopUp,
    RegistrationNav,
    PetSearchDropdown,
    PetInfoDisplay,
    ClientSearchDropdown,
    ClickyButton,
    AddClientPopUp,
  },
  data() {
    return {
      isAddPetPopupVisible: false,
      selectedPetId: null,
    }
  },
  methods: {
    HandleClick(index) {
      if (index == 2){
        this.$emit('next')
      }
    },
    showPetClientPopup() {
      this.isAddPetPopupVisible = true
    },
    hideAddPetPopup() {
      this.isAddPetPopupVisible = false
    },
    handlePetSelected(petId) {
      this.selectedPetId = petId;
      this.$emit('pet-selected', petId);
    },
    handleNewPetAdded(newPetId) {
      this.selectedPetId = newPetId;
      this.$emit('pet-selected', newPetId);
      this.$nextTick(() => {
        if (this.$refs.petSearchDropdown) {
          this.$refs.petSearchDropdown.updatePetList();
        }
      });
    },
  }
}
</script>

<style scoped>
@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@400;500&display=swap');

.pet {
  background-color: var(--white);
  border-radius: 8px;
  box-shadow: 2px 4px 6px rgba(0, 0, 0, 0.15);
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  font-family: 'Poppins', sans-serif;
  gap: 15px;
  width: 100%;
  padding-bottom: 40px;
  height: 520px;
}

.search-section {
  margin-top: 15px;
  margin-left: 40px;
  display: flex;
  width: calc(100% - 80px);
  gap: 20px;
}

.search-bar {
  flex: 1;
  min-width: 200px;
  margin: 0;
}

.add-pet-button {
  white-space: nowrap;
  min-width: 200px;
  height: 40px;
}
</style>