<!-- RegistrationNav.vue -->
<template>
  <nav class="pet-nav">
    <ul class="nav-items">
      <li
        v-for="(item, index) in navItems"
        :key="index"
        :class="['nav-item', { active: activeIndex === index}, {strange: index === 0}]"
        @click="$emit('change-active', index)"
      >
        {{ item }}
      </li>
    </ul>
  </nav>
</template>

<script>
import { messages } from '@/vue/locale'

export default {
  name: 'RegistrationNav',
  props: {
    activeIndex: {
      type: Number,
      default: 0
    }
  },
  data() {
    return {
      navItems: messages.registrationNavBarSections
    }
  }
}
</script>

<style scoped>
.pet-nav {
  border-bottom: 1px solid var(--bon-jour);
}

.nav-items {
  display: flex;
  list-style-type: none;
  margin: 0;
  padding: 0;
}

.nav-item {
  align-items: center;
  border-right: 1px solid var(--bon-jour);
  color: var(--elephant);
  cursor: pointer;
  display: flex;
  font-size: 16px;
  font-weight: 500;
  height: 48px;
  justify-content: center;
  opacity: 0.5;
  padding: 0 15px;
  transition: opacity 0.3s ease;
}

.nav-item:hover {
  opacity: 0.8;
}

.nav-item.strange {   /* only the first nav item has a smooth corner, dunno how to do it */
  border-radius: 8px 0 0 0;
}

.nav-item.active {
  background: linear-gradient(180deg, rgb(8, 97, 109) 0%, rgb(0, 130, 128) 100%);
  color: white;
  opacity: 1;
}

</style>