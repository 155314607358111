<template>
  <div class="pet-search-dropdown">
    <v-autocomplete
      v-model="selectedPetId"
      :items="petItems"
      :label="label"
      :prepend-inner-icon="prependInnerIcon"
      item-text="display"
      item-value="id"
      @change="emitSelectedPetId"
      color="var(--mosque)"
      :filter="customFilter"
      :menu-props="{ maxHeight: '280px' }"
      v-bind="$attrs"
      dense
      :loading="loading"
      :no-data-text="noDataText"
      :disabled="!clientId || loading"
    >
      <template v-slot:item="{ item }">
        <v-list-item-content>
          <v-list-item-title v-text="item.display"></v-list-item-title>
          <v-list-item-subtitle class="d-flex justify-space-between align-center mt-1">
            <div class="type-section">
              <v-icon small class="mr-1">mdi-paw</v-icon>
              <span>{{ item.type }}</span>
            </div>
            <div class="chip-section">
              <v-icon small class="mr-1">mdi-order-numeric-ascending</v-icon>
              <span>{{ item.chipNumber }}</span>
            </div>
          </v-list-item-subtitle>
        </v-list-item-content>
      </template>
    </v-autocomplete>
  </div>
</template>

<script>
import { database } from '../ipc.js'
import { messages as locale } from '@/vue/locale'

export default {
  name: 'PetSearchDropdown',
  inheritAttrs: false,
  props: {
    value: {
      type: [Number, String],
      default: null
    },
    label: String,
    prependInnerIcon: String,
    clientId: {
      type: [String, Number],
      default: null
    }
  },
  data() {
    return {
      selectedPetId: this.value,
      pets: [],
      loading: false,
      noDataText: locale.dropdownEmptyPetList, // Latvian for "No pets found"
    };
  },
  computed: {
    petItems() {
      return this.pets.map(pet => ({
        id: pet.id,
        display: `${pet.name}`,
        type: pet.type,
        chipNumber: pet.chipNumber
      }));
    }
  },
  methods: {
    async fetchPets() {
      if (this.clientId) {
        this.loading = true;
        try {
          this.pets = await database.getPets(this.clientId.toString()) || [];
        } catch (error) {
          console.error('Error fetching pets:', error);
          this.pets = [];
        } finally {
          this.loading = false;
        }
      } else {
        this.pets = [];
      }
    },
    emitSelectedPetId() {
      this.$emit('input', this.selectedPetId);
      this.$emit('pet-selected', this.selectedPetId);
    },
    customFilter(item, queryText, itemText) {
      const searchText = queryText.toLowerCase();
      return item.display.toLowerCase().includes(searchText) ||
        item.type.toLowerCase().includes(searchText) ||
        item.chipNumber.includes(searchText);
    },
    selectNewPet(newPetId) {
      this.selectedPetId = newPetId;
      this.emitSelectedPetId();
    },
    updatePetList() {
      this.fetchPets();
    },
  },
  watch: {
    value(newValue) {
      this.selectedPetId = newValue;
    },
    clientId: {
      immediate: true,
      handler() {
        this.fetchPets();
        // this.selectedPetId = null;
      }
    },
    pets: {
      immediate: true,
      handler(newPets) {
        if (newPets && newPets.length > 0 && !this.selectedPetId) {
          this.selectedPetId = newPets[newPets.length - 1].id;
          this.emitSelectedPetId();
        }
      }
    }
  }
}
</script>

<style scoped>
.pet-search-dropdown {
  /* Styles remain unchanged */
}

.v-list-item__subtitle {
  display: flex !important;
  justify-content: space-between !important;
  align-items: center !important;
}

.type-section, .chip-section {
  display: flex;
  align-items: center;
}

.type-section {
  margin-right: auto;
}

.chip-section {
  margin-left: auto;
}

::v-deep .v-text-field.v-text-field--outlined fieldset {
  opacity: 0.5;
}
</style>