<!-- AddPetPopUp.vue -->
<template>
  <modal-template @close="$emit('close')">
      <smaller-headline :text="messages.addNewPet" />
      <form @submit.prevent="handleSubmit">
        <div class="Form">
          <div>
            <div>
              <h1 class="field-label">{{ messages.firstName }}</h1>
              <v-text-field
                v-model="form.name"
                dense
                outlined
                color="var(--mosque)"
              />
            </div>
            <div>
              <h1 class="field-label">{{ messages.species }}</h1>
              <v-autocomplete
                v-model="form.type"
                :items="animalTypeOptions"
                item-text="text"
                item-value="value"
                dense
                outlined
                color="var(--mosque)"
                auto-select-first
                clearable
              />
            </div>
            <div>
              <h1 class="field-label">{{ messages.weight }}</h1>
              <v-text-field
                v-model="form.weight"
                dense
                outlined
                type="number"
                step="0.1"
                min="0"
                color="var(--mosque)"
              />
            </div>
          </div>
          <div>
            <div>
              <h1 class="field-label">{{ messages.sex }}</h1>
              <v-autocomplete
                v-model="form.sex"
                :items="Object.values(messages.sexes)"
                dense
                outlined
                color="var(--mosque)"
                auto-select-first
                clearable
              />
            </div>
            <div>
              <h1 class="field-label">{{ messages.breed }}</h1>
              <v-text-field
                v-model="form.breed"
                dense
                outlined
                color="var(--mosque)"
              />
            </div>
            <div>
              <h1 class="field-label">{{ messages.birthdate }}</h1>
              <date-picker
                v-model="form.birthdate"
                outlined
                dense
                color="var(--mosque)"
                :is-birthday="true"
                @input="updateBirthdate"
              />
            </div>
          </div>
        </div>

        <div>
          <h1 class="field-label">{{ messages.chipNumber }}</h1>
          <chip-number-input v-model="form.chipNumber" color="var(--mosque)" />
        </div>
        <div>
          <h1 class="field-label">{{ messages.comments }}</h1>
          <v-textarea
            v-model="form.comment"
            rows="5"
            outlined
            auto-grow
            color="var(--mosque)"
          />
        </div>
        <div class="button-section">
          <pop-up-action-button
            :text="messages.cancel"
            textColor="var(--camelot)"
            buttonType="outlined"
            @click="$emit('close')"
          />
          <pop-up-action-button
            :text="messages.add"
            backgroundColor="var(--camelot)"
            textColor="white"
            type="submit"
          />
        </div>
      </form>
  </modal-template>
</template>

<script>
import PopUpActionButton from '/src/vue/components/PopUpActionButton.vue'
import TextField from '/src/vue/components/TextField.vue'
import TextArea from '/src/vue/components/TextArea.vue'
import SmallerHeadline from '/src/vue/components/SmallerHeadline.vue'
import { database } from '/src/vue/ipc'
import DatePicker from '/src/vue/components/DatePicker.vue'
import ChipNumberInput from '/src/vue/components/ChipNumberInput.vue'
import SearchDropdown from '@/vue/components/SearchDropdown.vue'
import { messages } from '../../../../locale'
import ModalTemplate from '@/vue/components/ModalTemplate.vue'

export default {
  name: 'AddPetPopUp',
  computed: {
    messages() {
      return messages
    },
  },
  components: {
    ModalTemplate,
    ChipNumberInput,
    TextArea,
    TextField,
    PopUpActionButton,
    SmallerHeadline,
    SearchDropdown,
    DatePicker,
  },
  props: {
    clientId: {
      required: true,
    },
  },
  data() {
    return {
      form: {
        name: '',
        sex: '',
        chipNumber: '',
        type: '',
        breed: '',
        birthdate: '',
        weight: '',
        comment: '',
      },
      animalTypeOptions: [],
    }
  },
  created() {
    this.animalTypeOptions = Object.entries(messages.animalSpecies).map(([value, text]) => ({
      text,
      value
    }));
  },
  methods: {
    updateBirthdate(date) {
      this.form.birthdate = date
    },
    async handleSubmit() {
      const newPet = {
        name: this.form.name,
        sex: Object.keys(messages.sexes).find(key => messages.sexes[key] === this.form.sex),
        chipNumber: this.form.chipNumber,
        type: this.form.type, // This is now the English value
        breed: this.form.breed,
        birthdate: this.form.birthdate,
        weight: parseFloat(this.form.weight),
        comment: this.form.comment,
      }

      const newPetId = await database.addAnimal(this.clientId, newPet)

      if (newPetId) {
        this.$emit('pet-added', newPetId)
        this.$emit('close')
      } else {
        console.error('Failed to add new pet')
      }
    },
  },
}
</script>

<style scoped>
.header {
  padding-bottom: 7px;
  border-bottom: 1px solid #ddd;
  text-align: center;
}

.add-pet-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.6);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.add-pet-popup {
  background-color: white;
  border-radius: 8px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  width: 30%;
  max-height: 90vh;
  overflow-y: auto;
}

.Form {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 20px;
  width: 400px;
}

form {
  padding: 20px;
  display: flex;
  flex-direction: column;
  /* gap: 20px; */
}

.button-section {
  display: flex;
  justify-content: flex-end;
  gap: 20px;
}

::v-deep .v-text-field.v-text-field--outlined fieldset {
  opacity: 0.5;
}
</style>