<template>
  <modal-template @close="$emit('close')">
      <smaller-headline v-if="editMode" :text="messages.editExistingVaccine" />
      <smaller-headline v-else :text="messages.addNewVaccine" />
      <div v-if="!editMode" class="toggle-container">
        <div class="toggle-container-buttons">
          <button
            @click="activeComponent = 'AddNewVaccine'; localVaccineData = {}"
            :class="{ active: activeComponent === 'AddNewVaccine' }"
          >
            {{ messages.addNewVaccine }}
          </button>
          <button
            @click="activeComponent = 'SelectExisting'; localVaccineData = {}"
            :class="{ active: activeComponent === 'SelectExisting' }"
          >
            {{ messages.selectFromExisting }}
          </button>
        </div>
      </div>

      <add-edit-vaccine
        v-if="activeComponent === 'AddNewVaccine'"
        :isEditing="editMode"
        :value="localVaccineData"
        @input="updateLocalVaccineData"
      />

      <existing-vaccine-selector
        v-if="activeComponent === 'SelectExisting'"
        :value="localVaccineData"
        :clientId="clientId"
        :petId="petId"
        @update:selectedVaccine="selectedVaccine = $event"
        @input="updateLocalVaccineData"
      />
      <div class="button-section">
        <pop-up-action-button
          :text="messages.cancel"
          textColor="var(--camelot)"
          buttonType="outlined"
          @click="closePopup"
        />
        <pop-up-action-button
          :text="editMode ? messages.save : messages.add"
          backgroundColor="var(--camelot)"
          textColor="white"
          @click="addOrUpdateVaccine"
        />
      </div>
  </modal-template>
</template>

<script>
import Vue from 'vue'
import SmallerHeadline from '/src/vue/components/SmallerHeadline.vue'
import AddEditVaccine from '/src/vue/pages/register/sections/visit/AddEditVaccine.vue'
import ExistingVaccineSelector from '/src/vue/pages/register/sections/visit/ExistingVaccineSelector.vue'
import PopUpActionButton from '/src/vue/components/PopUpActionButton.vue'
import { database } from '/src/vue/ipc.js'
import { messages } from '../../../../locale'
import ModalTemplate from '@/vue/components/ModalTemplate.vue'

export default {
  name: 'AddEditVaccinePopup',
  computed: {
    messages() {
      return messages
    },
  },
  components: {
    ModalTemplate,
    SmallerHeadline,
    AddEditVaccine,
    ExistingVaccineSelector,
    PopUpActionButton,
  },
  props: {
    editMode: {
      type: Boolean,
      default: false,
    },
    vaccine: {
      type: Object,
      default: () => ({
        id: null,
        name: '',
        necessaryDosesAmount: 0,
        currentDosesAmount: 0,
        necessaryDosesCount: 0,
        currentDosesCount: 0,
      }),
    },
    clientId: {
      type: String,
      required: true,
    },
    petId: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      activeComponent: 'AddNewVaccine',
      localVaccineData: {},
      selectedVaccine: null,
    }
  },
  methods: {
    closePopup() {
      this.$emit('close')
    },
    async addOrUpdateVaccine() {
      console.log('Starting addOrUpdateVaccine method')
      console.log(
        'Initial localVaccineData:',
        JSON.stringify(this.localVaccineData),
      )

      let vaccineToSave = { ...this.localVaccineData }
      console.log('Initial vaccineToSave:', JSON.stringify(vaccineToSave))

      // Ensure all values are numbers
      vaccineToSave.necessaryDosesAmount =
        parseFloat(vaccineToSave.necessaryDosesAmount) || 0
      vaccineToSave.currentDosesAmount =
        parseFloat(vaccineToSave.currentDosesAmount) || 0
      vaccineToSave.necessaryDosesCount =
        parseInt(vaccineToSave.necessaryDosesCount) || 0
      vaccineToSave.currentDosesCount =
        parseInt(vaccineToSave.currentDosesCount) || 0

      console.log('vaccineToSave after parsing:', JSON.stringify(vaccineToSave))

      console.log('activeComponent:', this.activeComponent)
      if (this.activeComponent === 'SelectExisting') {
        console.log('Processing existing vaccine')
        vaccineToSave.id = this.selectedVaccine
        console.log('Selected vaccine ID:', vaccineToSave.id)

        // Fetch the existing vaccine data
        const existingVaccines = await database.getAnimalVaccines(
          this.clientId,
          this.petId,
        )
        console.log('All existing vaccines:', JSON.stringify(existingVaccines))

        const existingVaccine = existingVaccines.find(
          (v) => v.id === vaccineToSave.id,
        )
        console.log('Found existing vaccine:', JSON.stringify(existingVaccine))

        if (existingVaccine) {
          console.log('Updating existing vaccine')
          // Calculate the dose amount for this administration
          const doseAmount = vaccineToSave.doseAmount
          console.log('New dose amount:', doseAmount)

          // Update currentDosesAmount by adding the new dose
          vaccineToSave.currentDosesAmount =
            existingVaccine.currentDosesAmount + doseAmount
          console.log(
            'Updated currentDosesAmount:',
            vaccineToSave.currentDosesAmount,
          )

          // Increment currentDosesCount
          vaccineToSave.currentDosesCount =
            existingVaccine.currentDosesCount + 1
          console.log(
            'Updated currentDosesCount:',
            vaccineToSave.currentDosesCount,
          )

          // Ensure we keep the necessary doses information
          vaccineToSave.necessaryDosesAmount =
            existingVaccine.necessaryDosesAmount
          vaccineToSave.necessaryDosesCount =
            existingVaccine.necessaryDosesCount

          console.log(
            'Final vaccineToSave for existing vaccine:',
            JSON.stringify(vaccineToSave),
          )
        } else {
          console.log('Existing vaccine not found')
        }
      } else if (!this.editMode) {
        console.log('Processing new vaccine')
        // For new vaccines, ensure currentDosesCount is at least 1
        vaccineToSave.currentDosesCount = Math.max(
          1,
          vaccineToSave.currentDosesCount,
        )
        console.log(
          'Set currentDosesCount for new vaccine:',
          vaccineToSave.currentDosesCount,
        )
      }

      // Remove any performedVaccines field if it exists
      delete vaccineToSave.performedVaccines

      console.log('Final vaccineToSave:', JSON.stringify(vaccineToSave))

      this.$emit('add-update-vaccine', vaccineToSave)
      
      this.closePopup()
    },
    updateLocalVaccineData(newData) {
      console.log(
        'updateLocalVaccineData called with:',
        JSON.stringify(newData),
      )
      Object.keys(newData).forEach((key) => {
        Vue.set(this.localVaccineData, key, newData[key])
      })
      console.log(
        'Updated localVaccineData:',
        JSON.stringify(this.localVaccineData),
      )
    },
  },
  watch: {
    vaccine: {
      handler(newValue) {
        console.log('vaccine prop changed:', JSON.stringify(newValue))
        this.updateLocalVaccineData(newValue)
      },
      deep: true,
      immediate: true,
    },
  },
}
</script>

<style scoped>
.popup-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.popup-content {
  background-color: white;
  border-radius: 8px;
  width: 90%;
  max-width: 500px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.toggle-container {
  margin-top: 15px;
  margin-bottom: 15px;
  display: flex;
  justify-content: center;
}

.toggle-container-buttons {
  padding: 5px;
  background-color: #f0f0f0;
  display: flex;
  gap: 10px;
  border-radius: 6px;
}

.toggle-container button {
  padding: 8px 16px;
  background-color: #f0f0f0;
  border: none;
  cursor: pointer;
  font-size: 14px;
  transition: background-color 0.3s;
  border-radius: 4px;
}

.toggle-container button.active {
  background-color: #8b1f41;
  color: white;
}

.button-section {
  padding: 0 20px 20px 20px;
  display: flex;
  justify-content: flex-end;
  gap: 10px;
  margin-top: 20px;
}

@media (max-width: 600px) {
  .popup-content {
    width: 95%;
    padding: 15px;
  }

  .toggle-container button {
    padding: 6px 12px;
    font-size: 12px;
  }
}
</style>