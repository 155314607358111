<template>
  <div class="toggle-switch" @click="toggle" :class="{ active: localIsActive }">
    <div class="rectangle"></div>
  </div>
</template>

<script>
export default {
  name: 'ToggleSwitch',
  props: {
    isActive: {
      type: Boolean,
      required: true
    }
  },
  data() {
    return {
      localIsActive: this.isActive
    }
  },
  methods: {
    toggle() {
      this.localIsActive = !this.localIsActive
      this.$emit('toggle', this.localIsActive)
    },
  },
  watch: {
    isActive(newValue) {
      this.localIsActive = newValue
    }
  }
}
</script>

<style scoped>
.toggle-switch {
  background-color: #ccc; /* Inactive color */
  border-radius: 10px;
  height: 17px;
  overflow: hidden;
  position: relative;
  width: 32px;
  cursor: pointer;
  transition: background-color 0.3s;
}

.toggle-switch.active {
  background-color: var(--mosque); /* Active color */
}

.rectangle {
  background-color: var(--white);
  border-radius: 9px;
  height: 13px;
  width: 13px;
  position: absolute;
  top: 2px;
  left: 2px;
  transition: left 0.3s;
}

.toggle-switch.active .rectangle {
  left: 17px;
}
</style>