<template>
  <modal-template @close="$emit('close')">
      <smaller-headline :text="messages.informationAboutVisit" />
      <div class="Form">
        <div class="info-row">
          <div style="width: 100%">
            <h1 class="field-label">{{ messages.topic }}</h1>
            <v-text-field
              class="topic-field"
              v-model="localVisit.topic"
              dense
              outlined
              color="var(--mosque)"
            />
          </div>
          <div>
            <h1 class="field-label">{{ messages.date }}</h1>
            <date-picker
              class="date-field"
              v-model="localVisit.date"
              @input="checkForChanges"
              :is-birthday="true"
              outlined
            />
          </div>
        </div>
      </div>
      <div class="Form-full">
        <div>
          <h1 class="field-label">{{ messages.petName }}</h1>
          <pet-search-dropdown
            class="pet-name-field"
            v-model="localVisit.petId"
            :client-id="localVisit.clientId"
            @pet-selected="checkForChanges"
            outlined
            prepend-inner-icon="mdi-paw"
          />
        </div>
        <div>
          <h1 class="field-label">{{ messages.description }}</h1>
          <v-textarea
            v-model="localVisit.description"
            auto-grow
            outlined
            rows="5"
            color="var(--mosque)"
          />
        </div>
      </div>

      <!-- Medicines Table -->
      <div class="medicine-info-display">
        <h4 v-if="localVisit.medicines.length > 0">{{ messages.medicines }}</h4>
        <div v-if="localVisit.medicines.length > 0" class="medicine-table-wrapper">
          <table class="medicine-table">
            <thead>
              <tr>
                <th>{{ messages.name }}</th>
                <th>{{ messages.count }}</th>
                <th>
                  <v-btn color="#08616D" @click="toggleEditMode" text>
                    <v-icon :color="isEditing ? 'green' : ''">{{
                      isEditing ? 'mdi-check' : 'mdi-pencil'
                    }}</v-icon>
                  </v-btn>
                </th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="medicine in localVisit.medicines" :key="medicine.id">
                <td>
                  <i class="mdi mdi-pill"></i>
                  <span v-if="!isEditing">{{ medicine.name }}</span>
                  <input v-else v-model="medicine.name" style="outline: auto" />
                </td>
                <td>
                  <span v-if="!isEditing"
                    >{{ medicine.amount }} {{ medicine.unit }}</span
                  >
                  <div v-else class="amount-wrapper">
                    <input
                      v-model.number="medicine.amount"
                      type="number"
                      class="amount-input"
                      min="0"
                    />
                    <span class="unit">{{ medicine.unit }}</span>
                  </div>
                </td>
                <td v-if="isEditing" class="remove-cell">
                  <v-btn
                    small
                    text
                    color="#963156"
                    @click="deleteMedicine(medicine.id)"
                    class="remove-button"
                    >{{ messages.delete }}</v-btn
                  >
                </td>
                <td v-else></td>
              </tr>
              <tr
                v-if="isEditing"
                class="add-medicine-row"
                @click="addNewMedicine"
              >
                <td colspan="3">
                  <span class="add-medicine-content">
                    <i class="mdi mdi-plus"></i>
                    {{ messages.addNewMedicine }}
                  </span>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <div v-else class="no-medicine">
          <h1>{{ messages.noMedicineDuringAppointment }}</h1>
          <secondary-button
            text="Pievienot"
            color="#963156"
            @click="addNewMedicine"
          />
        </div>
      </div>

      <div class="button-section">
        <div class="left-buttons">
          <pop-up-action-button
            text=""
            icon="mdi-delete"
            color="var(--camelot)"
            buttonType="outlined"
            @click="deleteVisit"
          />
        </div>
        <div class="right-buttons">
          <pop-up-action-button
            v-if="edited"
            :text="messages.cancel"
            color="var(--camelot)"
            buttonType="outlined"
            @click="$emit('close')"
          />
          <pop-up-action-button
            v-if="edited"
            :text="messages.save"
            backgroundColor="var(--camelot)"
            textColor="white"
            @click="saveChanges"
          />
          <pop-up-action-button
            v-if="!edited"
            :text="messages.close"
            backgroundColor="var(--camelot)"
            textColor="white"
            @click="$emit('close')"
          />
        </div>
      </div>
  </modal-template>
</template>

<script>
import PopUpActionButton from '/src/vue/components/PopUpActionButton.vue'
import SmallerHeadline from '/src/vue/components/SmallerHeadline.vue'
import SecondaryButton from '/src/vue/components/SecondaryButton.vue'
import DatePicker from '/src/vue/components/DatePicker.vue'
import { database, ipc } from '/src/vue/ipc.js'
import PetSearchDropdown from '@/vue/components/PetSearchDropdown.vue'
import { messages } from '@/vue/locale'
import ModalTemplate from '@/vue/components/ModalTemplate.vue'

export default {
  name: 'MoreVisit',
  computed: {
    messages() {
      return messages
    },
  },
  components: {
    ModalTemplate,
    PopUpActionButton,
    SmallerHeadline,
    SecondaryButton,
    PetSearchDropdown,
    DatePicker,
  },
  props: {
    visit: {
      type: Object,
      required: true,
    },
    // pet: {
    //   type: Object,
    //   required: true
    // }
  },
  data() {
    return {
      localVisit: JSON.parse(JSON.stringify(this.visit)), // Deep clone
      isEditing: false,
      nextId: this.visit.medicines
        ? Math.max(...this.visit.medicines.map((m) => m.id), 0) + 1
        : 1,
      edited: false,
    }
  },
  watch: {
    localVisit: {
      handler() {
        this.checkForChanges()
      },
      deep: true,
    },
  },
  methods: {
    checkForChanges() {
      this.edited =
        JSON.stringify(this.localVisit) !== JSON.stringify(this.visit)
    },
    addNewMedicine() {
      this.isEditing = true,
      this.localVisit.medicines.push({
        id: ipc.generateUUID(),
        name: '',
        amount: 0,
        visitId: this.visit.id,
      })
      this.checkForChanges()
    },
    deleteMedicine(id) {
      this.localVisit.medicines = this.localVisit.medicines.filter(
        (medicine) => medicine.id !== id,
      )
      this.checkForChanges()
    },
    async saveChanges() {
      console.log('UPDATING:', this.localVisit)
      await database.updateVisit(this.localVisit.id, this.localVisit)
      this.$emit('update-visit')
      this.$emit('close')
      this.edited = false
    },
    cancelChanges() {
      this.localVisit = JSON.parse(JSON.stringify(this.visit))
      this.edited = false
    },
    toggleEditMode() {
      this.isEditing = !this.isEditing
      if (!this.isEditing) {
        this.checkForChanges()
      }
    },
    async deleteVisit() {
      if (confirm('Vai tiešām vēlaties dzēst šo apmeklējumu?')) {
        await database.removeVisit(this.visit.id)
        this.$emit('visit-deleted')
        this.$emit('close')
      }
    },
  },
}
</script>

<style scoped>
.add-pet-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.6);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 10;
}

.add-pet-popup {
  background-color: white;
  border-radius: 8px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  width: 30%;
  min-width: 380px;
  max-width: 500px;
}

.Form {
  display: flex;
  flex-direction: column;
  padding: 20px 20px 0 20px;
}

.mdi-plus {
  font-size: 24px;
  margin-right: 10px;
  color: #08616d;
}

.info-row {
  gap: 20px;
  display: flex;
  width: 100%;
}

.Form-full {
  padding: 0 20px 0 20px;
}

.button-section {
  padding: 0 20px 15px 20px;
  display: flex;
  justify-content: space-between;
}

.right-buttons {
  display: flex;
  gap: 10px;
}

.date-field {
  width: 100%;
}
.topic-field {
  width: 100%;
}
.pet-name-field {
  width: 100%;
}

.no-medicine {
  display: flex;
  gap: 10px;
  align-items: center;
}

.medicine-info-display {
  padding: 0 20px 30px 20px;
}

.medicine-table {
  width: 100%;
  border-collapse: collapse;
}

.medicine-table-wrapper {
  border: 1px solid var(--bon-jour);
  border-radius: 5px;
  overflow: hidden;
}

.medicine-table th,
.medicine-table td {
  padding: 10px;
  text-align: left;
  border-bottom: 1px solid #ddd;
}

.medicine-table th:last-child,
.medicine-table td:last-child {
  width: 100px;
  text-align: center;
}

.mdi-pill {
  font-size: 24px;
  margin-right: 10px;
  color: #7f5c91;
  cursor: pointer;
}

.mdi-pill.favorite {
  color: #e9676e;
}

.amount-wrapper {
  display: flex;
  align-items: center;
}

.amount-input {
  outline: auto;
  width: 60px;
  margin-right: 5px;
}

.add-medicine-row {
  cursor: pointer;
}

.add-medicine-content {
  display: flex;
  align-items: center;
  color: #08616d;
}

.remove-button {
  color: #08616d;
  background: none;
  border: none;
  cursor: pointer;
}

::v-deep .v-text-field.v-text-field--outlined fieldset {
  opacity: 0.5;
}
</style>
