<template>
  <modal-template @close="$emit('close')">
      <smaller-headline :text="messages.informationAboutPet" />
      <form @submit.prevent="handleSubmit">
        <div class="Form">
          <div>
            <div>
              <h1 class="field-label">{{ messages.firstName }}</h1>
              <v-text-field
                v-model="form.name"
                dense
                outlined
                color="var(--mosque)"
              />
            </div>
            <div>
              <h1 class="field-label">{{ messages.species }}</h1>
              <v-autocomplete
                v-model="form.type"
                :items="this.convertToOptions(messages.animalSpecies)"
                dense
                outlined
                color="var(--mosque)"
                auto-select-first
                clearable
              />
            </div>
            <div>
              <h1 class="field-label">{{ messages.weight }}</h1>
              <v-text-field
                v-model="form.weight"
                dense
                outlined
                type="number"
                step="0.1"
                min="0"
                color="var(--mosque)"
              />
            </div>
          </div>
          <div>
            <div>
              <h1 class="field-label">{{ messages.sex }}</h1>
              <v-autocomplete
                v-model="form.sex"
                :items="this.convertToOptions(messages.sexes)"
                dense
                outlined
                color="var(--mosque)"
                auto-select-first
                clearable
              />
            </div>
            <div>
              <h1 class="field-label">{{ messages.breed }}</h1>
              <v-text-field
                v-model="form.breed"
                dense
                outlined
                color="var(--mosque)"
              />
            </div>
            <div>
              <h1 class="field-label">{{ messages.birthdate }}</h1>
              <date-picker
                v-model="form.birthdate"
                outlined
                dense
                :is-birthday="true"
                color="var(--mosque)"
              />
            </div>
          </div>
        </div>

        <div>
          <h1 class="field-label">{{ messages.chipNumber }}</h1>
          <chip-number-input v-model="form.chipNumber" color="var(--mosque)" />
        </div>
        <div>
          <h1 class="field-label">{{ messages.comments }}</h1>
          <v-textarea
            v-model="form.comment"
            rows="5"
            outlined
            auto-grow
            color="var(--mosque)"
          />
        </div>

        <div class="vaccines-section">
          <h2 v-if="form.vaccines.length > 0">{{ messages.vaccines }}</h2>
        
          <div v-if="form.vaccines.length > 0" class="vaccine-table-wrapper">
            <table class="vaccine-table">
              <thead>
                <tr>
                  <th>{{ messages.name }}</th>
                  <th>{{ messages.doses }}</th>
                  <th>{{ messages.amount }}</th>
                  <th>
                    <v-btn color="#08616D" @click="toggleVaccineEditMode" text>
                      <v-icon :color="isVaccineEditing ? 'green' : ''">
                        {{ isVaccineEditing ? 'mdi-check' : 'mdi-pencil' }}
                      </v-icon>
                    </v-btn>
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="vaccine in form.vaccines" :key="vaccine.id">
                  <td>
                    <i class="mdi mdi-needle"></i>
                    <span v-if="!isVaccineEditing">{{ vaccine.name }}</span>
                    <input v-else v-model="vaccine.name" style="outline: auto" />
                  </td>
                  <td>
                    <div class="progress-bar">
                      <div
                        class="progress"
                        :style="{ width: `${getDoseProgressPercentage(vaccine)}%` }"
                      ></div>
                    </div>
                    <span class="progress-text" v-if="!isVaccineEditing">
                      {{ vaccine.currentDosesAmount }} / {{ vaccine.necessaryDosesAmount }} mL
                    </span>
                    <div v-else class="amount-wrapper">
                      <input
                        v-model.number="vaccine.currentDosesAmount"
                        type="number"
                        class="amount-input"
                        step="0.1"
                        :max="vaccine.necessaryDosesAmount"
                        min="0"
                      />
                      /
                      <input
                        v-model.number="vaccine.necessaryDosesAmount"
                        type="number"
                        class="amount-input"
                        step="0.1"
                        :min="vaccine.currentDosesAmount"
                      />
                      mL
                    </div>
                  </td>
                  <td>
                    <div class="progress-bar">
                      <div
                        class="progress"
                        :style="{ width: `${getCountProgressPercentage(vaccine)}%` }"
                      ></div>
                    </div>
                    <span class="progress-text" v-if="!isVaccineEditing">
                      {{ vaccine.currentDosesCount }} / {{ vaccine.necessaryDosesCount }}
                    </span>
                    <div v-else class="amount-wrapper">
                      <input
                        v-model.number="vaccine.currentDosesCount"
                        type="number"
                        class="amount-input"
                        :max="vaccine.necessaryDosesCount"
                        min="0"
                      />
                      /
                      <input
                        v-model.number="vaccine.necessaryDosesCount"
                        type="number"
                        class="amount-input"
                        :min="vaccine.currentDosesCount"
                      />
                    </div>
                  </td>
                  <td v-if="isVaccineEditing" class="remove-cell">
                    <v-btn
                      small
                      text
                      color="#963156"
                      @click="deleteVaccine(vaccine.id)"
                      class="remove-button"
                    >{{ messages.delete }}</v-btn>
                  </td>
                  <td v-else>
                    <span :class="['status-badge', getStatusClass(vaccine)]">
                      {{ getStatusText(vaccine) }}
                    </span>
                  </td>
                </tr>
                <tr
                  v-if="isVaccineEditing"
                  class="add-vaccine-row"
                  @click="addNewVaccine"
                >
                  <td colspan="4">
                    <span class="add-vaccine-content">
                      <i class="mdi mdi-plus"></i>
                      {{ messages.addNewVaccine }}
                    </span>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        <div v-else class="no-medicine">
          <h1>{{ messages.noVaccine }}</h1>
          <secondary-button
            text="Pievienot"
            color="#963156"
            @click="addNewVaccine"
          />
        </div>
      </div>
      <div class="button-section">
        <pop-up-action-button
          :text="messages.cancel"
          textColor="var(--camelot)"
          buttonType="outlined"
          @click.prevent="handleClose"
        />
        <pop-up-action-button
          :text="messages.save"
          backgroundColor="var(--camelot)"
          textColor="white"
          type="submit"
        />
      </div>
    </form>
  </modal-template>
</template>

<script>
import PopUpActionButton from '/src/vue/components/PopUpActionButton.vue'
import SmallerHeadline from '/src/vue/components/SmallerHeadline.vue'
import { database } from '/src/vue/ipc'
import DatePicker from '/src/vue/components/DatePicker.vue'
import ChipNumberInput from '/src/vue/components/ChipNumberInput.vue'
import { messages } from '@/vue/locale'
import ModalTemplate from '@/vue/components/ModalTemplate.vue'
import SecondaryButton from '/src/vue/components/SecondaryButton.vue'

export default {
  name: 'AddPetPopUp',
  computed: {
    messages() {
      return messages
    },
  },
  components: {
    ModalTemplate,
    ChipNumberInput,
    PopUpActionButton,
    SmallerHeadline,
    DatePicker,
    SecondaryButton,
  },
  props: {
    pet: {
      type: Object,
      required: true,
    },
    clientId: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      form: this.initializeForm(),
      isVaccineEditing: false,
      originalForm: JSON.parse(JSON.stringify(this.pet)),
    }
  },
  methods: {
    initializeForm() {
      console.log('this.pet',this.pet)
      console.log('messages.animalSpecies', messages.animalSpecies)
      return {
        ...JSON.parse(JSON.stringify(this.pet)),
        vaccines: JSON.parse(JSON.stringify(this.pet.vaccines || [])),
      }
    },
    async handleSubmit() {
      try {
        // First, update the animal details
        const updatedPet = {
          ...this.form,
          weight: parseFloat(this.form.weight),
        };

        // Update the animal data
        await database.updateAnimal(this.clientId, this.pet.id, updatedPet);

        // Reset and save all vaccines
        await this.saveVaccines();

        this.$emit('pet-updated', updatedPet);
        this.$emit('close');
      } catch (error) {
        console.error('Failed to save pet:', error);
      }
    },

    async saveVaccines() {
      try {
        const { clientId, id: animalId } = this.pet;

        // Reset vaccines: remove all current vaccines
        await database.deleteVaccinesForAnimal(animalId)
        // await database.dbRun('DELETE FROM vaccines WHERE animalId = ?', [animalId]);

        // Add all vaccines from the form
        for (const vaccine of this.form.vaccines) {
          await database.addVaccineToAnimal(clientId, animalId, vaccine);
        }

        this.$emit('update-vaccine');
      } catch (error) {
        console.error('Failed to save vaccines:', error);
      }
    },
    handleClose() {
      // Restore the original state
      this.form = JSON.parse(JSON.stringify(this.originalForm));
      this.isVaccineEditing = false;
      this.$emit('close');
    },
    convertToOptions(messages) {
      let result = [];
      for (const [key, value] of Object.entries(messages)) {
        result.push({ text: value, value: key });
      }
      return result;
    },
    getDoseProgressPercentage(vaccine) {
      return (vaccine.currentDosesAmount / vaccine.necessaryDosesAmount) * 100;
    },
    getCountProgressPercentage(vaccine) {
      return (vaccine.currentDosesCount / vaccine.necessaryDosesCount) * 100;
    },
    getStatusClass(vaccine) {
      const doseProgress = this.getDoseProgressPercentage(vaccine);
      const countProgress = this.getCountProgressPercentage(vaccine);
      if (doseProgress >= 100 && countProgress >= 100) return 'status-complete';
      if (doseProgress > 0 || countProgress > 0) return 'status-in-progress';
      return 'status-pending';
    },
    getStatusText(vaccine) {
      const doseProgress = this.getDoseProgressPercentage(vaccine);
      const countProgress = this.getCountProgressPercentage(vaccine);
      if (doseProgress >= 100 && countProgress >= 100) return messages.vaccineFinished;
      if (doseProgress > 0 || countProgress > 0) return messages.vaccineInProcess;
      return messages.vaccinePending;
    },
    addNewVaccine() {
      this.isVaccineEditing = true
      this.form.vaccines.push({
        id: Date.now(),
        name: '',
        currentDosesAmount: 0,
        necessaryDosesAmount: 0,
        currentDosesCount: 0,
        necessaryDosesCount: 0,
      });
    },
    deleteVaccine(id) {
      this.form.vaccines = this.form.vaccines.filter(vaccine => vaccine.id !== id);
    },
    toggleVaccineEditMode() {
      this.isVaccineEditing = !this.isVaccineEditing;
    },
  },
}
</script>
<style scoped>
.Form {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 20px;
  /* max-width: 400px; */
}

form {
  padding: 20px;
  display: flex;
  flex-direction: column;
}

.field {
  width: 100%;
}

.button-section {
  display: flex;
  justify-content: flex-end;
  gap: 20px;
}

::v-deep .v-text-field.v-text-field--outlined fieldset {
  opacity: 0.5;
}

.vaccine-table-wrapper {
  border: 1px solid var(--bon-jour);
  border-radius: 5px;
  overflow: hidden;
  margin-bottom: 20px;
}

.vaccine-table {
  width: 100%;
  border-collapse: collapse;
}

.vaccine-table th,
.vaccine-table td {
  padding: 10px;
  text-align: left;
  border-bottom: 1px solid #ddd;
}

.vaccine-table th:last-child,
.vaccine-table td:last-child {
  width: 100px;
  text-align: center;
}

.mdi-needle {
  font-size: 24px;
  margin-right: 10px;
  color: #7f5c91;
}

.amount-wrapper {
  display: flex;
  align-items: center;
}

.amount-input {
  outline: auto;
  width: 30px;
  margin-right: 5px;
}

.no-medicine {
  display: flex;
  gap: 10px;
  align-items: center;
  margin-bottom: 15px;
}

.add-vaccine-row {
  cursor: pointer;
}

.add-vaccine-content {
  display: flex;
  align-items: center;
  color: #08616d;
}

.remove-button {
  color: #08616d;
  background: none;
  border: none;
  cursor: pointer;
}

.progress-bar {
  width: 100px;
  height: 6px;
  background-color: #e0e0e0;
  border-radius: 3px;
  overflow: hidden;
  margin-bottom: 5px;
}

.progress {
  height: 100%;
  background-color: #4caf50;
}

.progress-text {
  font-size: 12px;
  color: #666;
}

.status-badge {
  padding: 4px 8px;
  border-radius: 12px;
  font-size: 12px;
  font-weight: 500;
}

.status-complete {
  background-color: #e8f5e9;
  color: #4caf50;
}

.status-in-progress {
  background-color: #fff8e1;
  color: #ffa000;
}

.status-pending {
  background-color: #f5f5f5;
  color: #9e9e9e;
}
</style>