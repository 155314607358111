export const messages = {
  dropdownEmptyClientList: "Nav atrasts neviens klients!",
  dropdownEmptyPetList: "Nav atrasts neviens dzīvnieks!",
  dropdownClientSearch: "Meklēt klientu",
  dropdownPetSearch: "Meklēt dzivnieku",

  licenseCheck: "Licences pārbaude",
  licenseEnterCode: "Ievadiet savu 16 zīmju kodu, lai atbloķētu programmu.",
  licenseCheckInProgress: "Tiek pārbaudīta esošā licence, lūdzu, uzgaidiet.",
  licenseUnlockButton: "Atbloķēt",
  licenseBuyButton: "Pirkt tagad",
  licenseKeyInvalid: "Nederīga licences atslēga. Lūdzu, mēģiniet vēlreiz.",
  licensePleaseEnterCode: "Lūdzu, ievadiet derīgu 16 rakstzīmju licences atslēgu.",
  licenseUnableToCheck: "Radās kļūda. Lūdzu, mēģiniet vēlreiz vēlāk.",

  sideBarItemStatistics: "Statistika",
  sideBarItemCalendar: "Kalendārs",
  sideBarItemNewClient: "Apmeklējums",
  sideBarItemList: "Saraksts",

  noteStatusCanceled: "Atcelts",
  noteStatusCompleted: "Pabeigts",
  noteStatusInProcess: "Procesa",
  noteStatusNew: "Nav Sakts",

  calendarAddVisit: "Pievienot apmeklējumu",
  calendarManageWorkingTime: "Mainīt darba laikus",

  pageCalendarTitle: "Esošo ierakstu saraksts",
  pageRegisterTitle: "Jauna apmeklējuma reģistrēšana",
  pageFullListTitle: "Detalizētas informācijas saraksts",
  pageStatisticsTitle: "Statistikas datu tendences",

  titleChangeWorkingTime: "Mainīt darba laikus",

  sortAlphabeticalAscending: "Alfabētiska secība (aug.)",
  sortAlphabeticalDescending: "Alfabētiska secība (dil.)",
  sortTimedAscending: "Pēc pievienošanas datuma (aug.)",
  sortTimedDescending: "Pēc pievienošanas datuma (dil.)",

  clinicIsClosed: "Klīnika šodien ir slēgta!",
  addNewNote: "Pievienot pierakstu šeit",
  clientList: "Klientu saraksts",
  aboutClient: "Par klientu",
  confirmDelete: "Vai esat pārliecināts, ka vēlaties dzēst šo klientu? Šo darbību nevar atcelt.",

  informationAboutPet: "Informācija par dzīvnieku",
  informationAboutVisit: "Informācija par apmeklējumu",
  informationAboutAppointment: "Informācija par pierakstu",
  phoneNumber: "Tālruņa numurs",
  birthdate: "Dzimšanas datums",
  chipNumber: "Čipa numurs",

  addNewMedicine: "Pievienot jaunu medikamentu",
  noMedicineDuringAppointment: "Šīs vizītes laikā netika lietoti medikamenti",

  addNewClient: "Pievienot jaunu klientu",
  addNewPet: "Pievienot jaunu dzīvnieku",
  addNewVaccine: "Pievienot jaunu vakcināciju",

  editExistingVaccine: "Rediģēt vakcīnu",
  selectFromExisting: "Izvēlēties no esošām",


  addVaccine: "Pievienot vakcināciju",
  noVaccine: "Šim dzīvniekam nav vakcīnu",

  registrationNavBarSections: ['Īpašnieks', 'Dzīvnieks', 'Apmeklējums'],
  informationAboutClientWillBeHere: "Informacija par klientu bus šeit.",
  informationAboutPetWillBeHere: "Informācija par dzīvnieku būs šeit.",

  sexes: {
    female: "Sieviete",
    male: "Vīrietis",
  },

  add: "Pievienot",
  count: "Skaits",
  vaccine: "Vakcinācija",
  vaccines: "Vakcinācijas",
  clientAddition: "Klienta pievienošana",
  petAddition: "Dzīvnieka pievienošana",
  fullName: "Pilns vārds",
  medicines: "Medikamenti",
  petName: "Dzīvnieka vārds",
  clientName: "Klienta vārds",
  date: "Datums",
  email: "E-pasts",
  address: "Adrese",
  comments: "Komentāri",
  title: "Virsraksts",
  description: "Apraksts",
  time: "Laiks",
  client: "Klients",
  pet: "Dzīvnieks",
  from: "No",
  till: "Līdz",
  topic: "Tēma",
  create: "Izveidot",
  cancel: "Atcelt",
  save: "Saglabat",
  next: "Tālāk",
  edit: "Rediget",
  delete: "Dzest",
  deleteClient: "Dzest klientu",
  empty: "Tukšs",
  loading: "Ielādēšana...",
  more: "Vairāk",
  search: "Meklēt",
  sort: "Kārtot",
  close: "Aizvērt",
  visits: "Apmeklējumi",
  visit: "Apmeklējums",
  notes: "Pieraksti",
  note: "Piezime",
  appointment: "Pieraksts",
  firstName: "Vārds",
  species: "Suga",
  breed: "Šķirne",
  weight: "Svars",
  sex: "Dzimums",
  name: "Nosaukums",
  amount: "Daudzums",
  state: "Status",
  doses: "Devas",
  vaccineFinished: "Pabeigts",
  vaccineInProcess: "Procesa",

  editVaccine: "Rediģēt vakcīnu",
  vaccineName: "Vakcīnas nosaukums",
  vaccineRequiredDose: "Nepieciešamā deva",
  vaccineCurrentDose: "Deva",
  vaccineAddedDose: "Pievienotā deva",
  vaccineTotalDose: "Kopēja deva",
  vaccineRequiredCount: "Nepieciešamais devu skaits",
  vaccineRequiredCountShort: "Nep. devu skaits",
  vaccineCurrentCount: "Devu skaits",

  statisticsCompletedWork: "Veiktais darbs",
  statisticsTotalClientCount: "Kopējais klientu skaits",
  statisticsPlannedVisitsCount: "Plānoto apmeklējumu skaits",
  statisticsClientsTimedCount: "Klientu skaits (pēdējās 7 dienās)",
  statisticsVaccinationsTimedCount: "Vakcināciju skaits (pēdējās 7 dienās)",
  statisticsTodayWorkload: "Šīsdienas darba slodze",
  statisticsNewClientsTimed: "Jaunu klientu skaits (nedēļas laikā)",

  weekDays: {
    full: {
      monday: 'Pirmdiena',
      tuesday: 'Otrdiena',
      wednesday: 'Tresdiena',
      thursday: 'Ceturdiena',
      friday: 'Piektiena',
      saturday: 'Sestdiena',
      sunday: 'Svetdiena',
    },
    trimmed: {
      monday: 'Pm',
      tuesday: 'Ot',
      wednesday: 'Tr',
      thursday: 'Ce',
      friday: 'Pi',
      saturday: 'Se',
      sunday: 'Sv',
    }
  },

  animalSpecies: {
    dog: 'Suns',
    cat: 'Kaķis',
    rabbit: 'Trusis',
    guineaPig: 'Jūrascūciņa',
    hamster: 'Kāmis',
    parrot: 'Papagailis',
    fish: 'Zivs',
    snake: 'Čūska',
    lizard: 'Ķirzaka',
    turtle: 'Bruņurupucis',
    horse: 'Zirgs',
    cow: 'Govs',
    sheep: 'Aita',
    goat: 'Kaza',
    pig: 'Cūka'
  }
}
