 <template>
  <v-app>
    <v-main>
      <div v-if="!isActivated">
        <LicenseKeyActivation @activated="onActivated" v-if="!isActivated"/>
      </div>
      <div class="main-layout" v-else>
        <SideBar :activeMenuItem="activeMenuItem" :titleText="titleText" />
        <div class="content">
          <transition name="fade" mode="out-in">
            <router-view></router-view>
          </transition>
        </div>
      </div>
    </v-main>
  </v-app>
</template>

<script>
import SideBar from './vue/components/SideBar.vue'
import LicenseKeyActivation from './vue/components/LicenseKeyActivation.vue'

export default {
  name: 'App',
  components: {
    SideBar,
    LicenseKeyActivation
  },
  data() {
    return {
      activeMenuItem: 1,
      titleText: 'PawKeeper',
      isActivated: false
    }
  },
  watch: {
    $route(to) {
      // Update activeMenuItem based on the current route
      switch (to.path) {
        case '/statistics':
          this.activeMenuItem = 1
          break
        case '/calendar':
          this.activeMenuItem = 2
          break
        case '/register':
          this.activeMenuItem = 3
          break
        case '/full-list':
          this.activeMenuItem = 4
          break
      }
      // Update titleText based on the current route (you can customize this)
      this.titleText = to.name || 'PawKeeper'
    }
  },
  methods: {
    onActivated(status) {
      console.log('License activated:', status);
      this.isActivated = true;
    }
  },
}
</script>

<style>
::-webkit-scrollbar {
  display: none;
}

.main-layout {
  display: flex;
  min-height: 100vh;
  background: linear-gradient(
    180deg,
    rgb(209.34, 226.32, 235.88) 0%,
    rgb(232.39, 232.8, 242.5) 86.88%
  );
}

.content {
  flex-grow: 1;
  padding-top: 21px;
  padding-left: 240px;
  padding-right: 20px;
  overflow-y: hidden; /* Prevent vertical scrolling */
}

/* Fade transition */
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.3s ease;
}

.fade-enter,
.fade-leave-to {
  opacity: 0;
}

/* Optional: Add a slide effect */
.fade-enter-active {
  transition: all 0.3s ease;
}

.fade-leave-active {
  transition: all 0.3s cubic-bezier(1, 0.5, 0.8, 1);
}

.fade-enter,
.fade-leave-to {
  transform: translateX(10px);
  opacity: 0;
}
</style>