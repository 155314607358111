<template>
  <v-container fluid class="pets-panel pa-2">
    <v-row dense>
      <v-col v-for="pet in pets" :key="pet.id" cols="12" sm="6" md="3" lg="3">
        <v-card outlined class="pet-card d-flex flex-column">
          <v-list-item three-line class="flex-grow-1">
            <v-list-item-avatar size="42" color="var(--mosque)" class="mr-4">
              <v-icon size="24" color="white">{{
                  getPetIcon(pet.type)
                }}</v-icon>
            </v-list-item-avatar>
            <v-list-item-content>
              <v-list-item-title class="font-weight-medium text-truncate">{{
                  pet.name
                }}</v-list-item-title>
              <v-list-item-subtitle class="text-truncate">{{ pet.breed }}</v-list-item-subtitle>
              <v-list-item-subtitle class="text-truncate">
                {{ formatDate(pet.birthdate) }} | {{ pet.weight }} kg
              </v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>
          <v-divider></v-divider>
          <v-card-actions class="pa-2">
            <v-btn
              text
              small
              color="var(--camelot)"
              @click="showPetDetails(pet)"
            >
              {{ messages.more }}
              <v-icon right small>mdi-chevron-right</v-icon>
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-col>
    </v-row>
    <more-pet
      v-if="selectedPet"
      :pet="selectedPet"
      :client-id="clientId"
      @close="closePetDetails"
      @pet-updated="updatePet"
    />
  </v-container>
</template>

<script>
import { messages } from '../../../../locale'
import MorePet from '@/vue/pages/fulllist/components/pop-ups/MorePet.vue'

export default {
  name: 'PetsPanel',
  computed: {
    messages() {
      return messages
    }
  },
  components: {
    MorePet
  },
  props: {
    pets: {
      type: Array,
      required: true,
    },
    clientId: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      selectedPet: null,
    }
  },
  methods: {
    getPetIcon(type) {
      switch (type.toLowerCase()) {
        case 'dog':
          return 'mdi-dog'
        case 'cat':
          return 'mdi-cat'
        default:
          return 'mdi-paw'
      }
    },
    formatDate(dateString) {
      if (!dateString) return 'N/A'
      const options = { year: 'numeric', month: 'short', day: 'numeric' }
      return new Date(dateString).toLocaleDateString('lv-LV', options)
    },
    showPetDetails(pet) {
      this.selectedPet = pet
    },
    closePetDetails() {
      this.selectedPet = null
    },
    updatePet() {
      this.$emit('pet-updated')
    },
  },
}
</script>

<style scoped>
.pets-panel {
  border-radius: 8px;
  width: 100%;
}

.pet-card {
  transition: all 0.3s ease;
  height: 100%; /* Ensure all cards have the same height */
}

.pet-card:hover {
  transform: translateY(-2px);
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
}

.v-list-item__avatar {
  margin-top: 0;
}

.v-list-item__title {
  font-size: 1rem;
  line-height: 1.2;
}

.v-list-item__subtitle {
  font-size: 0.75rem;
  line-height: 1.2;
}

.v-card__actions {
  justify-content: flex-end;
}

.v-btn {
  text-transform: none;
}

/* Ensure consistent height for list items */
.v-list-item.three-line {
  height: auto;
  min-height: 88px; /* Adjust this value as needed */
}
</style>