<template>
  <div class="time-section">
    <div v-if="timeSlots === undefined" class="closed">
      <div class="closed-text">{{ messages.clinicIsClosed }}</div>
    </div>
    <div v-else class="open">
      <div class="time-column">
        <div class="time-slot" v-for="(timeSlot, index) in timeSlots" :key="`time-${index}`">
          <div class="time">{{ timeSlot }}</div>
        </div>
      </div>
      <div class="notes-column">
        <div
          v-for="(timeSlot, index) in timeSlots"
          :key="`slot-${index}`"
          class="note-slot"
          :class="{ 'hoverable': !getNoteAtSlot(timeSlot) }"
          @click="handleSlotClick(timeSlot, getNoteAtSlot(timeSlot))"
        >
          <span v-if="!getNoteAtSlot(timeSlot)" class="click-me">{{ messages.addNewNote }}</span>
        </div>
        <div
          v-for="note in notes"
          :key="note.id"
          class="note"
          :style="getNoteStyle(note)"
          :class="{ 'note-appear': note.isNew }"
          @click="handleSlotClick(note.startTime, note)"
        >
          <vertical-line :state="note.state" :grids-taken="getGridsTaken(note)" />
          <div class="note-content" v-if="note.type === 'visit'">
            <div class="note-left">
              <div class="note-title">{{ note.title }}</div>
              <div class="note-description">{{ note.description }}</div>
            </div>
            <div class="owner-text valign-text-middle">
              <span>
                <span class="inter-light-elephant-16px-2">{{ messages.client }}</span>
                <span class="span1 default-style">:</span>
                <span class="inter-light-elephant-16px">&nbsp;</span>
                <span class="span-1 small-text">{{ getOwnerName(note.ownerId) }}</span>
              </span>
              <span>
                <span class="inter-light-elephant-16px-2">{{ messages.pet }}</span>
                <span class="span1 default-style">: </span>
                <span class="span-1 small-text">{{ getPetName(note.ownerId, note.petId) }}</span>
              </span>
            </div>
          </div>
          <div class="note-content" v-if="note.type === 'task'">
            <div>
              <div class="note-title">{{ note.title }}</div>
              <div class="note-description">{{ note.description }}</div>
            </div>
          </div>
          <status-block 
            class="status" 
            :state="note.state" 
            @update:state="updateNoteStatus(note, $event)"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import VerticalLine from '../../components/VerticalLine.vue'
import StatusBlock from '../../components/StatusBlock.vue'
import { database } from '../../ipc.js'
import { messages } from '../../locale'
import { parameters } from '@/vue/settings'

export default {
  name: 'TimeSection',
  computed: {
    messages() {
      return messages
    },
    locale() {
      return locale
    }
  },
  data() {
    return {
      clients: null,
    }
  },
  async created() {
    await this.fetchClients();
  },
  components: {
    VerticalLine,
    StatusBlock
  },
  props: {
    notes: {
      type: Array,
    },
    timeSlots: {
      type: Array,
    }
  },
  methods: {
    async fetchClients(){
      this.clients = await database.getAllClients();
    },
    async updateNoteStatus(note, newState) {
      note.state = newState;
      await database.updateNote(note.id, note);
    },
    getOwnerName(clientId){
      if (!this.clients) {
        return messages.loading;
      }
      const localClient = this.clients.find(client => client.id === clientId);
      return localClient ? localClient.fullName : messages.empty;
    },
    getPetName(clientId, petId){
      if (!this.clients) {
        return messages.loading;
      }
      const localClient = this.clients.find(client => client.id === clientId);
      const clientPets = localClient ? (localClient.animals || []) : [];
      const localPet = clientPets.find(pet => pet.id === petId);
      return localPet ? localPet.name : messages.empty;
    },
    timeToMinutes(time) {
      const [hours, minutes] = time.split(':').map(Number);
      return hours * 60 + minutes;
    },
    getNoteAtSlot(timeSlot) {
      return this.notes.find(note =>
        this.timeToMinutes(note.startTime) <= this.timeToMinutes(timeSlot) &&
        this.timeToMinutes(note.endTime) > this.timeToMinutes(timeSlot)
      );
    },
    getGridsTaken(note) {
      const startMinutes = this.timeToMinutes(note.startTime);
      const endMinutes = this.timeToMinutes(note.endTime);
      return (endMinutes - startMinutes) / parameters.calendarGridInterval;
    },
    getNoteStyle(note) {
      const startMinutes = this.timeToMinutes(note.startTime);
      const startOfDay = this.timeToMinutes(this.timeSlots[0]);
      const top = ((startMinutes - startOfDay) / parameters.calendarGridInterval) * 90;
      const height = this.getGridsTaken(note) * 90;

      return {
        position: 'absolute',
        top: `${top}px`,
        height: `${height}px`,
        width: "calc(100% - 10px)",
        left: '0',
        right: '0',
        backgroundColor: 'white',
        borderBottom: '1px solid var(--bon-jour)',
        zIndex: 1,
        padding: '10px',
        overflow: 'hidden',
        display: 'flex',
        alignItems: 'center',
        transition: 'box-shadow 0.3s ease, opacity 0.5s ease, transform 0.5s ease',
        opacity: note.isNew ? 0 : 1,
        transform: note.isNew ? 'translateY(20px)' : 'translateY(0)',
      };
    },
    handleSlotClick(timeSlot, note) {
      if (note) {
        this.$emit('note-click', note);
      } else {
        this.$emit('empty-slot-click', timeSlot);
      }
    }
  }
}
</script>

<style scoped>
.time-section {
  height: 100%;
  display: flex;
}

.open {
  display: flex;
  width: 100%;
  margin-top: 30px;
}

.closed {
  display: flex;
  height: 100%;
  width: 100%;
  align-items: center;
  justify-content: center;
}

.closed-text {
  color: rgba(26, 53, 71, 0.50);
  font-family: Poppins, serif;
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  margin-left: 0;
  padding-left: 0;
}

.time-column {
  width: 60px;
  flex-shrink: 0;
}

.notes-column {
  flex-grow: 1;
  position: relative;
  border-top: 1px solid #e0e0e0;
}

.time-slot, .note-slot {
  height: 90px;
  display: flex;
  align-items: flex-start;
  position: relative;
  margin-right: 15px;
}

.time-slot {
  height: 90px;
  display: flex;
  align-items: flex-start;
  justify-content: flex-end;
  position: relative;
}

.time {
  color: rgba(26, 53, 71, 0.5);
  font-family: 'Poppins', sans-serif;
  font-weight: 500;
  padding-right: 10px;
  text-align: right;
  position: absolute;
  top: -10px;
  right: 0;
}

.note-slot::before {
  content: '';
  position: absolute;
  left: -15px;
  width: 15px;
  height: 1px;
  background-color: #e0e0e0;
  top: -1px;
}

.note-slot {
  position: relative;
  border-bottom: 1px solid #e0e0e0;
  border-left: 1px solid #e0e0e0;
  box-sizing: border-box;
}

.note-slot.hoverable {
  cursor: pointer;
}

.note-slot.hoverable:hover {
  background-image: linear-gradient(to right, rgba(0, 0, 0, 0.05), rgba(0, 0, 0, 0));
}

.note-slot .click-me {
  display: none;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  color: rgba(26, 53, 71, 0.25);
  text-align: center;
  font-family: Inter, serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 150%;
}

.note-slot.hoverable:hover .click-me {
  display: block;
}

.note-content {
  flex-grow: 1;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  height: 100%;
  margin-left: 10px;
}

.note-title {
  font-weight: bold;
  font-size: 16px;
  color: #333;
  margin-bottom: 5px;
}

.note-description {
  font-size: 14px;
  color: #666;
  margin-bottom: 10px;
}

.note-details {
  display: flex;
  flex-direction: column;
  font-size: 12px;
  color: #888;
}

.note-details span {
  margin-bottom: 2px;
}

.owner-text,
.animal-text {
  height: 31px;
  letter-spacing: 0;
  line-height: 24px;
  position: relative;
  max-width: 100%;
  padding-right: 100px;
}

.span1 {
  color: var(--elephant-2);
  font-weight: 400;
}

.span-1 {
  color: var(--elephant);
  font-weight: 500;
}

.status {
  align-items: flex-start;
}

.note {
  transition: box-shadow 0.3s ease;
}

.note:hover {
  background-image: linear-gradient(to right, rgba(0, 0, 0, 0.05), rgba(0, 0, 0, 0));
  cursor: pointer;
}

.note-appear {
  animation: noteAppear 0.5s ease forwards;
}

@keyframes noteAppear {
  0% {
    opacity: 0;
    transform: translateY(20px);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}

@media (max-width: 1200px) {
  .time-column {
    width: 50px;
  }

  .notes-column {
    width: calc(100% - 50px);
  }

  .time {
    font-size: 12px;
  }

  .note-title {
    font-size: 14px;
  }

  .note-description {
    font-size: 12px;
  }
}

@media (max-width: 768px) {
  .time-column {
    width: 40px;
  }

  .notes-column {
    width: calc(100% - 40px);
  }

  .time {
    font-size: 10px;
  }

  .note-title {
    font-size: 12px;
  }

  .note-description {
    font-size: 10px;
  }

  .owner-text,
  .animal-text {
    font-size: 10px;
    line-height: 16px;
  }
}
</style>