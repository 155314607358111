<template>
  <article class="main-frame">
    <div class="switch-frame">
      <ToggleSwitch :is-active="isOpen" @toggle="onToggle" />
      <div class="box-align default-style">{{ messages.weekDays.full[workingDayIndex] }}</div>
    </div>
    <div v-if="isOpen" class="time-frame">
      <TimePicker
        :value="startTime"
        @input="updateStartTime"
        :availableTimes="availableTimes"
        outlined
      />
      <img class="line-15" src="/img/line-white.svg" alt="Line 15" />
      <TimePicker
        :value="endTime"
        @input="updateEndTime"
        :availableTimes="availableTimes"
        outlined
      />
    </div>
    <div v-else class="closed-text">
      {{ messages.clinicIsClosed }}
    </div>
  </article>
</template>

<script>
import ToggleSwitch from '/src/vue/components/ToggleSwitch.vue'
import TimePicker from '/src/vue/components/TimePicker.vue'
import { messages } from '../../../locale'

export default {
  name: 'TimeLine',
  components: {
    ToggleSwitch,
    TimePicker,
  },
  props: {
    workingDayIndex: {
      type: String,
      required: true
    },
    dayData: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      isOpen: this.dayData.isOpen,
      startTime: this.dayData.start,
      endTime: this.dayData.end,
      availableTimes: this.generateTimeOptions(),
    }
  },
  computed: {
    messages() {
      return messages
    },
  },
  methods: {
    onToggle(value) {
      this.isOpen = value
      this.emitUpdate()
    },
    generateTimeOptions() {
      const times = []
      for (let hour = 0; hour < 24; hour++) {
        for (let minute = 0; minute < 60; minute += 30) {
          const formattedHour = hour.toString().padStart(2, '0')
          const formattedMinute = minute.toString().padStart(2, '0')
          times.push(`${formattedHour}:${formattedMinute}`)
        }
      }
      return times
    },
    emitUpdate() {
      this.$emit('update', this.workingDayIndex, {
        isOpen: this.isOpen,
        start: this.startTime,
        end: this.endTime,
      })
    },
    updateStartTime(time) {
      this.startTime = time
      this.emitUpdate()
    },
    updateEndTime(time) {
      this.endTime = time
      this.emitUpdate()
    },
  },
  watch: {
    dayData: {
      handler(newData) {
        this.isOpen = newData.isOpen
        this.startTime = newData.start
        this.endTime = newData.end
      },
      immediate: true,
      deep: true
    }
  },
}
</script>

<style>
.closed-text {
  font-family: 'Inter', sans-serif;
  margin-left: 140px;
  opacity: 0.4;
  color: var(--elephant);
  font-weight: 400;
  line-height: 24px;
}

.main-frame {
  /* align-items: center; */
  display: flex;
  gap: 50px;
  height: 40px;
  position: relative;
  width: 495px;
}

.switch-frame {
  align-items: center;
  display: inline-flex;
  flex: 0 0 auto;
  gap: 10px;
  position: relative;
}

.box-align {
  color: var(--elephant);
  font-weight: 400;
  line-height: 24px;
  margin-top: -1px;
  position: relative;
  width: 85px;
}

.time-frame {
  align-items: flex-start;
  display: inline-flex;
  flex: 0 0 auto;
  grid-gap: 15px;
  position: relative;
}

.line-15 {
  margin-top: 20px;
  height: 1px;
  object-fit: cover;
  position: relative;
  width: 15px;
}
</style>