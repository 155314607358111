<template>
  <div class="text-field">
    <v-text-field
      v-model="inputValue"
      :label="label"
      :type="type"
      color="var(--mosque)"
      :rules="rules"
      v-bind="$attrs"
      @input="$emit('input', inputValue)"
    >
    <template v-if="unit" v-slot:append>
      <span class="unit">{{unit}}</span>
    </template>
  </v-text-field>
  </div>
</template>

<script>
export default {
  name: 'TextField',
  inheritAttrs: false,
  props: {
    value: {
      default: ''
    },
    label: {
      default: ''
    },
    type: {
      default: 'text'
    },
    unit: {
      type: String,
      default: null
    },
    rules: {
      type: Array,
      default: () => []
    }
  },
  data() {
    return {
      inputValue: this.value
    }
  },
  watch: {
    value(newValue) {
      this.inputValue = newValue
    }
  }
}
</script>

<style scoped>
.text-field {
  width: 100%;
}

.unit {
  margin-top: 5px;
  color: var(--elephant);
}

::v-deep .v-input__slot {
  min-height: 40px;
}

::v-deep .v-text-field__details {
  display: none;
}

::v-deep .v-input input {
  font-family: 'Inter', sans-serif;
  font-size: 14px;
}

::v-deep .v-label {
  font-family: 'Inter', sans-serif;
  font-size: 14px;
}

::v-deep .v-text-field.v-text-field--outlined fieldset {
  opacity: 0.5; /* Adjust this value as needed */
}
</style>