<template>
  <v-card class="client-profile" flat>
    <v-card-text v-if="client">
      <div class="client-header">
        <v-avatar size="80" color="var(--mosque)">
          <span class="white--text headline">{{ initials }}</span>
        </v-avatar>
        <div class="client-info">
          <ClientNameItem
            :value="client.fullName"
            :is-editing="editingFields.fullName"
            @edit="enableEditing('fullName')"
            @cancel="cancelChanges('fullName')"
            @save="saveField($event.field, $event.value)"
          />
          <div class="client-statistics">
            <div class="stat-item">
              <v-icon small color="var(--camelot)">mdi-calendar-check</v-icon>
              <span>{{ visitsCount }} {{ messages.visits }}</span>
              <span>&nbsp;&nbsp;&nbsp;</span>
              <v-icon small color="var(--camelot)">mdi-calendar-clock</v-icon>
              <span>{{ notesCount }} {{ messages.notes }}</span>
            </div>
          </div>
        </div>
      </div>

      <v-row>
        <v-col cols="12" md="6">
          <info-item
            icon="mdi-phone"
            :label="messages.phoneNumber"
            :value="client.phone"
            :is-editing="editingFields.phone"
            @edit="enableEditing('phone')"
            @cancel="cancelChanges('phone')"
            @input="updateField('phone', $event)"
            @save="saveField('phone', client.phone)"
          >
            <template v-slot:edit>
              <v-text-field
                :value="client.phone"
                @input="updateField('phone', $event)"
                dense
                outlined
              >
                <template v-slot:append>
                  <v-icon
                    small
                    @click="cancelChanges('phone')"
                    class="cancel-icon"
                    >mdi-close</v-icon
                  >
                </template>
              </v-text-field>
            </template>
          </info-item>

          <info-item
            icon="mdi-email"
            :label="messages.email"
            :value="client.email"
            :is-editing="editingFields.email"
            @edit="enableEditing('email')"
            @cancel="cancelChanges('email')"
            @input="updateField('email', $event)"
            @save="saveField('email', client.email)"
          >
            <template v-slot:edit>
              <v-text-field
                :value="client.email"
                @input="updateField('email', $event)"
                dense
                outlined
              >
                <template v-slot:append>
                  <v-icon
                    small
                    @click="cancelChanges('email')"
                    class="cancel-icon"
                    >mdi-close</v-icon
                  >
                </template>
              </v-text-field>
            </template>
          </info-item>
        </v-col>

        <v-col cols="12" md="6">
          <info-item
            icon="mdi-map-marker"
            :label="messages.address"
            :value="client.address"
            :is-editing="editingFields.address"
            @edit="enableEditing('address')"
            @cancel="cancelChanges('address')"
            @input="updateField('address', $event)"
            @save="saveField('address', client.address)"
          >
            <template v-slot:edit>
              <v-text-field
                :value="client.address"
                @input="updateField('address', $event)"
                dense
                outlined
                rows="3"
              >
                <template v-slot:append>
                  <v-icon
                    small
                    @click="cancelChanges('address')"
                    class="cancel-icon"
                    >mdi-close</v-icon
                  >
                </template>
              </v-text-field>
            </template>
          </info-item>

          <info-item
            icon="mdi-comment"
            :label="messages.comments"
            :value="client.comment"
            :is-editing="editingFields.comment"
            @edit="enableEditing('comment')"
            @cancel="cancelChanges('comment')"
            @input="updateField('comment', $event)"
            @save="saveField('comment', client.comment)"
          >
            <template v-slot:edit>
              <v-textarea
                :value="client.comment"
                @input="updateField('comment', $event)"
                auto-grow
                outlined
                rows="3"
              >
                <template v-slot:append>
                  <v-icon
                    small
                    @click="cancelChanges('comment')"
                    class="cancel-icon"
                    >mdi-close</v-icon
                  >
                </template>
              </v-textarea>
            </template>
          </info-item>
        </v-col>
      </v-row>
    </v-card-text>
    <v-card-text v-else>
      <v-progress-circular
        indeterminate
        color="var(--camelot)"
      ></v-progress-circular>
    </v-card-text>
  </v-card>
</template>

<script>
import InfoItem from './InfoItem.vue'
import ClientNameItem from './ClientNameItem.vue'
import { database } from '@/vue/ipc'
import { messages } from '../../../locale'

export default {
  name: 'ClientProfile',
  components: {
    InfoItem,
    ClientNameItem,
  },
  props: {
    client: {
      type: Object,
      required: true,
    },
    editingFields: {
      type: Object,
      required: true,
    },
    isEdited: {
      type: Boolean,
      required: true,
    },
    visitsCount: {
      type: Number,
      default: 0,
    },
    notesCount: {
      type: Number,
      default: 0,
    },
  },
  methods: {
    updateField(field, value) {
      this.$emit('input', { field, value })
    },
    async saveField(field, value) {
      this.$emit('save', { field, value })
    },
    enableEditing(field) {
      this.$emit('edit', field)
    },
    cancelChanges(field) {
      this.$emit('cancel', field)
    },
    resetEditingState(field) {
      this.$emit('edit', { ...this.editingFields, [field]: false })
    },
  },
  computed: {
    messages() {
      return messages
    },
    initials() {
      if (this.client && this.client.fullName) {
        return this.client.fullName
          .split(' ')
          .map((name) => name[0])
          .join('')
          .toUpperCase()
      }
      return ''
    },
  },
}
</script>

<style scoped>
.full-name {
  font-size: 20px;
  color: black;
}

.client-profile {
  background-color: white;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.client-header {
  display: flex;
  align-items: flex-start;
  margin-bottom: 20px;
}

.client-info {
  margin-left: 20px;
  flex-grow: 1;
}

.client-info h2 {
  margin-bottom: 5px;
  display: flex;
  align-items: center;
}

.edit-btn {
  margin-left: 8px;
  opacity: 0;
  transition: opacity 0.3s ease;
}

.client-info:hover .edit-btn {
  opacity: 1;
}

.client-statistics {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-left: auto;
}

.stat-item {
  display: flex;
  align-items: center;
  margin-bottom: 5px;
  margin-right: 50px;
}

.stat-item .v-icon {
  margin-right: 5px;
}

.button-section {
  margin-top: 20px;
  display: flex;
  justify-content: flex-end;
  gap: 10px;
}

.cancel-icon {
  cursor: pointer;
  /* background-color: red; */
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 2px;
  margin: 2px;
  border-radius: 30px;
  font-size: 40px;
}
.cancel-icon:hover {
  cursor: pointer;
  background-color: var(--bon-jour);
  margin: 0;
  padding: 4px;
  border-radius: 30px;
}
</style>