<template>
  <div class="appointments-timeline">
    <div v-if="loading">{{ messages.loading }}</div>
    <div v-else-if="error">Error: {{ error }}</div>
    <div v-else>
      <div class="panel-header">
        <div class="pagination">
          <v-btn icon @click="previousPage" :disabled="currentPage === 1">
            <v-icon>mdi-chevron-left</v-icon>
          </v-btn>
          <span class="page-indicator"
            >{{ currentPage }} / {{ totalPages }}</span
          >
          <v-btn icon @click="nextPage" :disabled="currentPage === totalPages">
            <v-icon>mdi-chevron-right</v-icon>
          </v-btn>
        </div>
      </div>
      <div class="timeline-container" ref="timelineContainer">
        <div
          v-for="entry in paginatedEntries"
          :key="entry.id"
          class="timeline-entry"
        >
          <div class="entry-date">
            {{ formatDate(entry.date) }}
          </div>
          <div class="entry-content" @click="openEditModal(entry)">
            <div class="entry-type-indicator" :class="getIndicatorClass(entry)">
              <v-icon v-if="entry.entryType === 'visit'" small
                >mdi-medical-bag</v-icon
              >
              <v-icon v-else-if="entry.entryType === 'note'" small
                >mdi-note-text</v-icon
              >
            </div>
            <div class="entry-details">
              <h4>{{ entry.title }}</h4>
              <p v-if="entry.description">
                {{ truncateDescription(entry.description) }}
              </p>
              <p v-if="entry.petName">
                <v-icon small>mdi-paw</v-icon>
                {{ entry.petName }}
              </p>
              <p v-if="entry.startTime && entry.endTime">
                <v-icon small>mdi-clock-outline</v-icon>
                {{ entry.startTime }} - {{ entry.endTime }}
              </p>
            </div>
            <status-block v-if="entry.entryType === 'note'" class="status" :state="entry.state" />
          </div>
        </div>
      </div>
    </div>

    <more-visit
      v-if="editedEntry && editedEntry.entryType === 'visit'"
      :visit="editedEntry"
      @update-visit="updateVisit"
      @visit-deleted="fetchData"
      @close="closeEditModal"
    />
    <edit-calendar-item
      v-if="editedEntry && editedEntry.entryType === 'note'"
      :note="editedEntry"
      :isCalendar="false"
      @noteDeleted="fetchData"
      @close="closeEditModal"
    />
  </div>
</template>

<script>
import { database } from '/src/vue/ipc.js'
import StatusBlock from '/src/vue/components/StatusBlock.vue'
import EditCalendarItem from '/src/vue/pages/calendar/add-calendar-overlay/EditCalendarItem.vue'
import MoreVisit from '/src/vue/pages/fulllist/components/pop-ups/MoreVisit.vue'
import { messages } from '../../../../locale'

export default {
  name: 'AppointmentsPanel',
  components: {
    StatusBlock,
    MoreVisit,
    EditCalendarItem,
  },
  props: {
    clientId: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      visits: [],
      notes: [],
      editedEntry: null,
      petOptions: [],
      currentPage: 1,
      itemsPerPage: 2,
      loading: true,
      error: null,
    }
  },
  computed: {
    messages() {
      return messages
    },
    sortedEntries() {

      const allEntries = [
        ...this.visits.map((visit) => {
          return {
            ...visit,
            entryType: 'visit',
            title: visit.topic,
            description: visit.description || '',
            petName: this.getPetName(visit.petId),
            date: visit.date || visit.visitDate, // Handle potential different date field names
          }
        }),
        ...(Array.isArray(this.notes) ? this.notes : []).map((note) => {
          return {
            ...note,
            entryType: 'note',
            title: note.title,
            description: note.description || note.content || '',
            petName: this.getPetName(note.petId),
            date: note.date || note.createdAt, // Handle potential different date field names
          }
        }),
      ]

      return allEntries.sort((a, b) => new Date(b.date) - new Date(a.date))
    },
    paginatedEntries() {
      const start = (this.currentPage - 1) * this.itemsPerPage
      const end = start + this.itemsPerPage
      return this.sortedEntries.slice(start, end)
    },
    totalPages() {
      return Math.ceil(this.sortedEntries.length / this.itemsPerPage)
    },
  },
  async mounted() {
    await this.fetchData()
  },
  methods: {
    async updateVisit() {
      await this.fetchData();
    },
    async fetchData() {
      try {
        this.visits = await database.getVisitsForClient(this.clientId)
        this.notes = await database.getNotesForClient(this.clientId)
        this.petOptions = await database.getPets(this.clientId)
      } catch (error) {
        console.error('Error fetching data:', error)
        this.error = 'Failed to load data. Please try again.'
      } finally {
        this.loading = false
      }
    },
    formatDate(dateString) {
      if (!dateString) return 'No date'
      const date = new Date(dateString)
      const month = date
        .toLocaleString('default', { month: 'short' })
        .toUpperCase()
      const day = date.getDate()
      const year = date.getFullYear()
      return `${month}\n${day}\n${year}`
    },
    getPetName(petId) {
      const pet = this.petOptions.find((pet) => pet.id === petId)
      return pet ? pet.name : 'Unknown Pet'
    },
    getIndicatorClass(entry) {
      return {
        'entry-type-indicator': true,
        'visit-indicator': entry.entryType === 'visit',
        'note-indicator': entry.entryType === 'note',
        [`status-${entry.state}`]: true,
      }
    },
    openEditModal(entry) {
      if (entry.entryType === 'visit' || entry.entryType === 'note') {
        this.editedEntry = { 
          ...entry,
          startTime: entry.timeStart,
          endTime: entry.timeEnd,
          ownerId: this.clientId  // Ensure clientId is set here
        };
      } else {
        this.editedEntry = null;
      }
    },
    closeEditModal() {
      this.editedEntry = null
    },
    truncateDescription(description) {
      return description && description.length > 100
        ? description.slice(0, 97) + '...'
        : description
    },
    previousPage() {
      if (this.currentPage > 1) {
        this.currentPage--
        this.scrollToTop()
      }
    },
    nextPage() {
      if (this.currentPage < this.totalPages) {
        this.currentPage++
        this.scrollToTop()
      }
    },
    scrollToTop() {
      if (this.$refs.timelineContainer) {
        this.$refs.timelineContainer.scrollTop = 0
      }
    },
  },
}
</script>

<style scoped>
.appointments-timeline {
  font-family: 'Roboto', Arial, sans-serif;
  padding: 20px;
  border-radius: 8px;
}

.panel-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
}

.panel-title {
  font-size: 24px;
  color: #333;
  margin: 0;
}

.pagination {
  display: flex;
  align-items: center;
}

.page-indicator {
  font-size: 14px;
  color: #666;
  margin: 0 10px;
}

.timeline-container {
  position: relative;
  padding-left: 30px;
  max-height: 600px;
  overflow: hidden;
  scrollbar-width: thin;
  scrollbar-color: #888 #f1f1f1;
}

.timeline-container::-webkit-scrollbar {
  width: 6px;
}

.timeline-container::-webkit-scrollbar-track {
  background: #f1f1f1;
}

.timeline-container::-webkit-scrollbar-thumb {
  background: #888;
  border-radius: 3px;
}

.timeline-container::-webkit-scrollbar-thumb:hover {
  background: #555;
}

.timeline-container::before {
  content: '';
  position: absolute;
  left: 15px;
  top: 0;
  bottom: 0;
  width: 2px;
  background-color: #ccc;
}

.timeline-entry {
  display: flex;
  margin-bottom: 20px;
  position: relative;
  align-items: flex-start;
}

.entry-date {
  width: 60px;
  text-align: right;
  padding-right: 20px;
  font-size: 12px;
  color: #666;
  white-space: pre-line;
  flex-shrink: 0;
}

.entry-content {
  flex-grow: 1;
  background-color: white;
  border-radius: 8px;
  padding: 10px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  position: relative;
  cursor: pointer;
  transition:
    box-shadow 0.3s ease,
    transform 0.3s ease;
  display: flex;
  align-items: flex-start;
}

.entry-content:hover {
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.15);
  transform: translateY(-2px);
}

.entry-type-indicator {
  width: 24px;
  height: 24px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 15px;
  flex-shrink: 0;
}

.visit-indicator {
  background-color: rgb(214, 214, 214);
}

.status-canceled {
  background-color: rgba(253, 169, 169, 0.5);
}

.status-new {
  background-color: rgb(214, 214, 214);
}

.status-in-process {
  background-color: rgba(255, 232, 202, 0.5);
}

.status-completed {
  background-color: rgba(220, 255, 200, 0.5);
}

.entry-details {
  flex-grow: 1;
}

.entry-details h4 {
  margin: 0 0 10px 0;
  color: #333;
  font-size: 16px;
}

.entry-details p {
  margin: 5px 0;
  color: #666;
  font-size: 14px;
  display: flex;
  align-items: center;
}

.entry-details p .v-icon {
  margin-right: 5px;
}

.edit-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.6);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.edit-pop-up {
  background-color: white;
  border-radius: 8px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.status {
  display: none;
}

@media (min-width: 1700px) {
  .status {
    display: flex;
  }
}
</style>