<template>
  <div class="vaccine-info-display">
    <div class="vaccine-table">
      <table>
        <thead>
          <tr>
            <th>{{ messages.name }}</th>
            <th>{{ messages.state }}</th>
            <th>{{ messages.doses }}</th>
            <th>{{ messages.amount }}</th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="vaccine in vaccines" :key="vaccine.id">
            <td>
              <span class="vaccine-name">
                <i class="mdi mdi-needle"></i>
                {{ vaccine.name }}
              </span>
            </td>
            <td>
              <span :class="['status-badge', getStatusClass(vaccine)]">
                {{ getStatusText(vaccine) }}
              </span>
            </td>
            <td>
              <div class="progress-bar">
                <div
                  class="progress"
                  :style="{ width: `${getDoseProgressPercentage(vaccine)}%` }"
                ></div>
              </div>
              <span class="progress-text">
                {{ vaccine.currentDosesAmount }} / {{ vaccine.necessaryDosesAmount }} mL
              </span>
            </td>
            <td>
              <div class="progress-bar">
                <div
                  class="progress"
                  :style="{ width: `${getCountProgressPercentage(vaccine)}%` }"
                ></div>
              </div>
              <span class="progress-text">
                {{ vaccine.currentDosesCount }} / {{ vaccine.necessaryDosesCount }}
              </span>
            </td>
            <td class="actions-cell">
              <button @click="showVaccineActionMenu($event, vaccine)">
                <i class="mdi mdi-dots-vertical"></i>
              </button>
            </td>
          </tr>
          <tr class="add-vaccine-row" @click="addVaccine">
            <td colspan="5">
              <span class="add-vaccine-content">
                <i class="mdi mdi-plus"></i>
                {{ messages.addNewVaccine }}
              </span>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
    <action-menu-popup
      v-if="showActionMenu"
      :position="actionMenuPosition"
      :show="showActionMenu"
      @edit="editVaccine"
      @delete="removeVaccine"
      @close="closeActionMenu"
    />
  </div>
</template>

<script>
import ActionMenuPopup from '/src/vue/components/ActionMenuPopup.vue'
import { messages } from '../../../../locale'

export default {
  name: 'VaccineSection',
  components: {
    ActionMenuPopup,
  },
  props: {
    vaccines: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      showActionMenu: false,
      actionMenuPosition: { top: '0px', left: '0px' },
      selectedVaccine: null,
    }
  },
  computed: {
    messages() {
      return messages
    },
  },
  methods: {
    addVaccine() {
      this.$emit('add-vaccine')
    },
    showVaccineActionMenu(event, vaccine) {
      event.stopPropagation()
      this.selectedVaccine = vaccine
      this.showActionMenu = true
      this.$nextTick(() => {
        const rect = event.target.getBoundingClientRect()
        const spacing = 5
        this.actionMenuPosition = {
          top: `${rect.bottom + window.scrollY + spacing}px`,
          left: `${(rect.left + window.scrollX - 75 + rect.width / 2) - 50}px`,
        }
      })
    },
    editVaccine() {
      if (this.selectedVaccine) {
        this.$emit('edit-vaccine', this.selectedVaccine)
        this.closeActionMenu()
      }
    },
    removeVaccine() {
      if (this.selectedVaccine) {
        this.$emit('remove-vaccine', this.selectedVaccine.id)
        this.closeActionMenu()
      }
    },
    closeActionMenu() {
      this.showActionMenu = false
      this.selectedVaccine = null
    },
    getDoseProgressPercentage(vaccine) {
      return (vaccine.currentDosesAmount / vaccine.necessaryDosesAmount) * 100
    },
    getCountProgressPercentage(vaccine) {
      return (vaccine.currentDosesCount / vaccine.necessaryDosesCount) * 100
    },
    getStatusClass(vaccine) {
      const doseProgress = this.getDoseProgressPercentage(vaccine)
      const countProgress = this.getCountProgressPercentage(vaccine)
      if (doseProgress >= 100 && countProgress >= 100) return 'status-complete'
      if (doseProgress > 0 || countProgress > 0) return 'status-in-progress'
      return 'status-pending'
    },
    getStatusText(vaccine) {
      const doseProgress = this.getDoseProgressPercentage(vaccine)
      const countProgress = this.getCountProgressPercentage(vaccine)
      if (doseProgress >= 100 && countProgress >= 100) return messages.vaccineFinished
      if (doseProgress > 0 || countProgress > 0) return messages.vaccineInProcess
    },
  },
  mounted() {
    document.addEventListener('click', this.closeActionMenu)
  },
  beforeDestroy() {
    document.removeEventListener('click', this.closeActionMenu)
  },
}
</script>

<style scoped>
.vaccine-info-display {
  width: 100%;
}

.vaccine-table {
  width: 100%;
  overflow-x: auto;
}

table {
  width: 100%;
  border-collapse: collapse;
}

th,
td {
  padding: 10px;
  text-align: left;
  border-bottom: 1px solid var(--bon-jour);
  font-family: Inter, serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 150%;
}

th {
  color: #828282;
  font-weight: 600;
}

.vaccine-name {
  display: flex;
  align-items: center;
}

.mdi-needle {
  font-size: 24px;
  margin-right: 10px;
  color: #7f5c91;
  cursor: pointer;
}

.actions-cell {
  text-align: right;
}

.mdi-dots-vertical {
  font-size: 20px;
  color: #08616d;
  cursor: pointer;
}

.add-vaccine-row {
  cursor: pointer;
  transition: background-color 0.3s;
}

.add-vaccine-row:hover {
  background-color: #f0f0f0;
}

.add-vaccine-content {
  display: flex;
  align-items: center;
  color: #08616d;
  font-weight: 400;
}

.mdi-plus {
  font-size: 24px;
  margin-right: 10px;
  color: #08616d;
}

.status-badge {
  padding: 4px 8px;
  border-radius: 12px;
  font-size: 12px;
  font-weight: 500;
}

.status-complete {
  background-color: #e8f5e9;
  color: #4caf50;
}

.status-in-progress {
  background-color: #fff8e1;
  color: #ffa000;
}

.status-pending {
  background-color: #f5f5f5;
  color: #9e9e9e;
}

.progress-bar {
  width: 100px;
  height: 6px;
  background-color: #e0e0e0;
  border-radius: 3px;
  overflow: hidden;
}

.progress {
  height: 100%;
  background-color: #4caf50;
}

.progress-text {
  margin-left: 10px;
  font-size: 12px;
  color: #666;
}

@media (max-width: 600px) {
  th,
  td {
    font-size: 14px;
  }

  .mdi-needle,
  .mdi-dots-vertical {
    font-size: 20px;
  }
}
</style>
