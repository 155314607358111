<template>
    <v-btn 
    small 
    text 
    :color="color" 
    @click="$emit('click')" 
    class="text-none"
    style="font-size: 15px"
    
    >{{text}}</v-btn>
</template>

<script>
export default {
  name: 'SecondaryButton',
  props: {
    text: {
      type: String,
      default: ''
    },
    icon: {
      type: String,
      default: ''
    },
    color: {
      type: String,
      default: ''
    },
  },
}
</script>
