<template>
  <div class="info-item">
    <div class="info-content">
      <v-icon left class="info-icon">{{ icon }}</v-icon>
      <div class="info-text">
        <div class="info-label">{{ label }}</div>
        <div class="info-value">
          <component
            :is="multiline ? 'v-textarea' : 'v-text-field'"
            :value="localValue"
            @input="updateValue"
            :readonly="!isLocalEditing"
            dense
            hide-details
            :auto-grow="multiline"
            :rows="multiline ? 3 : undefined"
            class="inline-edit"
            :class="{ 'edit-mode': isLocalEditing, 'view-mode': !isLocalEditing }"
            :color="isLocalEditing ? 'var(--mosque)': 'transparent'"
          >
            <template v-slot:append>
              <v-icon
                v-if="!isLocalEditing"
                small
                @click="startEditing"
                class="action-icon edit-btn"
              >
                mdi-pencil
              </v-icon>
              <template v-if="isLocalEditing">
                <v-icon
                  small
                  @click="cancelEditing"
                  class="action-icon cancel-icon"
                >
                  mdi-close
                </v-icon>
                <v-icon
                  small
                  @click="saveChanges"
                  class="action-icon save-icon"
                >
                  mdi-content-save
                </v-icon>
              </template>
            </template>
          </component>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'InfoItem',
  props: {
    icon: String,
    label: String,
    value: String,
    isEditing: Boolean,
    multiline: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      localValue: this.value,
      isLocalEditing: false
    }
  },
  watch: {
    value(newValue) {
      if (!this.isLocalEditing) {
        this.localValue = newValue;
      }
    },
    isEditing(newValue) {
      this.isLocalEditing = newValue;
    }
  },
  methods: {
    updateValue(newValue) {
      if (this.isLocalEditing) {
        this.localValue = newValue;
      }
    },
    startEditing() {
      this.isLocalEditing = true;
      this.$emit('edit');
    },
    cancelEditing() {
      this.isLocalEditing = false;
      this.localValue = this.value;
      this.$emit('cancel');
    },
    saveChanges() {
      this.isLocalEditing = false;
      this.$emit('save', {
        field: this.label.toLowerCase(),
        value: this.localValue
      });
    }
  }
}
</script>

<style scoped>
.info-item {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  margin-bottom: 20px;
  position: relative;
  width: 70%;
}

.info-content {
  display: flex;
  align-items: flex-start;
  flex-grow: 1;
}

.info-icon {
  margin-top: 13px;
}

.info-text {
  margin-left: 10px;
  flex-grow: 1;
}

.info-label {
  font-size: 0.9em;
  color: rgba(0, 0, 0, 0.6);
}

.info-value {
  font-size: 1.1em;
  display: flex;
  align-items: center;
}

.inline-edit {
  font-size: 1.1em;
}

.inline-edit.view-mode :deep(.v-input__slot::before),
.inline-edit.view-mode :deep(.v-input__slot::after) {
  border-color: transparent !important;
}

.inline-edit.view-mode :deep(.v-input--is-focused) .v-input__slot::after {
  transform: scaleX(0) !important;
}

.inline-edit :deep(.v-input__slot) {
  min-height: 24px !important;
  padding: 0 !important;
}

.inline-edit :deep(.v-text-field__slot input),
.inline-edit :deep(textarea) {
  padding: 0;
}

.inline-edit:not(.v-input--is-disabled) :deep(.v-input__slot) {
  background-color: transparent;
}

.inline-edit.v-input--is-disabled :deep(.v-input__slot) {
  background-color: transparent !important;
}

.inline-edit :deep(.v-input__append-inner) {
  margin-top: 2px !important;
}

.action-icon {
  opacity: 0;
  transition: opacity 0.3s ease;
}

.info-item:hover .action-icon {
  opacity: 1;
}

.edit-btn,
.cancel-icon {
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 2px;
  margin: 2px;
  border-radius: 30px;
}

.cancel-icon:hover {
  background-color: var(--bon-jour);
  margin: 0;
  padding: 4px;
}
</style>