<template>
  <div class="medicine-info-display">
    <div class="medicine-table-container">
      <table>
        <thead>
          <tr>
            <th>{{ messages.name }}</th>
            <th>{{ messages.count }}</th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="medicine in medicines" :key="medicine.id">
            <td>
              <span class="medicine-name">
                <i class="mdi mdi-pill"></i>
                <input
                  class="medicine-input"
                  v-model="medicine.name"
                  @input="updateMedicine(medicine)"
                />
              </span>
            </td>
            <td>
              <div class="amount-wrapper">
                <input
                  v-model.number="medicine.amount"
                  class="amount-input"
                  :style="{ width: getInputWidth(medicine.amount) }"
                  @input="updateMedicine(medicine)"
                />
                <span class="unit">mL</span>
              </div>
            </td>
            <td class="remove-cell">
              <button
                @click="removeMedicine(medicine.id)"
                class="remove-button"
              >
                {{ messages.delete }}
              </button>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
    <div class="add-medicine-row" @click="addNewMedicine">
      <span class="add-medicine-content">
        <i class="mdi mdi-plus"></i>
        {{ messages.addNewMedicine }}
      </span>
    </div>
  </div>
</template>

<script>
import { messages } from '../../../../locale'

export default {
  name: 'MedicineSection',
  computed: {
    messages() {
      return messages
    },
  },
  props: {
    medicines: {
      type: Array,
      required: true,
    },
  },
  methods: {
    addNewMedicine() {
      this.$emit('add-medicine')
    },
    removeMedicine(id) {
      this.$emit('remove-medicine', id)
    },
    updateMedicine(medicine) {
      this.$emit('update-medicine', medicine)
    },
    getInputWidth(value) {
      const minWidth = 30
      const valueLength = String(value).length
      return Math.max(minWidth, valueLength * 10 + 10) + 'px'
    },
  },
}
</script>

<style scoped>
.medicine-info-display {
  width: 100%;
  display: flex;
  flex-direction: column;
  height: 100%;
}

.medicine-table-container {
  width: 100%;
  overflow-y: auto;
  max-height: 320px;
  position: relative;
}

table {
  width: 100%;
  border-collapse: separate;
  border-spacing: 0;
}

thead {
  position: sticky;
  top: 0;
  z-index: 10;
  background-color: white;
}

th,
td {
  padding: 10px;
  text-align: left;
  border-bottom: 1px solid var(--bon-jour);
  font-family: Inter, serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  /* line-height: 150%; */
}

th {
  color: #828282;
  font-weight: 600;
}

.remove-cell {
  text-align: right;
}

.remove-button {
  color: #08616d;
  background: none;
  border: none;
  cursor: pointer;
  padding: 0;
}

.medicine-name {
  display: flex;
  align-items: center;
}

.medicine-input {
  width: 160px;
}

.mdi-pill {
  font-size: 24px;
  margin-right: 10px;
  color: #7f5c91;
  cursor: pointer;
}

input {
  font-family: Inter, serif;
  font-size: 16px;
  padding: 5px;
  border: 1px solid #ccc;
  border-radius: 4px;
}

.amount-wrapper {
  display: flex;
  align-items: center;
}

.amount-input {
  min-width: 30px;
  max-width: 100px;
  transition: width 0.1s;
}

.unit {
  margin-left: 5px;
}

.add-medicine-row {
  cursor: pointer;
  transition: background-color 0.3s;
  padding: 10px;
}

.add-medicine-row:hover {
  background-color: #f0f0f0;
}

.add-medicine-content {
  display: flex;
  align-items: center;
  color: #08616d;
  font-weight: 400;
}

.mdi-plus {
  font-size: 24px;
  margin-right: 10px;
  color: #08616d;
}
</style>