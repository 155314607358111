<template>
  <div class="pop-up-search-dropdown">
    <v-autocomplete
      v-bind="$attrs"
      :items="items"
      :label="label"
      :prepend-inner-icon="prependInnerIcon"
      :solo="type === 'solo'"
      :outlined="type === 'outlined'"
      :filled="type === 'filled'"
      v-on="$listeners"
      color="var(--mosque)"
      item-color="var(--mosque)"
      :menu-props="{ contentClass: 'custom-menu' }"
    >
      <template v-slot:selection="{ attr, on, item, selected }">
        <v-chip
          v-bind="attr"
          :input-value="selected"
          color="var(--mosque)"
          class="white--text"
          v-on="on"
        >
          <span class="pr-2">
            {{ item.text }}
          </span>
        </v-chip>
      </template>
    </v-autocomplete>
  </div>
</template>

<script>
export default {
  name: 'SearchDropdown',
  props: {
    items: Array,
    label: String,
    prependInnerIcon: String,
    type: {
      type: String,
      default: 'default',
      validator: (value) => ['default', 'solo', 'outlined', 'filled'].includes(value)
    }
  }
}
</script>
