<!-- ModalTemplate.vue -->
<template>
  <transition name="modal" appear>
    <div class="modal-overlay" @mousedown.self="$emit('close')">
      <transition name="modal-content" appear>
        <div class="modal-container">
          <div class="modal-content">
            <slot></slot>
          </div>
        </div>
      </transition>
    </div>
  </transition>
</template>

<script>
export default {
  name: 'ModalTemplate',
}
</script>

<style scoped>
.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
  backdrop-filter: blur(5px);
}

.modal-container {
  background-color: white;
  border-radius: 8px;
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.15);
  max-height: 90vh;
  overflow-y: auto;
}

/* Overlay fade animation */
.modal-enter-active,
.modal-leave-active {
  transition: opacity 0.1s ease;
}

.modal-enter-from,
.modal-leave-to,
.modal-enter, /* For Vue 2 compatibility */
.modal-leave-to /* For Vue 2 compatibility */ {
  opacity: 0;
}

.modal-content-enter-from
.modal-content-enter /* For Vue 2 compatibility */
 /* For Vue 2 compatibility */ {
  opacity: 0;
  transform: scale(0.5) translateY(20px);
}

/* Scrollbar styling */
.modal-container::-webkit-scrollbar {
  width: 8px;
}

.modal-container::-webkit-scrollbar-track {
  background: #f1f1f1;
  border-radius: 0 12px 12px 0;
}

.modal-container::-webkit-scrollbar-thumb {
  background: #888;
  border-radius: 10px;
}

.modal-container::-webkit-scrollbar-thumb:hover {
  background: #555;
}
</style>