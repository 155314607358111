// browser-storage.js

class BrowserStorage {
  constructor() {
    this.storage = window.localStorage;
    this.initializeStorage();
    this.initializeDefaultWorkingHours();
  }

  generateUUID() {
    return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function(c) {
      var r = Math.random() * 16 | 0, v = c === 'x' ? r : (r & 0x3 | 0x8);
      return v.toString(16);
    });
  }

  initializeStorage() {
    this.storage.clear()
    this.storage.setItem('workingHours', JSON.stringify({}));
    this.storage.setItem('clients', JSON.stringify([]));
    this.storage.setItem('animals', JSON.stringify([]));
    this.storage.setItem('vaccines', JSON.stringify([]));
    this.storage.setItem('notes', JSON.stringify([]));
    this.storage.setItem('visits', JSON.stringify([]));
  }

  async initializeDefaultWorkingHours() {
    const defaultWorkingHours = {
      monday: { isOpen: true, start: '08:00', end: '17:00' },
      tuesday: { isOpen: true, start: '08:00', end: '17:00' },
      wednesday: { isOpen: true, start: '08:00', end: '17:00' },
      thursday: { isOpen: true, start: '08:00', end: '17:00' },
      friday: { isOpen: true, start: '08:00', end: '17:00' },
      saturday: { isOpen: false, start: '00:00', end: '00:00' },
      sunday: { isOpen: false, start: '00:00', end: '00:00' },
    };

    const existingWorkingHours = JSON.parse(this.storage.getItem('workingHours'));
    if (Object.keys(existingWorkingHours).length === 0) {
      this.storage.setItem('workingHours', JSON.stringify(defaultWorkingHours));
    }
    return this.getWorkingHours();
  }

  async getWorkingHours() {
    return JSON.parse(this.storage.getItem('workingHours'));
  }

  async updateWorkingHours(newHours) {
    this.storage.setItem('workingHours', JSON.stringify(newHours));
    return true;
  }

  async getAllClients() {
    const clients = JSON.parse(this.storage.getItem('clients'));
    for (const client of clients) {
      client.animals = await this.getPets(client.id);
    }
    return clients;
  }

  async initializeDefaultClients() {
    const defaultClient = {
      id: this.generateUUID(),
      fullName: "Jānis Bērziņš",
      email: "janis.berzins@example.com",
      address: "Rīgas iela 15, Rīga, LV-1001",
      phone: "+371 26123456",
      comment: "Regulārs klients, nāk katru mēnesi"
    };

    const defaultAnimal = {
      id: this.generateUUID(),
      clientId: defaultClient.id,
      name: "Reksis",
      sex: "Viriesu",
      chipNumber: "123456789012345",
      type: "Dog",
      breed: "Latvijas suns",
      birthdate: "2018-05-10",
      weight: 25,
      comment: "Alerģisks pret ziedputekšņiem"
    };

    const clients = JSON.parse(this.storage.getItem('clients'));
    clients.push(defaultClient);
    this.storage.setItem('clients', JSON.stringify(clients));

    const animals = JSON.parse(this.storage.getItem('animals'));
    animals.push(defaultAnimal);
    this.storage.setItem('animals', JSON.stringify(animals));

    return [{ ...defaultClient, animals: [defaultAnimal] }];
  }

  async getPets(clientId) {
    const animals = JSON.parse(this.storage.getItem('animals'));
    const clientAnimals = animals.filter(animal => animal.clientId === clientId);
    for (const animal of clientAnimals) {
      animal.vaccines = await this.getAnimalVaccines(clientId, animal.id);
    }
    return clientAnimals;
  }

  async addClient(clientData) {
    const id = this.generateUUID();
    const clients = JSON.parse(this.storage.getItem('clients'));
    clients.push({ ...clientData, id, registrationDate: new Date().toISOString().split('T')[0] });
    this.storage.setItem('clients', JSON.stringify(clients));
    return id;
  }

  async removeClient(clientId) {
    let clients = JSON.parse(this.storage.getItem('clients'));
    const initialLength = clients.length;
    clients = clients.filter(client => client.id !== clientId);
    this.storage.setItem('clients', JSON.stringify(clients));
    return clients.length < initialLength;
  }

  async addAnimal(clientId, animalData) {
    const id = this.generateUUID();
    const animals = JSON.parse(this.storage.getItem('animals'));
    animals.push({ ...animalData, id, clientId });
    this.storage.setItem('animals', JSON.stringify(animals));
    return id;
  }

  async getAnimalVaccines(clientId, animalId) {
    const vaccines = JSON.parse(this.storage.getItem('vaccines'));
    return vaccines.filter(vaccine => vaccine.animalId === animalId);
  }

  async addVaccineToAnimal(clientId, animalId, vaccineData) {
    const id = this.generateUUID();
    const vaccines = JSON.parse(this.storage.getItem('vaccines'));
    const newVaccine = { ...vaccineData, id, animalId };
    vaccines.push(newVaccine);
    this.storage.setItem('vaccines', JSON.stringify(vaccines));
    return newVaccine;
  }

  async updateAnimalVaccine(clientId, animalId, vaccineId, updatedData) {
    const vaccines = JSON.parse(this.storage.getItem('vaccines'));
    const index = vaccines.findIndex(v => v.id === vaccineId && v.animalId === animalId);
    if (index !== -1) {
      vaccines[index] = { ...vaccines[index], ...updatedData };
      this.storage.setItem('vaccines', JSON.stringify(vaccines));
      return vaccines[index];
    }
    return null;
  }

  async deleteVaccinesForAnimal(animalId) {
    let vaccines = JSON.parse(this.storage.getItem('vaccines'));
    vaccines = vaccines.filter(vaccine => vaccine.animalId !== animalId);
    this.storage.setItem('vaccines', JSON.stringify(vaccines));
    return true;
  }

  async removeAnimal(clientId, animalId) {
    let animals = JSON.parse(this.storage.getItem('animals'));
    const initialLength = animals.length;
    animals = animals.filter(animal => !(animal.id === animalId && animal.clientId === clientId));
    this.storage.setItem('animals', JSON.stringify(animals));
    return animals.length < initialLength;
  }

  async getNotes() {
    return JSON.parse(this.storage.getItem('notes'));
  }

  async addNote(noteData) {
    const id = this.generateUUID();
    const notes = JSON.parse(this.storage.getItem('notes'));
    notes.push({ ...noteData, id });
    this.storage.setItem('notes', JSON.stringify(notes));
    return id;
  }

  async removeNote(noteId) {
    let notes = JSON.parse(this.storage.getItem('notes'));
    const initialLength = notes.length;
    notes = notes.filter(note => note.id !== noteId);
    this.storage.setItem('notes', JSON.stringify(notes));
    return notes.length < initialLength;
  }

  async updateNote(noteId, updatedData) {
    const notes = JSON.parse(this.storage.getItem('notes'));
    const index = notes.findIndex(note => note.id === noteId);
    if (index !== -1) {
      notes[index] = { ...notes[index], ...updatedData };
      this.storage.setItem('notes', JSON.stringify(notes));
      return true;
    }
    return false;
  }

  async getVisits() {
    const visits = JSON.parse(this.storage.getItem('visits'));
    for (const visit of visits) {
      visit.medicines = await this.getVisitMedicines(visit.id);
      visit.vaccines = await this.getVisitVaccines(visit.id);
    }
    return visits;
  }

  async addVisit(clientId, petId, visitData) {
    const id = this.generateUUID();
    const visits = JSON.parse(this.storage.getItem('visits'));
    const newVisit = {
      id,
      clientId,
      petId,
      date: visitData.date || new Date().toISOString().split('T')[0],
      topic: visitData.topic,
      description: visitData.description,
      medicines: [],
      vaccines: []
    };

    if (visitData.medicines && Array.isArray(visitData.medicines)) {
      newVisit.medicines = visitData.medicines.map(medicine => ({
        id: this.generateUUID(),
        name: medicine.name,
        amount: medicine.amount
      }));
    }

    if (visitData.vaccines && Array.isArray(visitData.vaccines)) {
      for (const vaccine of visitData.vaccines) {
        if (vaccine.id) {
          const updatedVaccine = await this.updateAnimalVaccine(clientId, petId, vaccine.id, vaccine);
          newVisit.vaccines.push({
            id: this.generateUUID(),
            vaccineId: updatedVaccine.id,
            currentDosesAmount: updatedVaccine.currentDosesAmount,
            currentDosesCount: updatedVaccine.currentDosesCount
          });
        } else {
          const newVaccine = await this.addVaccineToAnimal(clientId, petId, vaccine);
          newVisit.vaccines.push({
            id: this.generateUUID(),
            vaccineId: newVaccine.id,
            currentDosesAmount: newVaccine.currentDosesAmount,
            currentDosesCount: newVaccine.currentDosesCount
          });
        }
      }
    }

    visits.push(newVisit);
    this.storage.setItem('visits', JSON.stringify(visits));
    return id;
  }

  async removeVisit(visitId) {
    let visits = JSON.parse(this.storage.getItem('visits'));
    const initialLength = visits.length;
    visits = visits.filter(visit => visit.id !== visitId);
    this.storage.setItem('visits', JSON.stringify(visits));
    return visits.length < initialLength;
  }

  async updateVisit(visitId, updatedData) {
    const visits = JSON.parse(this.storage.getItem('visits'));
    const index = visits.findIndex(visit => visit.id === visitId);
    if (index !== -1) {
      visits[index] = { ...visits[index], ...updatedData };

      if (updatedData.medicines && Array.isArray(updatedData.medicines)) {
        visits[index].medicines = updatedData.medicines.map(medicine => ({
          id: medicine.id || this.generateUUID(),
          name: medicine.name,
          amount: medicine.amount
        }));
      }

      this.storage.setItem('visits', JSON.stringify(visits));
      return true;
    }
    return false;
  }

  async updateClient(clientId, updatedData) {
    const clients = JSON.parse(this.storage.getItem('clients'));
    const index = clients.findIndex(client => client.id === clientId);
    if (index !== -1) {
      clients[index] = { ...clients[index], ...updatedData };
      this.storage.setItem('clients', JSON.stringify(clients));
      return true;
    }
    return false;
  }

  async updateAnimal(clientId, animalId, updatedData) {
    const animals = JSON.parse(this.storage.getItem('animals'));
    const index = animals.findIndex(animal => animal.id === animalId && animal.clientId === clientId);
    if (index !== -1) {
      animals[index] = { ...animals[index], ...updatedData };
      this.storage.setItem('animals', JSON.stringify(animals));
      return true;
    }
    return false;
  }

  async getVisitsForClient(clientId) {
    const visits = JSON.parse(this.storage.getItem('visits'));
    const clientVisits = visits.filter(visit => visit.clientId === clientId);
    for (const visit of clientVisits) {
      visit.medicines = await this.getVisitMedicines(visit.id);
      visit.vaccines = await this.getVisitVaccines(visit.id);
    }
    return clientVisits;
  }

  async getNotesForClient(clientId) {
    const notes = JSON.parse(this.storage.getItem('notes'));
    return notes
      .filter(note => note.ownerId === clientId)
      .map(note => ({
        id: note.id,
        date: note.date,
        timeStart: note.startTime,
        timeEnd: note.endTime,
        petId: note.petId,
        title: note.title,
        description: note.description,
        state: note.state,
        type: note.type
      }));
  }
  async getVaccineCyclesForPet(clientId, petId) {
    const animals = JSON.parse(this.storage.getItem('animals'));
    const animal = animals.find(a => a.id === petId && a.clientId === clientId);

    if (!animal) {
      return [];
    }

    const vaccines = JSON.parse(this.storage.getItem('vaccines'));
    const petVaccines = vaccines.filter(v => v.animalId === petId);

    const visits = JSON.parse(this.storage.getItem('visits'));

    const vaccineCycles = [];
    for (const vaccine of petVaccines) {
      const vaccineVisits = visits
        .filter(v => v.petId === petId)
        .flatMap(v => v.vaccines)
        .filter(vv => vv.vaccineId === vaccine.id)
        .map(vv => ({
          visitId: vv.id,
          date: visits.find(v => v.vaccines.some(vaccine => vaccine.id === vv.id)).date,
          amount: vv.currentDosesAmount
        }));

      vaccineCycles.push({
        ...vaccine,
        visits: vaccineVisits
      });
    }

    return vaccineCycles;
  }

  async getVisitsForPet(clientId, petId) {
    const visits = JSON.parse(this.storage.getItem('visits'));
    const petVisits = visits.filter(visit => visit.clientId === clientId && visit.petId === petId);
    for (const visit of petVisits) {
      visit.medicines = await this.getVisitMedicines(visit.id);
      visit.vaccines = await this.getVisitVaccines(visit.id);
    }
    return petVisits;
  }

  async getVisitMedicines(visitId) {
    const visits = JSON.parse(this.storage.getItem('visits'));
    const visit = visits.find(v => v.id === visitId);
    return visit ? visit.medicines : [];
  }

  async getVisitVaccines(visitId) {
    const visits = JSON.parse(this.storage.getItem('visits'));
    const visit = visits.find(v => v.id === visitId);
    if (!visit) return [];

    const vaccines = JSON.parse(this.storage.getItem('vaccines'));
    return visit.vaccines.map(vv => {
      const vaccine = vaccines.find(v => v.id === vv.vaccineId);
      return {
        ...vv,
        name: vaccine ? vaccine.name : 'Unknown'
      };
    });
  }
}

// Export an instance of BrowserStorage
const browserStorage = new BrowserStorage();

export const database = {
  initializeDefaultWorkingHours: () => browserStorage.initializeDefaultWorkingHours(),
  getWorkingHours: () => browserStorage.getWorkingHours(),
  updateWorkingHours: (newHours) => browserStorage.updateWorkingHours(newHours),
  getAllClients: () => browserStorage.getAllClients(),
  initializeDefaultClients: () => browserStorage.initializeDefaultClients(),
  getPets: (clientId) => browserStorage.getPets(clientId),
  addClient: (clientData) => browserStorage.addClient(clientData),
  removeClient: (clientId) => browserStorage.removeClient(clientId),
  addAnimal: (clientId, animalData) => browserStorage.addAnimal(clientId, animalData),
  getAnimalVaccines: (clientId, animalId) => browserStorage.getAnimalVaccines(clientId, animalId),
  addVaccineToAnimal: (clientId, animalId, vaccineData) => browserStorage.addVaccineToAnimal(clientId, animalId, vaccineData),
  updateAnimalVaccine: (clientId, animalId, vaccineId, updatedData) => browserStorage.updateAnimalVaccine(clientId, animalId, vaccineId, updatedData),
  deleteVaccinesForAnimal: (animalId) => browserStorage.deleteVaccinesForAnimal(animalId),
  removeAnimal: (clientId, animalId) => browserStorage.removeAnimal(clientId, animalId),
  getNotes: () => browserStorage.getNotes(),
  addNote: (noteData) => browserStorage.addNote(noteData),
  removeNote: (noteId) => browserStorage.removeNote(noteId),
  updateNote: (noteId, updatedData) => browserStorage.updateNote(noteId, updatedData),
  getVisits: () => browserStorage.getVisits(),
  addVisit: (clientId, petId, visitData) => browserStorage.addVisit(clientId, petId, visitData),
  removeVisit: (visitId) => browserStorage.removeVisit(visitId),
  updateVisit: (visitId, updatedData) => browserStorage.updateVisit(visitId, updatedData),
  updateClient: (clientId, updatedData) => browserStorage.updateClient(clientId, updatedData),
  updateAnimal: (clientId, animalId, updatedData) => browserStorage.updateAnimal(clientId, animalId, updatedData),
  getVisitsForClient: (clientId) => browserStorage.getVisitsForClient(clientId),
  getNotesForClient: (clientId) => browserStorage.getNotesForClient(clientId),
  getVaccineCyclesForPet: (clientId, petId) => browserStorage.getVaccineCyclesForPet(clientId, petId),
  getVisitsForPet: (clientId, petId) => browserStorage.getVisitsForPet(clientId, petId),
};

export const ipc = {
  async activateLicense(licenseKey) {
    // In a browser environment, you might want to implement this differently
    // For example, you could make an API call to a server to validate the license
    console.warn('License activation not implemented in browser version');
    return { success: false, message: 'License activation not available in browser version' };
  },

  async validateLicense() {
    // Similar to activateLicense, this would need to be implemented differently in a browser environment
    console.warn('License validation not implemented in browser version');
    return { success: false, message: 'License validation not available in browser version' };
  },

  async getAppVersion() {
    // In a browser environment, you might want to store the app version in a different way
    // For example, you could store it in localStorage or fetch it from a config file
    return '1.0.0'; // Replace with actual version retrieval logic
  }
};
