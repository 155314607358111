<template>
  <div :class="[`side-bar-top-bar`, className || ``]">
    <div class="overlap-group">
      <div class="frame-2610461">
        <div class="graphic"></div>
        <div class="version-info">
          <h1 class="title-wrapper">
            <p class="title">PawKeeper</p>
            <span class="version-text" >v{{ version }}</span>
          </h1>
        </div>
        <div class="title-1">
          <div
            class="title-text-frame "
          >
            {{ titleText }}
          </div>
        </div>
      </div>
      <header class="header">
        <img
          class="many-lines-but-free-2"
          src="../../../public/img/patterned-lines-1.png"
          alt="many lines but free 2"
        />
      </header>
    </div>
    <div class="frame-2610561">
      <div class="sidebar">
        <div class="list">
          <menu-item
            v-for="(item, index) in menuItems"
            :key="index"
            :text="item.text"
            :active="activeMenuItem === index + 1"
            :icon="item.icon"
            :link="item.link"
            @click.native="setActiveMenuItem(index + 1)"
          />
        </div>
        <img
          class="many-lines-but-free-2-1"
          src="../../../public/img/patterned-lines-2.png"
          alt="many lines but free 2"
        />
      </div>
    </div>
  </div>
</template>

<script>
import MenuItem from '../components/MenuItem.vue'
import { ipc } from '@/vue/ipc'
import { messages as locale } from '@/vue/locale'

export default {
  name: 'SideBarTopBar',
  components: {
    MenuItem,
  },
  data() {
    return {
      menuItems: [
        { text: locale.sideBarItemStatistics, icon: 'pie-chart.svg', link: 'statistics' },
        { text: locale.sideBarItemCalendar, icon: 'calendar-white.svg', link: 'calendar' },
        { text: locale.sideBarItemNewClient, icon: 'add-file.svg', link: 'register' },
        { text: locale.sideBarItemList, icon: 'bullet-list.svg', link: 'full-list' },
      ],
      version: '1.0.0',
    }
  },
  computed: {
    sideBarBarButtonsText() {
      return sideBarBarButtonsText
    },
    calendarphrases() {
      return calendarphrases
    }
  },
  props: {
    titleText: String,
    className: String,
    activeMenuItem: Number,
  },
  methods: {
    setActiveMenuItem(index) {
      this.$emit('update:activeMenuItem', index)
    },
    async fetchAppVersion() {
      try {
        this.version = await ipc.getAppVersion()
      } catch (error) {
        console.error('Failed to fetch app version:', error)
        this.version = 'Unknown'
      }
    },
  },
  mounted() {
    this.fetchAppVersion()
  },
}
</script>

<style>
.side-bar-top-bar {
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  gap: 39px;
  height: 95%;
  left: 0;
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 1;
}

.overlap-group {
  height: 63px;
  position: relative;
  width: 100%;
}

.frame-2610461 {
  align-items: center;
  background: linear-gradient(
    180deg,
    rgb(8, 97, 109) 0%,
    rgb(0, 130, 128) 100%
  );
  box-shadow: 2px 4px 6px #00000026;
  display: flex;
  gap: 10px;
  height: 63px;
  justify-content: center;
  left: 0;
  padding: 13px 30px;
  position: absolute;
  top: 0;
  width: 100%;
}

.graphic {
  background-image: url('../../../public/img/logo.svg');
  background-position: 50% 50%;
  background-size: cover;
  border-radius: 56px;
  height: 42px;
  position: relative;
  width: 42px;
}

.title-wrapper {
  margin-top: -2px;
  display: flex;
  flex-direction: column;
}

.title {
  font-family: 'Inter', sans-serif;
  font-weight: medium;
  font-size: 5px;
  color: white; 
}

.version-info {
  text-align: left;
}

.version-text {
  font-size: 12px;
  margin-top: -20px;
  color: rgba(255, 255, 255, 0.7);
  font-family: 'Inter', sans-serif;
}

.title-1 {
  align-items: center;
  display: inline-flex;
  flex: 1;
  flex-direction: column;
  gap: 8px;
  justify-content: center;
  position: relative;
}

.title-text-frame {
  color: var(--white);
  font-family: var(--font-family-poppins);
  font-size: 23px;
  font-style: normal;
  font-weight: 400;
  letter-spacing: -0.48px;
  line-height: 26.4px;
  margin-top: -1px;
  position: relative;
  text-align: center;
  width: 100%;
}

.header {
  align-items: flex-start;
  display: flex;
  flex-direction: row;
  height: 63px;
  justify-content: flex-end;
  left: 0;
  position: absolute;
  top: 0;
  width: 100%;
}

.many-lines-but-free-2 {
  height: 63px;
  width: 30%;
  max-width: 432px;
}

.frame-2610561 {
  align-items: flex-start;
  border-radius: 8px;
  box-shadow: 2px 4px 6px #00000026;
  display: flex;
  margin-left: 30px;
  overflow: hidden;
  width: 187px;
}

.sidebar {
  align-items: flex-start;
  background: linear-gradient(
    180deg,
    rgb(8, 97, 109) 0%,
    rgb(0, 130, 128) 100%
  );
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  gap: 275px;
  height: calc(100vh - 102px);
  width: 187px;
}

.list {
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  gap: 20px;
  margin-top: 26px;
  position: relative;
  width: 100%;
}

.many-lines-but-free-2-1 {
  height: 321px;
  width: 100%;
}


</style>