<template>
  <div class="pet">
    <registration-nav :active-index="2"/>
    <headline-title :text="messages.visit" />
    <visit-info-display
      v-if="isDataReady"
      @topic-updated="handleTopicUpdated"
      @visit-data-updated="handleVisitDataUpdated"
      :client-id="clientId"
      :pet-id="petId"
    />
    <p v-else>{{ messages.loading }}</p>
  </div>
</template>

<script>
import RegistrationNav from '/src/vue/pages/register/RegistrationNav.vue'
import VisitInfoDisplay from '/src/vue/pages/register/sections/visit/VisitInfoDisplay.vue'
import HeadlineTitle from '/src/vue/components/HeadlineTitle.vue'
import { messages } from '@/vue/locale'

export default {
  name: 'VisitRegistration',
  computed: {
    messages() {
      return messages
    }
  },
  props: {
    clientId: {
      required: true
    },
    petId: {
      required: true
    }
  },
  components: {
    HeadlineTitle,
    VisitInfoDisplay,
    RegistrationNav,
  },
  data() {
    return {
      topicFilled: false,
      visitData: null,
      isDataReady: false
    }
  },
  watch: {
    clientId: {
      immediate: true,
      handler(newVal) {
        this.checkDataReady()
      }
    },
    petId: {
      immediate: true,
      handler(newVal) {
        this.checkDataReady()
      }
    }
  },
  methods: {
    handleTopicUpdated(topic) {
      this.topicFilled = topic.length > 0
      this.$emit('visit-completed', this.topicFilled)
    },
    handleVisitDataUpdated(data) {
      this.visitData = data
      this.$emit('visit-data-updated', {
        ...data,
        topic: data.topic,
        description: data.description
      })
      // Also emit visit-completed event when visit data is updated
      this.$emit('visit-completed', data.topic && data.topic.length > 0)
    },
    checkDataReady() {
      this.isDataReady = !!this.clientId && !!this.petId
      console.log('Data ready:', this.isDataReady, 'ClientID:', this.clientId, 'PetID:', this.petId)
    }
  },
  mounted() {
    this.checkDataReady()
  }
}
</script>

<style scoped>
@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@400;500&display=swap');

.pet {
  background-color: var(--white);
  border-radius: 8px;
  box-shadow: 2px 4px 6px rgba(0, 0, 0, 0.15);
  display: flex;
  flex-direction: column;
  font-family: 'Poppins', sans-serif;
  gap: 15px;
  height: 520px;
  width: 100%;
  padding-bottom: 40px;
}

.header {
  border-bottom: 2px solid var(--bon-jour);
  font-size: 30px;
  padding-left: 40px;
  padding-bottom: 5px;
}

.search-section {
  display: flex;
  gap: 20px;
  justify-content: center;
  width: 100%;
}

.main-search-dropdown {
  width: 8600px;
}
</style>