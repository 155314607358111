<template>
  <form @submit.prevent="handleSubmit" class="add-visit-form">
    <div class="date-time-section">
      <h1 class="field-label">{{ messages.time }}</h1>
      <div class="date-time-row">
        <div class="time-fields">
          <DatePicker
            style="width: 130px"
            v-model="date"
            :available-dates="workingDates"
            outlined
          />
          <TimePicker
            v-if="isDataReady"
            v-model="startTime"
            :interval="parameters.calendarGridInterval"
            :available-times="availableStartTimes"
            :disabled="!date"
            outlined
          />
          <TimePicker
            v-if="isDataReady"
            v-model="endTime"
            :interval="parameters.calendarGridInterval"
            :available-times="availableEndTimes"
            :disabled="!startTime"
            outlined
          />
        </div>
      </div>
    </div>

    <div class="form-field">
      <div>
        <h1 class="field-label">{{ messages.client }}</h1>
        <ClientSearchDropdown
          v-model="selectedClientId"
          prepend-inner-icon="mdi-magnify"
          class="main-search-dropdown"
          type="default"
          outlined
          :disabled="!endTime"
          @client-selected="onClientSelected"
        />
      </div>
      <div style="margin-top: 60px">
        <h1 class="field-label">{{ messages.pet }}</h1>
        <PetSearchDropdown
          v-model="selectedPetId"
          :clientId="selectedClientId"
          prepend-inner-icon="mdi-magnify"
          class="main-search-dropdown"
          type="default"
          outlined
          :disabled="!selectedClientId"
          @pet-selected="onPetSelected"
        />
      </div>
      <div class="info-fields">
        <div>
          <h1 class="field-label">{{ messages.title }}</h1>
          <v-text-field
            v-model="title"
            dense
            outlined
            color="var(--mosque)"
            :disabled="!endTime"
          />
        </div>
        <div>
          <h1 class="field-label">{{ messages.description }}</h1>
          <TextArea
            v-model="notes"
            maxRows="10"
            outlined
          />
        </div>
      </div>
    </div>

    <div v-if="errorMessage" class="error-message">{{ errorMessage }}</div>

    <div class="button-section">
      <PopUpActionButton
        :text="messages.cancel"
        textColor="var(--camelot)"
        buttonType="outlined"
        @click.prevent="handleCancel"
      />
      <PopUpActionButton
        :text="messages.save"
        backgroundColor="var(--camelot)"
        textColor="white"
        @click.prevent="handleSubmit"
        :disabled="!isFormValid"
      />
    </div>
  </form>
</template>

<script>
import PopUpActionButton from '/src/vue/components/PopUpActionButton.vue'
import TextArea from '/src/vue/components/TextArea.vue'
import DatePicker from '/src/vue/components/DatePicker.vue'
import TimePicker from '/src/vue/components/TimePicker.vue'
import { database } from '/src/vue/ipc.js'
import utils from '/src/vue/utils'
import TextField from '/src/vue/components/TextField.vue'
import PetSearchDropdown from '@/vue/components/PetSearchDropdown.vue'
import ClientSearchDropdown from '@/vue/components/ClientSearchDropdown.vue'
import { messages } from '../../../locale'
import { parameters } from '@/vue/settings'

export default {
  name: 'AddVisit',
  components: {
    PetSearchDropdown,
    ClientSearchDropdown,
    DatePicker,
    TextArea,
    PopUpActionButton,
    TimePicker,
    TextField
  },
  props: {
    selectedSlot: {
      type: String,
      default: null
    },
    selectedDate: {
      type: Date,
      default: null
    }
  },
  data() {
    return {
      date: this.selectedDate ? this.selectedDate.toISOString().substr(0, 10) : new Date().toISOString().substr(0, 10),
      startTime: this.selectedSlot || '',
      endTime: this.selectedSlot ? utils.addMinutesToStringDate(this.selectedSlot, 10) : '',
      notes: '',
      title: '',
      selectedClientId: null,
      selectedPetId: null,
      workingHours: {},
      existingNotes: [],
      isDataReady: false,
      isSubmitting: false,
      errorMessage: ''
    }
  },
  computed: {
    parameters() {
      return parameters
    },
    messages() {
      return messages
    },
    workingDates() {
      const today = new Date();
      const nextMonth = new Date(today.getFullYear(), today.getMonth() + 1, 1);
      const dates = [];

      while (today < nextMonth) {
        const dayName = this.getDayName(today);
        if (this.workingHours[dayName] && this.workingHours[dayName].isOpen) {
          dates.push(today.toISOString().substr(0, 10));
        }
        today.setDate(today.getDate() + 1);
      }

      return dates;
    },
    availableStartTimes() {
      if (!this.date) return [];

      const dayName = this.getDayName(new Date(this.date));
      const { start, end } = this.workingHours[dayName] || { start: '09:00', end: '17:00' };

      const times = this.generateTimeSlots(start, end);
      return this.filterAvailableTimes(times);
    },
    availableEndTimes() {
      if (!this.startTime) return [];

      const dayName = this.getDayName(new Date(this.date));
      const { end } = this.workingHours[dayName];

      const times = this.generateTimeSlots(this.startTime, end);
      return this.filterAvailableEndTimes(times, this.startTime);
    },
    isFormValid() {
      return this.date && this.startTime && this.endTime && this.selectedClientId && this.selectedPetId && this.notes.trim() !== '' && this.title.trim() !== '';
    },
    isDataReady() {
      return this.isDataReady && Object.keys(this.workingHours).length > 0;
    }
  },
  methods: {
    handleCancel() {
      this.$emit('close');
    },
    async handleSubmit() {
      if (!this.isFormValid) return;

      const newVisit = {
        id: Date.now(),
        type: 'visit',
        state: 'new',
        date: this.date,
        startTime: this.startTime,
        endTime: this.endTime,
        title: this.title,
        description: this.notes,
        ownerId: this.selectedClientId,
        petId: this.selectedPetId,
        isNew: true,
      }

      try {
        await database.addNote(newVisit);
        this.$emit('noteAdded', newVisit);
        this.$emit('close');
      } catch (error) {
        console.error('Error adding visit:', error);
        this.errorMessage = 'An error occurred while adding the visit. Please try again.';
      }
    },
    onClientSelected(clientId) {
      this.selectedClientId = clientId;
      this.selectedPetId = null;
    },
    onPetSelected(petId) {
      this.selectedPetId = petId;
    },
    getDayName(date) {
      const days = Object.keys(messages.weekDays.full);
      const dayIndex = date.getDay();
      const adjustedIndex = (dayIndex + 6) % 7;
      return days[adjustedIndex];
    },
    generateTimeSlots(start, end) {
      const times = [];
      let current = start;

      while (current < end) {
        times.push(current);
        current = utils.addMinutesToStringDate(current, this.parameters.calendarGridInterval);
      }

      return times;
    },
    filterAvailableTimes(times, startFrom = '00:00') {
      return times.filter(time => {
        if (time < startFrom) return false;

        const conflictingNote = this.existingNotes.find(note => {
          return note.date === this.date &&
            time >= note.startTime &&
            time < note.endTime;
        });

        return !conflictingNote;
      });
    },
    filterAvailableEndTimes(times, startTime) {
      const nextConflictingNote = this.existingNotes
        .filter(note => note.date === this.date && note.startTime > startTime)
        .sort((a, b) => a.startTime.localeCompare(b.startTime))[0];

      return times.filter(time => {
        if (time <= startTime) return false;
        if (nextConflictingNote && time > nextConflictingNote.startTime) return false;

        const conflictingNote = this.existingNotes.find(note => {
          return note.date === this.date &&
            time > note.startTime &&
            time <= note.endTime;
        });

        return !conflictingNote;
      });
    },
    async loadData() {
      try {
        [this.workingHours, this.existingNotes] = await Promise.all([
          database.getWorkingHours(),
          database.getNotes()
        ]);
        this.isDataReady = true;
      } catch (error) {
        console.error('Error loading data:', error);
      }
    }
  },
  mounted() {
    this.loadData();
  }
}
</script>

<style scoped>
.add-visit-form {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.date-time-section {
  display: flex;
  flex-direction: column;
}

.date-time-row {
  display: flex;
  width: 100%;
}

.time-fields {
  display: flex;
  gap: 20px;
  flex: 2;
  justify-content: center;
}

.form-field {
  display: flex;
  flex-direction: column;
}

.info-fields {
  display: flex;
  flex-direction: column;
}

.button-section {
  margin: 0 10px;
  width: 97%;
  display: flex;
  justify-content: flex-end;
  gap: 10px;
}

.error-message {
  color: red;
  margin-bottom: 10px;
}
</style>