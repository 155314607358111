<template>
  <div class="statistics-frame">
    <v-main class="hero-1">
      <div v-if="loading" class="loading">
        Loading statistics...
      </div>
      <div v-else-if="error" class="error">
        {{ error }}
      </div>
      <div v-else class="stats-grid">
        <stat-card
          class="progress-card"
          :title="stats.completedWork.title"
          :displayValue="stats.completedWork.value"
          displayType="progress"
        />
        <stat-card
          class="planned-visits-card"
          :title="stats.plannedNotes.title"
          :displayValue="stats.plannedNotes.value"
        />
        <stat-card
          class="total-clients-card"
          :title="stats.totalClients.title"
          :displayValue="stats.totalClients.value"
        />
        <stat-card
          class="client-count-card"
          :title="stats.clientCount.title"
          :chartData="stats.clientCount.data"
          displayType="chart"
          :chartColors="['#963156']"
        />
        <stat-card
          class="workload-card"
          :title="stats.todayWorkload.title"
          :displayValue="stats.todayWorkload.value"
          displayType="circular"
        />
        <stat-card
          class="vaccinations-card"
          :title="stats.vaccinations.title"
          :chartData="stats.vaccinations.data"
          displayType="chart"
          :chartColors="['#08616D']"
        />
        <stat-card
          class="new-clients-card"
          :title="stats.newClientsWeekly.title"
          :displayValue="stats.newClientsWeekly.value"
        />
      </div>
    </v-main>
  </div>
</template>

<script>
import SideBarTopBar from '../../components/SideBar.vue'
import StatCard from '../../pages/statistics/StatCard.vue'
import { database } from '../../ipc.js'
import { messages } from '@/vue/locale'

export default {
  name: 'Statistics',
  data() {
    return {
      stats: null,
      loading: true,
      error: null,
      currentDate: new Date(),
    }
  },
  async created() {
    await this.loadData();
  },
  methods: {
    async loadData() {
      try {
        const workingHours = await database.getWorkingHours()
        const clients = await database.getAllClients()
        const visits = await database.getVisits()
        const notes = await database.getNotes()
        this.stats = this.calculateStats(clients, visits, notes, workingHours)
      } catch (err) {
        this.error = `Error loading statistics: ${err.message}`
      } finally {
        this.loading = false
      }
    },
    calculateStats(clients, visits, notes, workingHours) {
      const today = new Date();
      const todayString = today.toISOString().split('T')[0];
      const oneWeekAgo = new Date(today.getTime() - 7 * 24 * 60 * 60 * 1000);

      const currentDay = this.getCurrentDayName()
      workingHours = workingHours[currentDay];
      
      const todayNotes = Object.values(notes).filter(note => note.date === todayString);
      let todayWorkload = 0;

      if (workingHours.isOpen) {
        const clinicStartMinutes = this.timeToMinutes(workingHours.start);
        const clinicEndMinutes = this.timeToMinutes(workingHours.end);

        const totalAvailableMinutes = clinicEndMinutes - clinicStartMinutes;
        const totalAvailableSlots = totalAvailableMinutes / 10; // Total slots in the day
        console.log('totalAvailableSlots', totalAvailableSlots)


        let totalSlotsUsed = 0;
        todayNotes.forEach(note => {
          const noteStartMinutes = this.timeToMinutes(note.startTime);
          const noteEndMinutes = this.timeToMinutes(note.endTime);
          const noteDurationMinutes = noteEndMinutes - noteStartMinutes;
          totalSlotsUsed += Math.ceil(noteDurationMinutes / 10);
        });
        console.log('totalSlotsUsed', totalSlotsUsed)

        todayWorkload = Math.round(Math.min((totalSlotsUsed / totalAvailableSlots) * 100, 100));
      }







      // Other calculations remain the same
      const totalClients = Object.keys(clients).length;
      const completedNotes = todayNotes.filter(note => note.state === 'completed').length;
      const completedWork = todayNotes.length > 0 ? Math.round((completedNotes / todayNotes.length) * 100) : 0;
      console.log('completedNotes', completedNotes)
      console.log('todayNotes', todayNotes)
      console.log('completedWork', completedWork)
      // const plannedNotes = Object.values(notes).filter(note => note.date === todayString).length; //Planned for today
      const plannedNotes = Object.values(notes).filter(note => note.date >= todayString && note.type == 'visit').length; //If we showin ALL planned visits...
      const newClientsWeekly = Object.values(clients).filter(client => new Date(client.registrationDate) >= oneWeekAgo).length;

      const clientCountLast7Days = Array(7).fill(0).map((_, index) => {
        const date = new Date(today.getTime() - (6 - index) * 24 * 60 * 60 * 1000);
        return Object.values(clients).filter(client => new Date(client.registrationDate).toDateString() === date.toDateString()).length;
      });

      const vaccinationsLast7Days = Array(7).fill(0).map((_, index) => {
        const date = new Date(today.getTime() - (6 - index) * 24 * 60 * 60 * 1000);
        return Object.values(visits).filter(visit =>
          new Date(visit.date).toDateString() === date.toDateString() &&
          Array.isArray(visit.vaccines) && visit.vaccines.length > 0
        ).length;
      });

      return {
        completedWork: {
          title: messages.statisticsCompletedWork,
          value: completedWork
        },
        totalClients: {
          title: messages.statisticsTotalClientCount,
          value: totalClients
        },
        plannedNotes: {
          title: messages.statisticsPlannedVisitsCount,
          value: plannedNotes
        },
        clientCount: {
          title: messages.statisticsNewClientsTimed,
          data: clientCountLast7Days
        },
        vaccinations: {
          title: messages.statisticsVaccinationsTimedCount,
          data: vaccinationsLast7Days
        },
        todayWorkload: {
          title: messages.statisticsTodayWorkload,
          value: todayWorkload
        },
        newClientsWeekly: {
          title: messages.statisticsNewClientsTimed,
          value: newClientsWeekly
        }
      };
    },
    timeToMinutes(time) {
      const [hours, minutes] = time.split(':').map(Number);
      return hours * 60 + minutes;
    },
    getCurrentDayName() {
      const days = Object.keys(messages.weekDays.full)
      const dayIndex = this.currentDate.getDay()
      const adjustedIndex = (dayIndex + 6) % 7
      console.log('TODAY', days[adjustedIndex])
      return days[adjustedIndex];
    },
  },
  components: { StatCard, SideBarTopBar },
}
</script>

<style scoped>
.statistics-frame {
  align-items: flex-start;
  display: flex;
  /* position: relative; */
  /* width: 100%; */
  /* z-index: 10; */
  position: fixed;
  top: 98px; /* Keep the existing top margin */
  left: 240px; /* Adjust based on your sidebar width */
  right: 20px; /* Add some right margin */
  bottom: 5vh; /* Create a 5% gap at the bottom */
  z-index: 2;
}

.hero-1 {
  padding: 20px;
  /* margin-top: 78px; */
  z-index: 10;
}

.stats-grid {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-template-rows: auto auto auto;
  gap: 40px;
  width: 100%;
  margin: 0;
}

.progress-card {
  grid-column: 1 / span 2;
  grid-row: 1;
}

.planned-visits-card {
  grid-column: 3;
  grid-row: 1;
}

.total-clients-card {
  grid-column: 1;
  grid-row: 3;
}

.client-count-card {
  grid-column: 1;
  grid-row: 2;
}

.workload-card {
  grid-column: 2;
  grid-row: 2 / span 2;
}

.vaccinations-card {
  grid-column: 3;
  grid-row: 2;
}

.new-clients-card {
  grid-column: 3;
  grid-row: 3;
}
</style>