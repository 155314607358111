<template>
  <div class="x04-search-input">
    <v-text-field
      v-model="searchQuery"
      :label="label"
      prepend-inner-icon="mdi-magnify"
      single-line
      hide-details
      outlined
      dense
      color="var(--mosque)"
      @input="emitSearch"
    ></v-text-field>
  </div>
</template>

<script>
export default {
  name: 'TableSearchInput',
  props: {
    label: {
      type: String,
      default: 'Search',
    },
  },
  data() {
    return {
      searchQuery: '',
    }
  },
  methods: {
    emitSearch() {
      this.$emit('search', this.searchQuery)
    },
  },
}
</script>

<style scoped>
.x04-search-input {
  width: 100%;
}

::v-deep .v-text-field.v-text-field--outlined fieldset {
  opacity: 0.5; /* Adjust this value as needed */
}
</style>