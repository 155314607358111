<template>
  <div class="wrapper">
    <div class="field-container-name">
      <h1 class="field-label">{{ messages.vaccine }}</h1>
      <v-autocomplete
        v-model="selectedVaccineId"
        :items="vaccineCycleItems"
        item-text="name"
        item-value="id"
        @change="updateSelectedVaccine"
        :menu-props="{ maxHeight: '280px' }"
        color="var(--mosque)"
        outlined
        dense
        :no-data-text="noDataText"
      >
        <template v-slot:item="{ item }">
          <v-list-item-content>
            <v-list-item-title v-text="item.name"></v-list-item-title>
            <v-list-item-subtitle
              class="d-flex justify-space-between align-center mt-1"
            >
              <div>
                {{ messages.vaccineCurrentDose }}:
                {{ item.currentDosesAmount }}/{{ item.necessaryDosesAmount }} mL
              </div>
              <div>
                {{ messages.vaccineCurrentCount }}:
                {{ item.currentDosesCount }}/{{ item.necessaryDosesCount }}
              </div>
            </v-list-item-subtitle>
          </v-list-item-content>
        </template>
      </v-autocomplete>
    </div>
    <div class="input-row">
      <div class="field-container">
        <h1 class="field-label">{{ messages.vaccineAddedDose }}</h1>
        <TextField
          v-model.number="vaccineData.doseAmount"
          @input="emitUpdate"
          type="number"
          outlined
          :min="0"
          :max="vaccineData.necessaryDosesAmount - vaccineData.currentDosesAmount"
          step="0.1"
          dense
          unit="mL"
        >
        </TextField>
      </div>
      <div class="field-container">
        <h1 class="field-label">{{ messages.vaccineRequiredDose }}</h1>
        <TextField
          v-model.number="vaccineData.necessaryDosesAmount"
          @input="emitUpdate"
          type="number"
          outlined
          :min="vaccineData.currentDosesAmount"
          step="0.1"
          dense
          disabled
          unit="mL"
        >
        </TextField>
      </div>
    </div>
    <div class="input-row">
      <div class="field-container">
        <h1 class="field-label">{{ messages.vaccineCurrentCount }}</h1>
        <TextField
          v-model.number="vaccineData.currentDosesCount"
          @input="emitUpdate"
          type="number"
          outlined
          dense
          disabled
          :min="0"
          :max="vaccineData.necessaryDosesCount"
        />
      </div>
      <div class="field-container">
        <h1 class="field-label">{{ messages.vaccineRequiredCount }}</h1>
        <TextField
          v-model.number="vaccineData.necessaryDosesCount"
          @input="emitUpdate"
          type="number"
          outlined
          dense
          disabled
          :min="vaccineData.currentDosesCount"
        />
      </div>
    </div>
  </div>
</template>

<script>
import TextField from '/src/vue/components/TextField.vue'
import { database } from '/src/vue/ipc.js'
import { messages } from '../../../../locale'

export default {
  name: 'ExistingVaccineSelector',
  components: {
    TextField,
  },
  props: {
    value: {
      type: Object,
      required: true,
    },
    clientId: {
      type: String,
      required: true,
    },
    petId: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      selectedVaccineId: null,
      vaccineCycles: [],
      noDataText: 'Nav atrasts neviens vakcīnas cikls!',
      vaccineData: { ...this.value, doseAmount: 0 },
    }
  },
  computed: {
    messages() {
      return messages
    },
    vaccineCycleItems() {
      return this.vaccineCycles.map((cycle) => ({
        id: cycle.id,
        name: cycle.name,
        necessaryDosesAmount: cycle.necessaryDosesAmount,
        necessaryDosesCount: cycle.necessaryDosesCount,
        currentDosesCount: cycle.currentDosesCount,
        currentDosesAmount: cycle.currentDosesAmount,
      }))
    },
    selectedVaccine() {
      return (
        this.vaccineCycles.find(
          (cycle) => cycle.id === this.selectedVaccineId,
        ) || {}
      )
    },
  },
  methods: {
    emitUpdate() {
      this.$emit('input', { ...this.vaccineData })
    },
    updateSelectedVaccine() {
      if (this.selectedVaccineId) {
        const selectedVaccine = this.selectedVaccine
        console.log('selectedVaccine', selectedVaccine)
        this.vaccineData = {
          ...selectedVaccine,
          currentDosesAmount: selectedVaccine.currentDosesAmount,
          currentDosesCount: selectedVaccine.currentDosesCount + 1,
          doseAmount: 0
        }
      } else {
        this.resetVaccineData()
      }
      this.$emit('update:selectedVaccine', this.selectedVaccineId)
      this.emitUpdate()
    },
    resetVaccineData() {
      this.vaccineData = {
        id: null,
        name: '',
        necessaryDosesAmount: 0,
        currentDosesAmount: 0,
        necessaryDosesCount: 0,
        currentDosesCount: 0,
      }
    },
    async loadVaccineCycles() {
      this.vaccineCycles = await database.getVaccineCyclesForPet(
        this.clientId,
        this.petId,
      )
    },
    validateData() {
      this.vaccineData.necessaryDosesAmount =
        parseFloat(this.vaccineData.necessaryDosesAmount) || 0
      this.vaccineData.currentDosesAmount =
        parseFloat(this.vaccineData.currentDosesAmount) || 0
      this.vaccineData.necessaryDosesCount =
        parseInt(this.vaccineData.necessaryDosesCount) || 0
      this.vaccineData.currentDosesCount =
        parseInt(this.vaccineData.currentDosesCount) || 0
      this.emitUpdate()
    },
  },
  created() {
    this.loadVaccineCycles()
  },
  watch: {
    clientId() {
      this.loadVaccineCycles()
      this.resetVaccineData()
    },
    petId() {
      this.loadVaccineCycles()
      this.resetVaccineData()
    },
    value: {
      handler(newValue) {
        this.vaccineData = { ...newValue }
        this.validateData()
      },
      deep: true,
    },
  },
  mounted() {
    this.validateData()
  },
}
</script>


<style scoped>
.wrapper {
  display: flex;
  flex-direction: column;
  gap: 20px;
  padding: 20px;
  width: 450px;
}

.input-row {
  display: flex;
  gap: 15px;
}

.input-row > * {
  flex: 1;
}

.unit {
  margin-left: 5px;
  color: var(--elephant);
}

.field-container {
  display: flex;
  flex-direction: column;
}

.field-container-name {
  display: flex;
  flex-direction: column;
  margin-bottom: -22px;
}

.field-label {
  margin-bottom: 5px;
  font-size: 14px;
  color: var(--elephant);
}
</style>