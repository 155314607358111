<template>
  <modal-template @close="$emit('close')">
      <smaller-headline :text="messages.titleChangeWorkingTime" />
      <div class="common-frame">
        <div class="working-day-timelines">
          <TimeLine
            v-for="day in weekDays"
            :key="day"
            :workingDayIndex="day"
            :dayData="workingHours[day] || getDefaultDayData()"
            @update="updateWorkingHours"
          />
        </div>
        <div class="button-section">
          <pop-up-action-button
            v-if="edited"
            :text="messages.cancel"
            color="var(--camelot)"
            buttonType="outlined"
            @click="$emit('close')"
          />
          <pop-up-action-button
            v-if="edited"
            :text="messages.save"
            backgroundColor="var(--camelot)"
            textColor="white"
            @click="saveWorkingHours"
          />
          <pop-up-action-button
            v-if="!edited"
            :text="messages.close"
            backgroundColor="var(--camelot)"
            textColor="white"
            @click="$emit('close')"
          />
        </div>
      </div>
    </modal-template>
</template>

<script>
import PopUpActionButton from '/src/vue/components/PopUpActionButton.vue'
import TimeLine from '/src/vue/pages/calendar/work-time-pop-up/TimeLine.vue'
import SmallerHeadline from '/src/vue/components/SmallerHeadline.vue'
import { database } from '/src/vue/ipc'
import { messages } from '@/vue/locale'
import ModalTemplate from '@/vue/components/ModalTemplate.vue'

export default {
  name: 'WorkTimeOverlay',
  components: {
    ModalTemplate,
    TimeLine,
    PopUpActionButton,
    SmallerHeadline,
  },
  data() {
    return {
      workingHours: {},
      weekDays: Object.keys(messages.weekDays.full),
      edited: false
    }
  },
  computed: {
    messages() {
      return messages
    }
  },
  watch: {
    workingHours: {
      handler() {
        this.edited = true;
      },
      deep: true
    }
  },
  methods: {
    async initializeWorkingHours() {
      const hours = await database.getWorkingHours()
      this.workingHours = hours && Object.keys(hours).length
        ? hours
        : this.getDefaultWorkingHours()
      console.log('Initialized working hours:', this.workingHours) // Debug log
    },
    getDefaultWorkingHours() {
      const defaultHours = {}
      this.weekDays.forEach(day => {
        defaultHours[day] = this.getDefaultDayData()
      })
      return defaultHours
    },
    getDefaultDayData() {
      return { isOpen: false, start: '09:00', end: '17:00' }
    },
    updateWorkingHours(day, data) {
      this.$set(this.workingHours, day, data)
      this.edited = true
      console.log('Updated working hours:', this.workingHours) // Debug log
    },
    async saveWorkingHours() {
      await database.updateWorkingHours(this.workingHours)
      this.edited = false
      this.$emit('workingHoursUpdated', this.workingHours)
      this.$emit('close')
    },
  },
  async created() {
    await this.initializeWorkingHours()
  },
  emits: ['close', 'workingHoursUpdated'],
}
</script>

<style scoped>

.common-frame {
  padding: 20px;
  position: relative;
  justify-content: center;
  align-items: center;
  top: 0;
  left: 0;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  display: flex;
}

.common-frame {

  align-items: flex-start;
  align-self: stretch;
  display: flex;
  flex-direction: column;
  gap: 20px;
  position: relative;
  width: 100%;
}

.working-day-timelines {
  align-items: center;
  align-self: stretch;
  display: flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: 20px;
  justify-content: center;
  position: relative;
  width: 100%;
}

.button-section {
  display: flex;
  justify-content: flex-end;
  gap: 10px;
  width: 100%;
}

#overlay-work-time-pop-up {
  align-items: center;
  background-color: #00000040;
  justify-content: center;
}
</style>