import { render, staticRenderFns } from "./ActionMenuPopup.vue?vue&type=template&id=1023c346&scoped=true"
import script from "./ActionMenuPopup.vue?vue&type=script&lang=js"
export * from "./ActionMenuPopup.vue?vue&type=script&lang=js"
import style0 from "./ActionMenuPopup.vue?vue&type=style&index=0&id=1023c346&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "1023c346",
  null
  
)

export default component.exports