<template>
  <div class="status-bar">
    <div 
      class="status valign-text-middle default-style" 
      :class="currentStatus"
      @click.stop="cycleStatus"
    >
      {{ getStateName(state) }}
    </div>
  </div>
</template>

<script>
import { messages as locale } from '@/vue/locale'

export default {
  name: 'StatusBlock',
  props: {
    state: {
      type: String,
      default: 'new', // default state
      validator: (value) => ['canceled', 'new', 'in-process', 'completed'].includes(value)
    },
  },
  data() {
    return {
      statuses: ['canceled', 'new', 'in-process', 'completed'], // Array of statuses
    }
  },
  computed: {
    currentStatus() {
      return {
        'status-canceled-state': this.state === 'canceled',
        'status-new-state': this.state === 'new',
        'status-in-process-state': this.state === 'in-process',
        'status-completed-state': this.state === 'completed',
      }
    }
  },
  methods: {
    getStateName(state) {
      switch (state) {
        case "canceled": return locale.noteStatusCanceled;
        case "new": return locale.noteStatusNew;
        case "in-process": return locale.noteStatusInProcess;
        case "completed": return locale.noteStatusCompleted;
        default: return '';
      }
    },
    cycleStatus() {
      console.log('CHANGINGGGGGGGGGGGGGGGGGGGGGGGGGGGGGGGGGGGGGGG')
      const currentIndex = this.statuses.indexOf(this.state);
      const nextIndex = (currentIndex + 1) % this.statuses.length; // Move to the next index, cycle back if at the end
      this.$emit('update:state', this.statuses[nextIndex]); // Emit event to update state
    }
  }
}
</script>


<style scoped>
.status {
  color: var(--white);
  font-weight: 400;
  height: 27px;
  text-align: center;
  width: 95px;
  border-radius: 8px;
}

.status-canceled-state {
  background-color: #e5343e;
}

.status-new-state {
  background-color: var(--nobel-2);
}

.status-in-process-state {
  background-color: var(--mountain-meadow);
}

.status-completed-state {
  background-color: #4bb410;
}
</style>
