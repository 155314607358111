<template>
  <div class="wrapper">
    <div class="field-container">
      <h1 class="field-label">{{ messages.name }}</h1>
      <TextField
        v-model="vaccineData.name"
        @input="emitUpdate"
        outlined
        dense
      />
    </div>
    <div class="input-row">
      <div class="field-container">
        <h1 class="field-label">{{ isEditing ? messages.vaccineTotalDose : messages.vaccineAddedDose }}</h1>
        <TextField
          v-model.number="vaccineData.currentDosesAmount"
          @input="emitUpdate"
          type="number"
          outlined
          :min="0"
          step="0.1"
          dense
          unit="mL"
        >
        </TextField>
      </div>
      <div class="field-container">
        <h1 class="field-label">{{ messages.vaccineRequiredDose }}</h1>
        <TextField
          v-model.number="vaccineData.necessaryDosesAmount"
          @input="emitUpdate"
          type="number"
          outlined
          :min="0"
          step="0.1"
          dense
          unit="mL"
        >
        </TextField>
      </div>
    </div>
    <div class="input-row">
      <div class="field-container">
        <h1 class="field-label">{{ messages.vaccineRequiredCount }}</h1>
        <TextField
          v-model.number="vaccineData.necessaryDosesCount"
          @input="emitUpdate"
          type="number"
          outlined
          dense
          :min="0"
        />
      </div>
    </div>
  </div>
</template>

<script>
import TextField from '/src/vue/components/TextField.vue'
import { messages } from '../../../../locale'

export default {
  name: 'AddEditVaccine',
  computed: {
    messages() {
      return messages
    },
  },
  components: {
    TextField,
  },
  props: {
    value: {
      type: Object,
      required: true,
    },
    isEditing: {
      type: Boolean,
      default: false,
    }
  },
  data() {
    return {
      vaccineData: { ...this.value },
    }
  },
  methods: {
    emitUpdate() {
      this.$emit('input', { ...this.vaccineData })
    },
    validateData() {
      this.vaccineData.necessaryDosesAmount =
        parseFloat(this.vaccineData.necessaryDosesAmount) || 0
      this.vaccineData.currentDosesAmount =
        parseFloat(this.vaccineData.currentDosesAmount) || 0
      this.vaccineData.necessaryDosesCount =
        parseInt(this.vaccineData.necessaryDosesCount) || 0
      this.vaccineData.currentDosesCount =
        parseInt(this.vaccineData.currentDosesCount) || 0
      this.emitUpdate()
    },
  },
  watch: {
    value: {
      handler(newValue) {
        this.vaccineData = { ...newValue }
        this.validateData()
      },
      deep: true,
    },
  },
  mounted() {
    this.validateData()
  },
}
</script>

<style scoped>
.wrapper {
  display: flex;
  flex-direction: column;
  gap: 20px;
  padding: 20px;
  width: 450px;
}

.input-row {
  display: flex;
  gap: 15px;
}

.input-row > * {
  flex: 1;
}

.unit {
  margin-left: 5px;
  color: var(--elephant);
}

.field-container {
  display: flex;
  flex-direction: column;
}

.field-label {
  margin-bottom: 5px;
  font-size: 14px;
  color: var(--elephant);
}
</style>