<template>
  <div class="license-container" :class="{ 'fade-in': showContent }">
    <div class="license-card">
      <div class="text-center pa-6">
        <v-avatar size="80" class="mb-4">
          <img src="../../../public/img/logo.svg" alt="">
        </v-avatar>

        <h2 class="headline mb-2">{{locale.licenseCheck}}</h2>

        <p v-if="!isChecking" class="subtitle-1 mb-6">{{locale.licenseEnterCode}}</p>

        <p v-if="isChecking" class="subtitle-1 mb-6">{{locale.licenseCheckInProgress}}</p>
        <div v-if="isChecking" class="loading-container">
          <span class="loader"></span>
        </div>

        <div v-else class="license-input-container">
          <input
            v-model="formattedLicenseKey"
            class="license-input"
            placeholder="XXXX-XXXX-XXXX-XXXX"
            @input="formatLicenseKey"
            @paste="onPaste"
          >
        </div>

        <v-btn
          v-if="!isChecking"
          block
          color="var(--camelot)"
          dark
          large
          class="mt-4"
          @click="activateLicense"
          :loading="isActivating"
        >
          {{locale.licenseUnlockButton}}
          <v-icon right>mdi-arrow-right</v-icon>
        </v-btn>

        <v-btn
          v-if="!isChecking"
          text
          color="var(--camelot)"
          class="mt-4"
          @click="buyNow"
        >
          {{locale.licenseBuyButton}}
          <v-icon right>mdi-arrow-right</v-icon>
        </v-btn>

        <v-snackbar v-model="showError" color="error" :timeout="3000">
          {{ errorMessage }}
        </v-snackbar>
      </div>
    </div>
  </div>
</template>

<script>
import { ipc } from '../ipc';
import { messages as locale } from '../locale'

export default {
  name: 'LicenceKeyActivation',
  props: {
    isVisible: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      formattedLicenseKey: '',
      isActivating: false,
      isChecking: true,
      showError: false,
      errorMessage: '',
      showContent: false
    }
  },
  computed: {
    locale() {
      return locale
    },
    licenseKey() {
      return this.formattedLicenseKey.replace(/-/g, '');
    }
  },
  async created() {
    await this.checkLicense();
  },
  mounted() {
    setTimeout(() => {
      this.showContent = true;
    }, 50);
  },
  methods: {
    async checkLicense() {
      this.isChecking = true;
      try {
        const isValid = await ipc.validateLicense();
        if (isValid) {
          this.$emit('activated', true);
        }
      } catch (error) {
        this.showErrorMessage(locale.licenseUnableToCheck);
      } finally {
        this.isChecking = false;
      }
    },
    formatLicenseKey(event) {
      let value = event.target.value.replace(/[^A-Za-z0-9]/g, '').toUpperCase();
      let formattedValue = '';
      for (let i = 0; i < value.length && i < 16; i++) {
        if (i > 0 && i % 4 === 0) {
          formattedValue += '-';
        }
        formattedValue += value[i];
      }
      this.formattedLicenseKey = formattedValue;
    },
    onPaste(event) {
      event.preventDefault();
      const pastedText = event.clipboardData.getData('text');
      const sanitizedText = pastedText.replace(/[^A-Za-z0-9]/g, '').toUpperCase();
      this.formattedLicenseKey = '';
      this.$nextTick(() => {
        this.formatLicenseKey({ target: { value: sanitizedText.slice(0, 16) } });
      });
    },
    async activateLicense() {
      if (this.licenseKey.length === 16) {
        this.isActivating = true;
        try {
          const isValid = await ipc.activateLicense(this.licenseKey);
          if (isValid) {
            this.$emit('activated', true);
          } else {
            this.showErrorMessage(locale.licenseKeyInvalid);
          }
        } catch (error) {
          this.showErrorMessage(locale.licenseUnableToCheck);
        } finally {
          this.isActivating = false;
        }
      } else {
        this.showErrorMessage(locale.licensePleaseEnterCode);
      }
    },
    showErrorMessage(message) {
      this.errorMessage = message;
      this.showError = true;
    },
    buyNow() {
      window.open('https://pawkeeper.online', '_blank');
    },
  },
}
</script>

<style scoped>
.license-container {
  position: fixed;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  background: linear-gradient(rgba(8, 97, 109, 0.66), rgba(150, 49, 86, 0.4));
  z-index: 99;
  opacity: 0;
  transition: opacity 0.3s ease-in-out;
}

.license-container.fade-in {
  opacity: 1;
}

.license-card {
  background-color: white;
  border-radius: 8px;
  width: 100%;
  max-width: 400px;
}

.v-btn {
  text-transform: none;
}

.license-input-container {
  margin-bottom: 20px;
}

.license-input {
  width: 100%;
  height: 48px;
  border: 1px solid #ccc;
  border-radius: 4px;
  text-align: center;
  font-size: 18px;
  font-weight: 300;
  letter-spacing: 2px;
}

.license-input:focus {
  outline: none;
  border-color: var(--camelot);
  box-shadow: 0 0 0 2px rgba(var(--camelot), 0.2);
}

.loading-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 20px;
}

.loader {
  width: 48px;
  height: 48px;
  display: inline-block;
  position: relative;
}
.loader::after,
.loader::before {
  content: '';
  box-sizing: border-box;
  width: 48px;
  height: 48px;
  border-radius: 50%;
  background: var(--camelot);
  position: absolute;
  left: 0;
  top: 0;
  animation: animloader 2s linear infinite;
}

@keyframes animloader {
  0% {
    transform: scale(0);
    opacity: 1;
  }
  100% {
    transform: scale(1);
    opacity: 0;
  }
}
</style>