<template>
  <transition name="fade">
    <div v-if="show" class="action-menu-popup" :style="position" @mouseleave="$emit('close')">
      <div @click="$emit('edit')" class="menu-item">
        <i class="mdi mdi-pencil"></i>
        {{locale.edit}}
      </div>
      <div @click="$emit('delete')" class="menu-item">
        <i class="mdi mdi-delete"></i>
        {{locale.delete}}
      </div>
    </div>
  </transition>
</template>

<script>
import { messages as locale } from '../locale'

export default {
  name: 'ActionMenuPopup',
  computed: {
    locale() {
      return locale
    }
  },
  props: {
    show: {
      type: Boolean,
      required: true
    },
    position: {
      type: Object,
      required: true
    }
  }
}
</script>

<style scoped>
.action-menu-popup {
  position: fixed;
  background-color: white;
  border: 1px solid var(--bon-jour);
  border-radius: 8px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
  z-index: 10;
  width:fit-content;
}

.menu-item {
  padding: 8px 12px;
  width: 150px;
  cursor: pointer;
  display: flex;
  align-items: center;
  transition: background-color 0.3s ease;
}

.menu-item:hover {
  background-color: #f0f0f0;
}

.menu-item i {
  color: var(--mosque);
  font-size: 20px;
}

.fade-enter-active, .fade-leave-active {
  transition: opacity 0.3s ease;
}

.fade-enter, .fade-leave-to {
  opacity: 0;
}
</style>