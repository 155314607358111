<template>
  <div class="colorful-line-design" :style="lineStyle"  ></div>
</template>

<script>

export default {
  name: 'VerticalLine',
  props: {
    state: {
      type: String,
      default: '',
      validator: (value) => ['canceled', 'new', 'in-process', 'completed', ''].includes(value)
    },
    gridsTaken: {
      type: Number,
    }
  },
  computed: {
    lineStyle() {
      return {
        backgroundColor: this.state ? this.getStateColor(this.state) : 'transparent',
        height: (this.gridsTaken * 90) + 'px'
      };
    }
  },
  methods: {
    getStateColor(state) {
      switch (state) {
        case 'canceled': return "#ff0000";
        case 'completed': return "#7ebf00";
        case 'in-process': return "#029185";
        case 'new': return "#aaaaaa";
      }
    },
  }
}
</script>

<style scoped>
.colorful-line-design {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  width: 4px;
}
</style>