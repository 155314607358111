<template>
  <modal-template @close="$emit('close')">
      <smaller-headline :text="messages.addNewClient" />
      <form @submit.prevent="handleSubmit">
        <div>
          <h1 class="field-label">{{ messages.fullName }}</h1>
          <v-text-field
            v-model="form.fullName"
            dense
            outlined
            color="var(--mosque)"
          />
        </div>
        <div>
          <h1 class="field-label">{{ messages.address }}</h1>
          <v-text-field
            v-model="form.adrese"
            dense
            outlined
            color="var(--mosque)"
          />
        </div>
        <div>
          <h1 class="field-label">{{ messages.phoneNumber }}</h1>
          <phone-number-field
            v-model="form.telNumurs"
            class="number-field"
          />
        </div>
        <div>
          <h1 class="field-label">{{ messages.email }}</h1>
          <v-text-field
            v-model="form.epasts"
            placeholder="piemers@gmail.com"
            dense
            outlined
            color="var(--mosque)"
          />
        </div>
        <div>
          <h1 class="field-label">{{ messages.comments }}</h1>
          <v-textarea
            v-model="form.komentarijs"
            rows="5"
            auto-grow
            dense
            outlined
            color="var(--mosque)"
          />
        </div>

        <div class="button-section">
          <pop-up-action-button
            :text="messages.cancel"
            textColor="var(--camelot)"
            buttonType="outlined"
            @click="$emit('close')"
          />
          <pop-up-action-button
            :text="messages.save"
            backgroundColor="var(--camelot)"
            textColor="white"
            type="submit"
          />
        </div>
      </form>
  </modal-template>
</template>

<script>
import PopUpActionButton from '/src/vue/components/PopUpActionButton.vue'
import SmallerHeadline from '/src/vue/components/SmallerHeadline.vue'
import PhoneNumberField from '/src/vue/components/PhoneNumberField.vue'
import TextField from '/src/vue/components/TextField.vue'
import TextArea from '/src/vue/components/TextArea.vue'
import { database } from '/src/vue/ipc'
import { messages } from '@/vue/locale'
import ModalTemplate from '@/vue/components/ModalTemplate.vue'

export default {
  name: 'AddClientPopup',
  computed: {
    messages() {
      return messages
    },
  },
  components: {
    ModalTemplate,
    TextArea,
    TextField,
    PopUpActionButton,
    PhoneNumberField,
    SmallerHeadline,
  },
  data() {
    return {
      form: {
        fullName: '',
        adrese: '',
        telNumurs: '',
        epasts: '',
        komentarijs: '',
      },
    }
  },
  methods: {
    async handleSubmit() {
      const newClient = {
        fullName: this.form.fullName,
        address: this.form.adrese,
        phone: this.form.telNumurs,
        email: this.form.epasts,
        comment: this.form.komentarijs,
      }
      const newClientId = await database.addClient(newClient)
      this.$emit('client-added', newClientId)
      this.$emit('close')
    },
  },
}
</script>

<style scoped>
form {
  display: flex;
  flex-direction: column;
  /* gap: 20px; */
  padding: 20px;
  width: 400px;
}

.field-label {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 5px;
  cursor: pointer;
}

.number-field {
  margin-bottom: 20px;
}

.button-section {
  display: flex;
  justify-content: flex-end;
  gap: 20px;
}

::v-deep .v-text-field.v-text-field--outlined fieldset {
  opacity: 0.5;
}
</style>