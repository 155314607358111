<template>
  <v-card class="stat-card" :class="customClass" :style="cardStyle">
    <v-card-text>
      <h3 class="stat-title mb-4">{{ title }}</h3>
      <template v-if="displayType === 'progress'">
        <div class="progress-container">
          <div class="progress-value">{{ displayValue }}%</div>
          <v-progress-linear
            :value="displayValue"
            height="35"
            rounded
            background-color="#4caf5033"
            color="#1CA685"
          ></v-progress-linear>
        </div>
      </template>
      <template v-else-if="displayType === 'chart'">
        <v-sparkline
          :value="chartData"
          :gradient="chartColors"
          :smooth="16"
          padding="12"
          line-width="2"
          stroke-linecap="round"
          gradient-direction="top"
          auto-draw
          :height="chartHeight"
          show-labels
          label-size="12"
          auto-draw-easing="ease-in"
          color="rgba(0, 0, 0, 0.2)"
        ></v-sparkline>
      </template>

      <template v-else-if="displayType === 'circular'">
        <div class="text-center">
          <v-progress-circular
            :value="displayValue"
            :size="circularSize"
            width="35"
            color="#4CAF50"
            class="circular-progress"
          >

            <span class="circular-value">{{ displayValue }}%</span>
          </v-progress-circular>
        </div>
      </template>
      <template v-else>
        <div class="stat-number">{{ displayValue }}</div>
      </template>
    </v-card-text>
  </v-card>
</template>

<script>
export default {
  name: 'StatCard',
  props: {
    title: String,
    displayValue: [Number, String],
    displayType: {
      type: String,
      default: 'number'
    },
    chartData: Array,
    chartColors: Array,
    customClass: String,
    width: String,
    height: String,
    chartHeight: {
      type: [String, Number],
      default: 100
    },
    circularSize: {
      type: Number,
      default: 300
    }
  },
  computed: {
    cardStyle() {
      return {
        width: this.width || '100%',
        height: this.height || 'auto',
        borderRadius: '8px',
        boxShadow: '2px 4px 6px rgba(0, 0, 0, 0.15)'
      }
    }
  }
}
</script>

<style scoped>

.stat-title {
  margin-top: 15px;
  color: #545454;
  font-family: Inter,serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 150%;
  letter-spacing: -0.32px;
}


.stat-number {
  color: #1A3547;
  font-family: Inter,serif;
  font-size: 34px;
  font-style: normal;
  font-weight: 600;
  line-height: 150%; /* 51px */
  letter-spacing: -0.68px;
}

.circular-progress {
  margin-top: 16px;
}

.circular-value {
  color: #1A3547;
  text-align: center;
  font-family: Inter,serif;
  font-size: 34px;
  font-style: normal;
  font-weight: 400;
  line-height: 150%; /* 51px */
}

.progress-container {
  position: relative;
  padding-left: 100px;
}

.progress-value {
  font-family: Inter,serif;
  font-size: 34px;
  font-style: normal;
  font-weight: 600;
  line-height: 150%; /* 51px */
  position: absolute;
  left: 0;
  top: 50%;
  transform: translateY(-50%);
  color: #1A3547;
}

.v-progress-linear {
  margin-top: 0;
}
</style>