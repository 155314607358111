import { render, staticRenderFns } from "./ClientNameItem.vue?vue&type=template&id=f8a793da&scoped=true"
import script from "./ClientNameItem.vue?vue&type=script&lang=js"
export * from "./ClientNameItem.vue?vue&type=script&lang=js"
import style0 from "./ClientNameItem.vue?vue&type=style&index=0&id=f8a793da&prod&scoped=true&lang=css"
import style1 from "./ClientNameItem.vue?vue&type=style&index=1&id=f8a793da&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "f8a793da",
  null
  
)

export default component.exports