<template>
  <div class="full-list">
    <div class="content-wrapper">
      <div class="content-container">
        <template v-if="!selectedClient">
          <div class="header-group">
            <headline-title :text="messages.clientList" />
            <div class="header-content">
              <div class="search-sort-container">
                <TableSearchInput :label="messages.search" @search="handleSearch" />
                <sorting :label="messages.sort" @sort="handleSort" />
              </div>
            </div>
          </div>
          <div class="client-list-container">
            <div class="client-list">
              <div
                v-for="client in sortedAndFilteredClients"
                :key="client.id"
                class="client-item"
              >
                <div class="client-name">{{ client.fullName }}</div>
                <div class="client-phone">{{ client.phone }}</div>
                <div class="client-email">{{ client.email }}</div>
                <div class="client-more" @click="showClientInfo(client)">{{ messages.more }}</div>
              </div>
            </div>
          </div>
        </template>
        <client-info
          v-if="selectedClientId"
          :clientId="selectedClientId"
          @close="closeClientInfo"
        />
      </div>
      <div class="button-section" >
        <pop-up-action-button
          v-if="selectedClientId"
          :text="messages.deleteClient"
          backgroundColor="var(--camelot)"
          textColor="white"
          @click="HandleDeleteClient"
        />        
      </div>
    </div>
  </div>
</template>

<script>
import SideBarTopBar from '../../components/SideBar.vue'
import HeadlineTitle from '../../components/HeadlineTitle.vue'
import TableSearchInput from '../../components/TableSearchInput.vue'
import PopUpActionButton from '/src/vue/components/PopUpActionButton.vue'
import ClientInfo from "./ClientInfo.vue"
import Sorting from './Sorting.vue'
import { database } from '../../ipc.js'
import { messages } from '@/vue/locale'


export default {
  name: 'FullList',
  components: {
    SideBarTopBar,
    HeadlineTitle,
    TableSearchInput,
    Sorting,
    ClientInfo,
    PopUpActionButton
  },
  data() {
    return {
      clients: [],
      searchQuery: '',
      sortType: 'name',
      sortOrder: 'asc',
      selectedClient: null,
    }
  },
  created() {
    this.loadClients()
  },
  computed: {
    messages() {
      return messages
    },
    filteredClients() {
      if (!this.searchQuery) {
        return this.clients
      }
      const query = this.searchQuery.toLowerCase()
      return this.clients.filter(client =>
        client.fullName.toLowerCase().includes(query) ||
        client.phone.toLowerCase().includes(query) ||
        client.email.toLowerCase().includes(query)
      )
    },
    sortedAndFilteredClients() {
      return [...this.filteredClients].sort((a, b) => {
        let compareA, compareB;
        if (this.sortType === 'name') {
          compareA = a.fullName.toLowerCase();
          compareB = b.fullName.toLowerCase();
        } else if (this.sortType === 'date') {
          compareA = new Date(a.registrationDate);
          compareB = new Date(b.registrationDate);
        }

        if (this.sortOrder === 'asc') {
          return compareA > compareB ? 1 : -1;
        } else {
          return compareA < compareB ? 1 : -1;
        }
      })
    },
    selectedClientId() {
      return this.selectedClient ? this.selectedClient.id : null;
    }
  },
  methods: {
    async loadClients() {
      this.clients = await database.getAllClients()
    },
    handleSearch(query) {
      this.searchQuery = query
    },
    handleSort({ type, order }) {
      this.sortType = type;
      this.sortOrder = order;
    },
    showClientInfo(client) {
      this.selectedClient = client;
    },
    closeClientInfo() {
      this.selectedClient = null;
    },
    async deleteClient() {
      await database.removeClient(this.selectedClientId)
    },
    HandleDeleteClient() {
      if (confirm(this.messages.confirmDelete)) {
        this.deleteClient(this.selectedClientId)
        this.selectedClient = null
        this.loadClients()
      }
    },
  }
}
</script>

<style scoped>
.full-list {
  position: fixed;
  top: 98px; /* Keep the existing top margin */
  left: 240px; /* Adjust based on your sidebar width */
  right: 20px; /* Add some right margin */
  bottom: 5vh; /* Create a 5% gap at the bottom */
  /* box-shadow: 2px 4px 6px rgba(0, 0, 0, 0.15); */
  display: flex;
  flex-direction: column;
  z-index: 2;
}

.content-wrapper {
  flex: 1;
  display: flex;
  flex-direction: column;
  /* overflow: hidden; Prevent scrolling on this container */
}

.content-container {
  display: flex;
  flex-direction: column;
  background-color: var(--white);
  border-radius: 8px;
  box-shadow: 2px 4px 6px rgba(0, 0, 0, 0.15);
  max-height: calc(95vh - 98px); /* Adjust max height relative to viewport */
  overflow: hidden;
}

.button-section {
  margin-top: 20px;
}

.header-group {
  padding-top: 10px;
}

.header-content {
  display: flex;
  flex-direction: column;
  gap: 15px;
  padding: 20px 40px;
}

.search-sort-container {
  display: flex;
  gap: 15px;
  width: 100%;
}

.client-list-container {
  flex: 1;
  overflow-y: auto; /* Enable scrolling when content exceeds max height */
  padding: 0 40px 30px;
  max-height: inherit; /* Inherit max-height from the parent content-container */
}

.client-list-container::-webkit-scrollbar {
  width: 6px;
}

.client-list-container::-webkit-scrollbar-track {
  background: transparent;
}

.client-list-container::-webkit-scrollbar-thumb {
  background-color: var(--bon-jour);
  border-radius: 3px;
}

.client-list {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.client-item {
  display: flex;
  align-items: center;
  gap: 15px;
  border-bottom: 1px solid var(--bon-jour);
  padding: 10px 0;
  cursor: pointer;
}

.client-name,
.client-phone,
.client-email,
.client-more {
  color: var(--elephant);
  font-weight: 400;
  line-height: 24px;
  flex: 1;
}

.client-name {
  font-weight: 500;
  flex: 1;
}

.client-phone {
  flex: 1;
}

.client-email {
  flex: 1;
}

.client-more {
  color: var(--camelot);
  font-weight: 500;
  white-space: nowrap;
  text-align: right;
  flex: 0.2;
  padding-right: 30px;
}

</style>