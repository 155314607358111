<template>
  <div class="person-form">
    <transition name="fade-slide" mode="out-in">
      <div
        v-if="clientId && selectedClient"
        class="client-info"
        key="client-info"
      >
        <div class="info-column left-column">
          <div class="name-field">
            <h1 class="field-label">{{ messages.fullName }}</h1>
            <text-field
              :value="selectedClient.fullName"
              readonly
              class="name-field"
              outlined
              dense
            />
          </div>
          <div class="email-field">
            <h1 class="field-label">{{ messages.phoneNumber }}</h1>
            <phone-number-field
              :value="selectedClient.phone"
              readonly
              class="phone-field"
            />
          </div>
          <div class="email-field">
            <h1 class="field-label">{{ messages.email }}</h1>
            <text-field
              :value="selectedClient.email"
              readonly
              class="email-field"
              outlined
              dense
            />
          </div>
        </div>
        <div class="info-column">
          <div>
            <h1 class="field-label">{{ messages.address }}</h1>
            <text-field
              :value="selectedClient.address"
              readonly
              class="address-field"
              outlined
              dense
            />
          </div>
          <div>
            <h1 class="field-label">{{ messages.comments }}</h1>
            <text-area
              :value="selectedClient.comment"
              outlined
              readonly
              rows="6"
              class="comment-field"
              auto-grow
            />
          </div>
        </div>
      </div>
      <div v-else-if="loading" class="dzivnieks" key="loading">
        <p class="client-info-will-be-shown-here valign-text-middle small-text">
          {{ messages.loading }}
        </p>
      </div>
      <div v-else class="dzivnieks" key="placeholder">
        <p class="client-info-will-be-shown-here valign-text-middle small-text">
          {{ messages.informationAboutClientWillBeHere }}
        </p>
      </div>
    </transition>
  </div>
</template>

<script>
import TextField from '/src/vue/components/TextField.vue'
import TextArea from '/src/vue/components/TextArea.vue'
import PhoneNumberField from '/src/vue/components/PhoneNumberField.vue'
import ClickyButton from '/src/vue/components/ClickyButton.vue'
import { database } from '/src/vue/ipc.js'
import { messages } from '../../../../locale'

export default {
  name: 'ClientInfoDisplay',
  computed: {
    messages() {
      return messages
    },
  },
  components: {
    TextField,
    TextArea,
    PhoneNumberField,
    ClickyButton,
  },
  data() {
    return {
      selectedClient: null,
      loading: false,
    }
  },
  props: {
    clientId: {
      type: String,
      default: null,
    },
  },
  watch: {
    clientId: {
      handler() {
        this.fetchClientData()
      },
      immediate: true,
    },
  },
  methods: {
    async fetchClientData() {
      if (!this.clientId) {
        this.selectedClient = null
        return
      }

      this.loading = true
      try {
        const clients = await database.getAllClients()
        this.selectedClient = clients.find(
          (client) => client.id === this.clientId,
        )
        if (!this.selectedClient) {
          console.error(`Client with id ${this.clientId} not found`)
        }
      } catch (error) {
        console.error('Error fetching client data:', error)
        this.selectedClient = null
      } finally {
        this.loading = false
      }
    },
  },
}
</script>

<style scoped>
.person-form {
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 20px 40px 0 40px;
}

.client-info {
  display: flex;
  height: 300px;
  width: 100%;
  justify-content: space-between;
}

.info-column {
  display: flex;
  flex-direction: column;
  gap: 10px;
  width: 45%;
}

.left-column {
  align-items: flex-start;
}

.right-column {
  align-items: flex-end;
}

.dzivnieks {
  position: relative;
  width: 100%;
  height: 300px;
}

.client-info-will-be-shown-here {
  position: absolute;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #d9d9d9;
  font-weight: 500;
  text-align: center;
}

.phone-field {
  margin-bottom: -19px;
}

.email-field,
.name-field,
.address-field,
.comment-field {
  width: 100%;
}

.field-label {
  margin-bottom: 5px;
  font-size: 14px;
  color: var(--elephant);
}

/* Animation styles */
.fade-slide-enter-active,
.fade-slide-leave-active {
  transition: all 0.2s ease;
}

.fade-slide-enter-from,
.fade-slide-leave-to {
  opacity: 0;
  transform: translateY(20px);
}

.fade-slide-enter-to,
.fade-slide-leave-from {
  opacity: 1;
  transform: translateY(0);
}

/* Add staggered animation for child elements */
.client-info > * {
  opacity: 0;
  transform: translateY(2px);
  animation: fadeSlideIn 0.2s ease forwards;
}

.client-info > *:nth-child(1) {
  animation-delay: 0.1s;
}
.client-info > *:nth-child(2) {
  animation-delay: 0.1s;
}

@keyframes fadeSlideIn {
  to {
    opacity: 1;
    transform: translateY(0);
  }
}
</style>