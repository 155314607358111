<template>
  <div class="client-name-item">
    <v-text-field
      style="font-size: 20px"
      :value="localValue"
      @input="updateValue"
      :readonly="!isLocalEditing"
      dense
      hide-details
      class="inline-edit full-name"
      :class="{ 'edit-mode': isLocalEditing, 'view-mode': !isLocalEditing }"
      :color="isLocalEditing ? 'var(--mosque)': 'transparent'"
    >
      <template v-slot:append>
        <v-icon
          v-if="!isLocalEditing"
          small
          @click="startEditing"
          class="action-icon edit-btn"
        >
          mdi-pencil
        </v-icon>
        <template v-if="isLocalEditing">
          <v-icon
            small
            @click="cancelEditing"
            class="action-icon cancel-icon"
          >
            mdi-close
          </v-icon>
          <v-icon
            small
            @click="saveChanges"
            class="action-icon save-icon"
          >
            mdi-content-save
          </v-icon>
        </template>
      </template>
    </v-text-field>
  </div>
</template>

<script>
export default {
  name: 'ClientNameItem',
  props: {
    value: String,
    isEditing: Boolean,
  },
  data() {
    return {
      localValue: this.value,
      isLocalEditing: false
    }
  },
  watch: {
    value(newValue) {
      if (!this.isLocalEditing) {
        this.localValue = newValue;
      }
    },
    isEditing(newValue) {
      this.isLocalEditing = newValue;
    }
  },
  methods: {
    updateValue(newValue) {
      if (this.isLocalEditing) {
        this.localValue = newValue;
      }
    },
    startEditing() {
      this.isLocalEditing = true;
      this.$emit('edit');
    },
    cancelEditing() {
      this.isLocalEditing = false;
      this.localValue = this.value;
      this.$emit('cancel');
    },
    saveChanges() {
      this.isLocalEditing = false;
      this.$emit('save', {
        field: 'fullName',
        value: this.localValue
      });
    }
  }
}
</script>

<style scoped>
/* Styles remain unchanged */
</style>

<style scoped>
.client-name-item {
  display: flex;
  align-items: center;
  width: 200px;
}

.full-name {
  font-size: 20px;
  color: black;
}

.inline-edit {
  font-size: 1.1em;
  margin-bottom: 8px;
  margin-top: 10px;
}

.inline-edit :deep(.v-input__slot) {
  min-height: 24px !important;
  padding: 0 !important;
}

.inline-edit :deep(.v-text-field__slot input) {
  padding: 0;
}

/* Hide Vuetify's default underline when not editing, including on focus */
.inline-edit.view-mode :deep(.v-input__slot::before),
.inline-edit.view-mode :deep(.v-input__slot::after) {
  border-color: transparent !important;
}


.inline-edit :deep(.v-input__append-inner) {
  margin-top: 2px !important;
}

.action-icon {
  opacity: 0;
  transition: opacity 0.3s ease;
}

.client-name-item:hover .action-icon {
  opacity: 1;
}

.edit-btn,
.cancel-icon,
.save-icon {
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 2px;
  margin: 2px;
  border-radius: 30px;
}

.cancel-icon:hover,
.save-icon:hover {
  background-color: var(--bon-jour);
  margin: 0;
  padding: 4px;
}
</style>