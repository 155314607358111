<template>
  <div class="time-picker">
    <v-select
      v-model="localSelectedTime"
      :items="availableTimes"
      color="var(--mosque)"
      prepend-icon="mdi-clock-outline"
      class="time-select"
      :menu-props="{ auto: true}"
      dense
      :disabled="disabled"
      v-bind="$attrs"
      @input="onTimeChange"
    >
      <template v-slot:prepend>
        <v-icon size="24">mdi-clock-outline</v-icon>
      </template>
    </v-select>
  </div>
</template>

<script>
export default {
  name: 'TimePicker',
  inheritAttrs: false,
  props: {
    value: {
      type: String,
      default: ''
    },
    interval: {
      type: Number,
      default: 30,
      validator: (value) => value > 0 && value <= 60 && 60 % value === 0
    },
    availableTimes: {
      type: Array,
      default: () => []
    },
    disabled: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      localSelectedTime: this.value
    };
  },
  watch: {
    value(newValue) {
      this.localSelectedTime = newValue;
    },
    availableTimes: {
      handler(newTimes) {
        if (newTimes.length > 0 && !newTimes.includes(this.localSelectedTime)) {
          this.localSelectedTime = '';
          this.$emit('input', this.localSelectedTime);
        }
      },
      immediate: true
    }
  },
  methods: {
    onTimeChange(time) {
      this.$emit('input', time);
    }
  }
};
</script>

<style scoped>
.time-picker {
  display: flex;
  align-items: center;
  justify-content: center;
}

.time-select {
  width: 130px;
  height: 45px;
  color: var(--elephant);
  font-weight: 400;
  background-color: white;
}

::v-deep .v-text-field.v-text-field--outlined fieldset {
  opacity: 0.5;
}

::v-deep .v-icon {
  transition: transform 0.3s ease;
}

::v-deep .v-select:hover .v-icon {
  transform: scale(1.2);
}
</style>