<template>
  <div class="register">
    <div class="frame-container" :class="{ 'fade-out': isAnimating }">
      <client-registration
        v-if="registrationStep === 'client'"
        @next="goToNextStep"
        @client-selected="setSelectedClientId"
      />
      <pet-registration
        v-else-if="registrationStep === 'pet'"
        :clientId="selectedClientId"
        @next="goToNextStep"
        @pet-selected="setSelectedPet"
      />
      <visit-registration
        v-else-if="registrationStep === 'visit'"
        :clientId="selectedClientId"
        :petId="selectedPetId"
        @next="goToNextStep"
        @visit-completed="setVisitCompleted"
        @visit-data-updated="updateVisitData"
      />
      <clicky-button
        v-if="
        (registrationStep === 'client' && selectedClientId) ||
        (registrationStep === 'pet' && selectedPet) ||
        (registrationStep === 'visit' && isVisitCompleted)
        "
        :text="buttonText"
        buttonClass="primary"
        @click="handleButtonClick"
        class="create-button"
      />
    </div>
  </div>
</template>

<script>
import ClientRegistration from '/src/vue/pages/register/sections/client/ClientRegistration.vue'
import PetRegistration from '/src/vue/pages/register/sections/pet/PetRegistration.vue'
import ClickyButton from '/src/vue/components/ClickyButton.vue'
import VisitRegistration from '/src/vue/pages/register/sections/visit/VisitRegistration.vue'
import { database } from '/src/vue/ipc'
import confetti from 'canvas-confetti'
import { messages as locale } from '@/vue/locale'

export default {
  name: 'Register',
  components: {
    ClientRegistration,
    PetRegistration,
    ClickyButton,
    VisitRegistration,
  },
  data() {
    return {
      registrationStep: 'client',
      selectedClientId: null,
      selectedPet: null,
      isVisitCompleted: false,
      visitData: null,
      isAnimating: false,
    }
  },
  computed: {
    buttonText() {
      return this.registrationStep === 'visit' && this.isVisitCompleted ? locale.create : locale.next
    },
    selectedPetId() {
      return this.selectedPet
    }
  },
  methods: {
    handleButtonClick() {
      if (this.registrationStep === 'visit' && this.isVisitCompleted) {
        this.saveVisit()
      } else {
        this.goToNextStep()
      }
    },
    goToNextStep() {
      if (this.registrationStep === 'client' && this.selectedClientId) {
        this.registrationStep = 'pet'
      } else if (this.registrationStep === 'pet' && this.selectedPet) {
        this.registrationStep = 'visit'
      }
    },
    setSelectedClientId(id) {
      this.selectedClientId = id
      console.log('Selected Client ID:', id)
    },
    setSelectedPet(pet) {
      this.selectedPet = pet;
      this.selectedPetId = pet ? pet.id : null;
      console.log('Selected Pet:', JSON.stringify(this.selectedPet, null, 2));
      console.log('Selected Pet ID:', this.selectedPetId);
    },
    setVisitCompleted(status) {
      this.isVisitCompleted = status
    },
    updateVisitData(data) {
      this.visitData = data
      console.log('Updated Visit Data:', JSON.stringify(data, null, 2))
    },
    async saveVisit() {
      console.log('Saving visit...');
      console.log('Selected Client ID:', this.selectedClientId);
      console.log('Selected Pet ID:', this.selectedPetId);
      console.log('Visit Data:', JSON.stringify(this.visitData, null, 2));

      if (this.selectedClientId && this.selectedPetId && this.visitData) {
        console.log('All required data is present. Attempting to add visit...');
        try {
          console.log('this.visitData', this.visitData)
          const visitId = await database.addVisit(this.selectedClientId, this.selectedPetId, this.visitData);
          if (visitId) {
            console.log('Visit saved successfully with ID:', visitId);
            this.animateAndClearCache();
          } else {
            console.error('Failed to save visit. Returned visitId is null.');
            this.showErrorMessage('Failed to save visit. Please try again.');
          }
        } catch (error) {
          console.error('Error saving visit:', error);
          this.showErrorMessage(`An error occurred while saving the visit: ${error.message}`);
        }
      } else {
        console.error('Missing required data for saving visit:');
        console.error('Selected Client ID:', this.selectedClientId);
        console.error('Selected Pet ID:', this.selectedPetId);
        console.error('Visit Data:', this.visitData);
        this.showErrorMessage('Missing required information. Please ensure all fields are filled.');
      }
    },
    animateAndClearCache() {
      this.isAnimating = true;
      setTimeout(() => {
        this.clearCacheAndNavigate();
        this.isAnimating = false;
      }, 500); // Match this with the CSS transition duration
    },
    clearCacheAndNavigate() {
      // Clear all cache data
      this.selectedClientId = null;
      this.selectedPet = null;
      this.isVisitCompleted = false;
      this.visitData = null;

      // Reset to the first navigation page
      this.registrationStep = 'client';

      if (Math.random() < 0.005) {
        this.triggerFireworks();
      }
    },
    triggerFireworks() {
      const duration = 5 * 1000;
      const animationEnd = Date.now() + duration;
      const defaults = { startVelocity: 30, spread: 360, ticks: 60, zIndex: 0 };

      function randomInRange(min, max) {
        return Math.random() * (max - min) + min;
      }

      const interval = setInterval(function() {
        const timeLeft = animationEnd - Date.now();

        if (timeLeft <= 0) {
          return clearInterval(interval);
        }

        const particleCount = 50 * (timeLeft / duration);

        confetti(Object.assign({}, defaults, {
          particleCount,
          origin: { x: randomInRange(0.1, 0.3), y: Math.random() - 0.2 }
        }));
        confetti(Object.assign({}, defaults, {
          particleCount,
          origin: { x: randomInRange(0.7, 0.9), y: Math.random() - 0.2 }
        }));
      }, 250);
    },
    showErrorMessage(message) {
      // Implement this method to show error messages to the user
      // For example, you could use a toast notification or an alert
      console.error(message);
      // TODO: Add user-facing error notification
    }
  }
}
</script>

<style scoped>
.register {
  /* margin-top: 78px; */
  display: flex;
  flex-direction: column;
  /* position: relative; */
  position: fixed;
  top: 98px; /* Keep the existing top margin */
  left: 240px; /* Adjust based on your sidebar width */
  right: 20px; /* Add some right margin */
  z-index: 2;
}

.frame-container {
  flex: 1;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  transition: opacity 0.5s ease;
}

.frame-container.fade-out {
  opacity: 0;
}

.create-button {
  align-self: flex-start;
  margin-top: 20px;
  width: 15%;
  height: 40px;
  margin-left: 0;
  margin-right: 0;
}
</style>