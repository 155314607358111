<!-- DatePicker.vue -->
<template>
  <div class="date-picker" :class="{ 'date-picker--outlined': outlined }">
    <v-menu
      v-model="menu"
      :close-on-content-click="false"
      :nudge-right="40"
      transition="scale-transition"
      offset-y
      min-width="auto"
    >
      <template v-slot:activator="{ on, attrs }">
        <v-text-field
          :value="formattedDate"
          readonly
          v-bind="attrs"
          v-on="on"
          :outlined="outlined"
          :dense="dense"
          color="var(--mosque)"
          :class="{ 'date-input': !outlined }"
        >
          <template v-slot:prepend-inner>
            <v-icon>mdi-calendar</v-icon>
          </template>
        </v-text-field>
      </template>
      <v-date-picker
        v-model="selectedDate"
        @input="onDateSelected"
        :allowed-dates="allowedDates"
        color="var(--mosque)"
      ></v-date-picker>
    </v-menu>
  </div>
</template>

<script>
import { database } from '../ipc'

export default {
  name: 'DatePicker',
  props: {
    value: {
      type: String,
      default: ''
    },
    availableDates: {
      type: Array,
      default: () => []
    },
    isBirthday: {
      type: Boolean,
      default: false
    },
    outlined: {
      type: Boolean,
      default: false
    },
    dense: {
      type: Boolean,
      default: true
    },
    color: {
      type: String,
      default: 'primary'
    }
  },
  data: () => ({
    menu: false,
    workingHours: {},
    selectedDate: ''
  }),
  computed: {
    formattedDate() {
      return this.selectedDate || this.value;
    }
  },
  created() {
    this.workingHours = database.getWorkingHours();
    this.selectedDate = this.value || new Date().toISOString().substr(0, 10);
  },
  methods: {
    allowedDates(val) {
      if (this.isBirthday) {
          const currentDate = new Date();
        currentDate.setHours(23, 59, 59, 999); // Set to end of the day
        const selectedDate = new Date(val);
        return selectedDate <= currentDate;
      }
      
      if (this.availableDates.length > 0) {
        return this.availableDates.includes(val);
      }
      
      const date = new Date(val);
      const day = date.toLocaleDateString('en-US', { weekday: 'long' }).toLowerCase();
      return this.workingHours[day] && this.workingHours[day].isOpen;
    },
    onDateSelected() {
      this.menu = false;
      this.$emit('input', this.selectedDate);
    },
  },
  watch: {
    value(newVal) {
      this.selectedDate = newVal;
    },
    selectedDate(newVal) {
      this.$emit('input', newVal);
    }
  }
}
</script>

<style scoped>
.date-picker {
  width: 100%;
}

.date-picker :deep(.v-input__prepend-inner) {
  margin-top: 6px;
}

.date-picker :deep(.v-icon) {
  font-size: 20px;
}

.date-picker--outlined :deep(.v-input__slot) {
  min-height: 40px;
}

.date-picker--outlined :deep(.v-text-field__details) {
  display: none;
}

.date-input :deep(.v-input__slot) {
  border-bottom: 1px solid rgba(0, 0, 0, 0.42);
}

.date-input :deep(.v-input__slot:before) {
  border-color: transparent;
}

.date-input :deep(.v-input__slot:after) {
  border-color: var(--mosque);
}
</style>