<template>
  <div class="text-area">
    <v-textarea
      v-model="textareaValue"
      :label="label"
      color="var(--mosque)"
      v-bind="$attrs"
      @input="$emit('input', textareaValue)"
    ></v-textarea>
  </div>
</template>

<script>
export default {
  name: 'TextArea',
  inheritAttrs: false,
  props: {
    value: {
      type: String,
      default: ''
    },
    label: {
      default: ''
    }
  },
  data() {
    return {
      textareaValue: this.value
    }
  },
  watch: {
    value(newValue) {
      this.textareaValue = newValue
    }
  }
}
</script>

<style scoped>
::v-deep .v-input textarea {
  font-family: 'Inter', sans-serif;
  font-size: 14px;
}

::v-deep .v-label {
  font-family: 'Inter', sans-serif;
  font-size: 14px;
}
::v-deep .v-text-field.v-text-field--outlined fieldset {
  opacity: 0.5; /* Adjust this value as needed */
}
</style>