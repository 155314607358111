<template>
  <modal-template @close="$emit('close')">
    <div class="add-calendar-item">
      <div class="toggle-container">
        <div class="toggle-container-buttons">
          <button
            @click="setActiveComponent('AddNote')"
            :class="{ active: activeComponent === 'AddNote' }"
          >
            {{ messages.note }}
          </button>
          <button
            @click="setActiveComponent('AddVisit')"
            :class="{ active: activeComponent === 'AddVisit' }"
          >
            {{ messages.appointment }}
          </button>
        </div>
      </div>
      <transition
        name="fade-slide"
        mode="out-in"
      >
        <component
          :key="activeComponent"
          :is="activeComponent"
          @close="$emit('close')"
          @noteAdded="onNoteAdded"
          :selectedSlot="selectedSlot"
          :selectedDate="selectedDate"
        ></component>
      </transition>
    </div>
  </modal-template>
</template>

<script>
import AddNote from '/src/vue/pages/calendar/add-calendar-overlay/AddNote.vue'
import AddVisit from '/src/vue/pages/calendar/add-calendar-overlay/AddVisit.vue'
import { messages } from '@/vue/locale'
import ModalTemplate from '@/vue/components/ModalTemplate.vue'

export default {
  name: 'AddCalendarItem',
  computed: {
    messages() {
      return messages
    },
  },
  components: {
    ModalTemplate,
    AddNote,
    AddVisit
  },
  props: {
    selectedSlot: {
      type: String,
      default: null
    },
    selectedDate: {
      type: Date,
      required: true
    }
  },
  data() {
    return {
      activeComponent: 'AddVisit'
    }
  },
  methods: {
    onNoteAdded(newNote) {
      this.$emit('noteAdded', newNote)
    },
    setActiveComponent(component) {
      this.activeComponent = component
    }
  },
  emits: ['close', 'noteAdded']
}
</script>

<style scoped>
.add-calendar-item {
  display: flex;
  flex-direction: column;
  height: 100%;
  padding: 20px;
}

.toggle-container {
  display: flex;
  justify-content: center;
  padding-bottom: 20px ;
}

.toggle-container-buttons {
  background-color: #f0f0f0;
  gap: 10px;
  justify-content: center;
  display: flex;
  padding: 5px;
  border-radius: 6px;
}

.toggle-container button {
  padding: 3px 25px;
  border: none;
  background-color: #f0f0f0;
  cursor: pointer;
  font-size: 16px;
  transition: background-color 0.3s;
}

.toggle-container button.active {
  background-color: #8B1F41;
  border-radius: 4px;
  color: white;
}

Transition styles
.fade-slide-enter-active,
.fade-slide-leave-active {
  transition: all 0.5s ease;
}

.fade-slide-enter-from,
.fade-slide-leave-to {
  opacity: 0;
  transform: translateY(20px);
}

.fade-slide-leave-from,
.fade-slide-enter-to {
  opacity: 1;
  transform: translateY(0);
}
</style>
