<template>
  <div :class="[`title-14`]">
    <h1
      class="klientu-saraksts valign-text-middle poppins-medium-elephant-34px"
    >
      {{ text }}
      
    </h1>
    <v-btn v-if="showCloseIcon" icon @click="$emit('close')" class="close-icon">
      <v-icon>mdi-close</v-icon>
    </v-btn>
  </div>
</template>

<script>
export default {
  name: 'HeadlineTitle',
  props: {
    text: String,
    showCloseIcon: {
      type: Boolean,
      default: false
    }
  }
}
</script>

<style>
.title-14 {
  align-items: center;
  display: flex;
  justify-content: space-between;
  height: 66.12px;
  padding: 0px 40px;
  position: relative;
  width: 100%;
  border-bottom-style: solid;
  border-bottom-width: 1px;
  border-color: var(--bon-jour);
}

.klientu-saraksts {
  margin-right: auto;
  letter-spacing: -0.68px;
  margin-bottom: 10px;
  position: relative;
  white-space: nowrap;
  width: fit-content;
}

.close-icon {
  margin-left: auto;
  cursor: pointer;
  margin-bottom: 10px;
  transform: translateX(15px);
}
</style>