<template>
  <button class="clicky-button" :class="buttonClass" @click="$emit('click')">
    <v-icon v-if="icon" class="icon-white">{{ icon }}</v-icon>
    <span v-else>{{ text }}</span>
  </button>
</template>

<script>
export default {
  name: 'ClickyButton',
  props: {
    text: {
      type: String,
      default: ''
    },
    buttonClass: {
      type: String,
      default: ''
    },
    icon: {
      type: String,
      default: ''
    }
  }
}
</script>

<style scoped>
.clicky-button {
  align-items: center;
  border: none;
  border-radius: 8px;
  box-shadow: 2px 4px 6px #00000026;
  cursor: pointer;
  display: flex;
  font-family: 'Inter', sans-serif;
  font-weight: 600;
  font-size: 16px;
  justify-content: center;
  letter-spacing: 0;
  padding: 10px 15px;
  text-align: center;
  white-space: nowrap;
  margin: 0; /* Remove any default margin */
}

.clicky-button.primary {
  background-color: var(--camelot) !important;
  color: var(--white);
}
.icon-white {
  color: white !important;
}
</style>