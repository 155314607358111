<template>
  <div class="phone-number-input">
    <v-autocomplete
      v-model="selectedCountry"
      :items="countries"
      item-text="name"
      item-value="iso2"
      :filter="filterCountries"
      hide-details
      outlined
      dense
      class="country-select"
      @change="updatePhoneNumber"
      :disabled="disabled || readonly"
      :readonly="readonly"
    >
      <template v-slot:selection="{ item }">
        <span>{{ item.emoji }} +{{ item.dialCode }}</span>
      </template>
      <template v-slot:item="{ item }">
        <v-list-item-content>
          <v-list-item-title>
            {{ item.emoji }} {{ item.name }} (+{{ item.dialCode }})
          </v-list-item-title>
        </v-list-item-content>
      </template>
    </v-autocomplete>

    <v-text-field
      v-model="phoneNumber"
      :label="label"
      :rules="rules"
      outlined
      dense
      class="number-input"
      @input="updatePhoneNumber"
      color="var(--mosque)"
      :disabled="disabled"
      :readonly="readonly"
    ></v-text-field>
  </div>
</template>

<script>
import { parsePhoneNumberFromString, getCountries, getCountryCallingCode } from 'libphonenumber-js'

export default {
  name: 'PhoneNumberInput',
  props: {
    value: {
      type: String,
      default: ''
    },
    label: {
      type: String,
    },
    color: {
      type: String,
      default: 'primary'
    },
    rules: {
      type: Array,
      default: () => []
    },
    disabled: {
      type: Boolean,
      default: false
    },
    readonly: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      countries: [],
      selectedCountry: null,
      phoneNumber: '',
    }
  },
  created() {
    this.initializeCountries()
    this.parseInitialValue()
  },
  methods: {
    initializeCountries() {
      this.countries = getCountries().map(country => ({
        name: new Intl.DisplayNames(['en'], { type: 'region' }).of(country),
        iso2: country,
        dialCode: getCountryCallingCode(country),
        emoji: this.getCountryFlag(country)
      }))
    },
    getCountryFlag(countryCode) {
      const codePoints = countryCode
        .toUpperCase()
        .split('')
        .map(char => 127397 + char.charCodeAt())
      return String.fromCodePoint(...codePoints)
    },
    parseInitialValue() {
      if (this.value) {
        const phoneNumber = parsePhoneNumberFromString(this.value)
        if (phoneNumber) {
          this.selectedCountry = phoneNumber.country
          this.phoneNumber = phoneNumber.nationalNumber
        } else {
          this.phoneNumber = this.value
          this.selectedCountry = 'LV' // Default to Latvia
        }
      } else {
        this.selectedCountry = 'LV' // Default to Latvia
      }
    },
    filterCountries(item, queryText) {
      const textOne = item.name.toLowerCase()
      const textTwo = item.dialCode.toString()
      const searchText = queryText.toLowerCase()
      return textOne.indexOf(searchText) > -1 || textTwo.indexOf(searchText) > -1
    },
    updatePhoneNumber() {
      if (this.selectedCountry && this.phoneNumber) {
        const phoneNumber = parsePhoneNumberFromString(this.phoneNumber, this.selectedCountry)
        if (phoneNumber) {
          this.$emit('input', phoneNumber.formatInternational())
        } else {
          this.$emit('input', '')
        }
      } else {
        this.$emit('input', '')
      }
    }
  }
}
</script>

<style scoped>
.phone-number-input {
  display: flex;
  gap: 10px;
}

.country-select {
  flex: 0 0 120px;
}

.number-input {
  flex: 1;
}
</style>